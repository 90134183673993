const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: ["name"],
    },
  ],
  fields: [
    {
      name: "name",
      label: "Nom de l'objectif du projet",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12",
    },
  ],
};

export default form;
