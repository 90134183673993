import { FaGears } from "react-icons/fa6";

export const ParamsConfig = {
  module: "parameters",
  single: "Paramètre",
  plural: "Paramètres",
  label: "liste des Paramètres",
  path: "/roles",
  link: "parametres",
  icon: FaGears,
};

export default ParamsConfig;
