const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: ["code", "raisonSociale", "logo", "typeGeneral"],
    },
    {
      label: "Identifiants légaux",
      fields: [
        "siret",
        "rcs",
        "orias",
        "oriasVerifie",
        "dateDebutOrias",
        "dateFinOrias",
      ],
    },
    {
      label: "Contact",
      fields: ["informationContact", "telephone", "mail"],
    },
    {
      label: "Adresse",
      fields: ["adresse", "complement", "codePostal", "ville", "pays"],
    },
    {
      label: "Champs divers",
      fields: [
        "divers1",
        "divers2",
        "divers3",
        "divers4",
        "divers5",
        "divers6",
        "divers7",
        "divers8",
        "divers9",
        "divers10",
        "divers11",
        "divers12",
        "divers13",
        "divers14",
        "divers15",
        "divers16",
        "divers17",
        "divers18",
        "divers19",
        "divers20",
      ],
    },
  ],
  fields: [
    {
      name: "code",
      label: "Code",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "raisonSociale",
      label: "Raison sociale",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "logo",
      label: "Logo",
      type: "image",
      required: false,
      className: "col-span-12 md:col-span-12 lg:col-span-4",
    },
    {
      name: "typeGeneral",
      label: "Type Général",
      type: "select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
      options: [
        { value: 5, label: "Agent" },
        { value: 0, label: "Aucun" },
        { value: 4, label: "Autre" },
        { value: 1, label: "Courtier" },
        { value: 2, label: "Mandataire" },
        { value: 3, label: "Salarié" },
      ],
    },
    {
      name: "siret",
      label: "SIRET",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "rcs",
      label: "RCS",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "orias",
      label: "ORIAS",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "oriasVerifie",
      label: "ORIAS Vérifié",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "dateDebutOrias",
      label: "Date de début ORIAS",
      type: "date",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "dateFinOrias",
      label: "Date de fin ORIAS",
      type: "date",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "informationContact",
      label: "Information de contact",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "telephone",
      label: "Téléphone",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "mail",
      label: "Email",
      type: "email",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    },
    {
      name: "adresse",
      label: "Adresse",
      type: "rich-text",
      filtrable: true,
      required: false,
      className: "col-span-12 lg:col-span-8",
    },
    {
      name: "complement",
      label: "Complément d'adresse",
      type: "rich-text",
      filtrable: true,
      required: false,
      className: "col-span-12 lg:col-span-4",
    },
    {
      name: "ville",
      label: "Ville",
      type: "ville",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-4 lg:col-span-4",
    },
    // Divers fields
    ...[...Array(20)].map((_, i) => ({
      name: `divers${i + 1}`,
      label: `Divers ${i + 1}`,
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6 lg:col-span-4",
    })),
  ],
};

export default form;
