// src/utils/TokenManager.js

class TokenManager {
  constructor() {
    if (TokenManager.instance) {
      return TokenManager.instance;
    }
    TokenManager.instance = this;
    this.token = null;
  }

  setToken(token) {
    this.token = token;
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem("authToken", token);
    }
  }

  getToken() {
    if (!this.token && typeof window !== "undefined") {
      this.token = window.sessionStorage.getItem("authToken");
    }
    return this.token;
  }

  clearToken() {
    this.token = null;
    if (typeof window !== "undefined") {
      window.sessionStorage.removeItem("authToken");
    }
  }
}

const tokenManager = new TokenManager();
export default tokenManager;
