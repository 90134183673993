import dayjs from "dayjs";

export const structureFormData = (itemData) => {
  console.log("Item data:", itemData);
  const structuredFormData = {
    ...itemData.formData,
    familyComposition: {
      startDate: itemData.familyComposition?.startDate
        ? dayjs(itemData.familyComposition.startDate)
        : null,
      postalCode: itemData.familyComposition?.postalCode,
      city: itemData.familyComposition?.city,
      mainInsuredBirthdate: itemData.familyComposition?.mainInsuredBirthdate
        ? dayjs(itemData.familyComposition.mainInsuredBirthdate)
        : null,
      regime: itemData.familyComposition?.regime,
      gamme: itemData.familyComposition?.gamme,
      familyMembers:
        itemData.familyComposition?.familyMembers?.map((member) => ({
          ...member,
          birthDate: dayjs(member.birthDate),
        })) || [],
    },
    selectedProduct: itemData.selectedProduct,
  };

  console.log("Structured form data:", structuredFormData);

  Object.keys(itemData).forEach((key) => {
    if (!["formData", "familyComposition", "selectedProduct"].includes(key)) {
      structuredFormData[key] = itemData[key];
    }
  });

  return structuredFormData;
};

export const generateDummyData = () => {
  // Implement your dummy data generation logic here
  // This is just a placeholder

  return {
    "9": {
      "6": true,
      "7": false,
    },
    "10": {
      "8": false,
      "9": true,
    },
    "11": {
      "10": true,
      "11": false,
    },
    "12": {
      "12": false,
      "13": true,
    },
    "13": {
      "14": false,
      "15": false,
      "16": false,
      "17": false,
      "18": true,
      "19": false,
      "20": false,
      "21": false,
      "22": false,
      "23": false,
      "24": false,
    },
    "14": {
      "25": false,
      "26": true,
    },
    "15": {
      "27": false,
      "28": true,
    },
    "16": "Test0",
    "17": {
      "30": false,
      "31": true,
    },
    "18": {
      "32": false,
      "33": true,
    },
    "19": {
      "34": false,
      "35": true,
      "36": false,
    },
    "20": {
      "37": false,
      "38": true,
      "39": false,
    },
    "22": {
      "43": true,
      "44": false,
      "45": false,
    },
    "23": {
      "46": true,
      "47": false,
    },
    "24": {
      "48": false,
      "49": true,
    },
    "25": {
      "50": false,
      "51": true,
    },
    "26": {
      "52": false,
      "53": true,
      "54": false,
    },
    "27": {
      "55": true,
      "56": false,
    },
    "28": {
      "57": true,
      "58": false,
    },
    "29": {
      "59": true,
      "60": false,
      "61": false,
      "62": false,
    },
    "30": {
      "63": false,
      "64": true,
    },
    "31": {
      "65": false,
      "66": true,
    },
    "32": {
      "67": true,
      "68": false,
    },
    "33": {
      "69": true,
      "70": false,
    },
    "34": {
      "71": false,
      "72": true,
    },
    "35": {
      "73": false,
      "74": true,
    },
    "36": {
      "75": true,
      "76": false,
    },
    "37": {
      "77": true,
      "78": false,
    },
    "38": {
      "79": false,
      "80": true,
    },
    "39": "Test10",
    "40": "Test11",
    "41": "Test12",
    "42": "Test13",
    "43": "22/08/1959",
    "44": {
      "86": true,
      "87": false,
    },
    "45": {
      "88": false,
      "89": true,
    },
    "46": {
      "90": true,
      "91": false,
    },
    "47": {
      "92": false,
      "93": false,
      "94": false,
      "95": true,
      "96": false,
      "97": false,
      "98": false,
      "99": false,
      "100": false,
      "101": false,
      "102": false,
      "103": false,
      "104": false,
      "105": false,
      "106": false,
      "107": false,
      "108": false,
    },
    "48": {
      "109": false,
      "110": true,
    },
    "49": {
      "111": true,
      "112": false,
    },
    "50": "test3",
    "51": {
      "114": true,
      "115": false,
    },
    "52": {
      "116": true,
      "117": false,
    },
    "53": {
      "118": true,
      "119": false,
    },
    "54": {
      "120": false,
      "121": true,
    },
    "55": {
      "122": true,
      "123": false,
    },
    "56": {
      "124": false,
      "125": true,
    },
    "57": {
      "126": false,
      "127": true,
    },
    "58": {
      "128": false,
      "129": true,
    },
    "59": {
      "130": false,
      "131": true,
    },
    "60": {
      "132": false,
      "133": true,
    },
    "61": {
      "134": false,
      "135": true,
    },
    "62": {
      "136": true,
      "137": false,
      "138": false,
    },
    "63": "Test17",
    "64": "Test18",
    "65": "Test19",
    "66": "Test20",
    "67": "93000",
    "68": "Test21",
    "69": {
      "145": true,
      "146": false,
      "147": false,
      "148": false,
      "149": false,
    },
    "70": {
      "150": true,
      "151": false,
    },
    "71": {
      "152": true,
      "153": false,
    },
    "72": {
      "154": true,
      "155": false,
    },
    "73": {
      "156": true,
      "157": false,
    },
    "74": "38",
    "75": {
      "159": false,
      "160": true,
    },
    "76": {
      "161": false,
      "162": true,
    },
    "77": {
      "163": false,
      "164": true,
    },
    "78": {
      "165": false,
      "166": true,
    },
    "79": "28",
    "80": {
      "168": false,
      "169": true,
    },
    "81": {
      "170": true,
      "171": false,
    },
    "82": {
      "172": false,
      "173": true,
    },
    "83": {
      "174": false,
      "175": true,
    },
    "84": {
      "176": true,
      "177": false,
    },
    "85": "83",
    "86": {
      "179": true,
      "180": false,
    },
    "familyComposition": {
      "mainInsuredBirthdate": null,
      "startDate": "2024-08-31T22:00:00.000Z",
      "postalCode": "31450 MONTBRUN LAURAGAIS",
    },
  };
};
