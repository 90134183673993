import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Descriptions, Tag, Divider, Tooltip } from "antd";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const SelectedProduct = ({ product }) => {
  if (!product) return null;

  const { offer, option, product: productDetails } = product;

  return (
    <Card
      title={
        <Title level={3} className="flex items-center">
          <span className="mr-2">{productDetails.libelle}</span>
          <Tag color="blue">{productDetails.famille}</Tag>
        </Title>
      }
      extra={
        <Tooltip title="Selected Product">
          <Tag icon={<CheckCircleOutlined />} color="success">
            Selected
          </Tag>
        </Tooltip>
      }
      className="mb-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      <Descriptions
        bordered
        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label="Assureur" span={3}>
          <Text strong>{productDetails.assureur}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Gamme" span={3}>
          {productDetails.gamme}
        </Descriptions.Item>
        <Descriptions.Item label="Option" span={3}>
          {option.libelle}
          {option.description && (
            <Tooltip title={option.description}>
              <InfoCircleOutlined className="ml-2 text-blue-500" />
            </Tooltip>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Offre" span={3}>
          <Text strong>{offer.libelle}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Cotisation mensuelle">
          <Text type="danger" strong>
            €{offer.cotisationMensuelle.toFixed(2)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Cotisation annuelle">
          <Text type="danger" strong>
            €{offer.cotisationAnnuelle.toFixed(2)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label="Frais de prélèvement">
          €{offer.fraisPrelevement.toFixed(2)}
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Description du produit</Divider>
      <div className="bg-gray-50 p-4 rounded-md">
        <Text>{productDetails.description.replace(/<br \/>/g, "\n")}</Text>
      </div>

      <Divider orientation="left">Description de l'offre</Divider>
      <div className="bg-gray-50 p-4 rounded-md">
        <Text>{offer.description}</Text>
      </div>

      <div className="mt-4 flex justify-between items-center bg-blue-50 p-4 rounded-md">
        <Text type="secondary">Code produit: {productDetails.code}</Text>
        <Text type="secondary">Code offre: {offer.code}</Text>
        <Text type="secondary">Type: {productDetails.type}</Text>
      </div>
    </Card>
  );
};

SelectedProduct.propTypes = {
  product: PropTypes.shape({
    offer: PropTypes.shape({
      code: PropTypes.string.isRequired,
      libelle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      fraisPrelevement: PropTypes.number.isRequired,
      cotisationAnnuelle: PropTypes.number.isRequired,
      cotisationMensuelle: PropTypes.number.isRequired,
    }).isRequired,
    option: PropTypes.shape({
      code: PropTypes.string.isRequired,
      libelle: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    product: PropTypes.shape({
      code: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      gamme: PropTypes.string.isRequired,
      famille: PropTypes.string.isRequired,
      libelle: PropTypes.string.isRequired,
      assureur: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default React.memo(SelectedProduct);
