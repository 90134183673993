import React from "react";
import {
  Form,
  Input,
  Checkbox,
  InputNumber,
  Select,
  Card,
  Typography,
} from "antd";
import {
  TeamOutlined,
  FieldTimeOutlined,
  UserSwitchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-blue-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const prospectStatusOptions = [
  { value: "none", label: "Aucun" },
  { value: "A APPELER", label: "A APPELER" },
  { value: "A RELANCER SUITE NRP", label: "A RELANCER SUITE NRP" },
  { value: "ACS", label: "ACS" },
  { value: "DEVIS REFUSE", label: "DEVIS REFUSE" },
  { value: "FAUX NUMERO", label: "FAUX NUMERO" },
  { value: "HORS CIBLE", label: "HORS CIBLE" },
  { value: "NRP", label: "NRP" },
  { value: "RAF", label: "RAF" },
  { value: "REPONDEUR", label: "REPONDEUR" },
  { value: "VENTE", label: "VENTE" },
];

const CRMTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-blue-800">
        Paramètres CRM
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Affichage et Interface"
          icon={<TeamOutlined />}
          className="bg-blue-50"
        >
          <FormItem name="displayNextTaskInBanner" valuePropName="checked">
            <Checkbox>Affichage de la prochaine tache dans le bandeau</Checkbox>
          </FormItem>
          <FormItem
            name="hideAppointmentShowCallButtonFromProspectList"
            valuePropName="checked"
          >
            <Checkbox>
              Cacher RDV et afficher bouton Appel depuis liste prospect
            </Checkbox>
          </FormItem>
          <FormItem
            name="prospectListWithCallCountAndStatusSort"
            valuePropName="checked"
          >
            <Checkbox>
              Liste prospect avec nombre d'appels et trie par statut
            </Checkbox>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Gestion des Prospects"
          icon={<UserSwitchOutlined />}
          className="bg-green-50"
        >
          <FormItem
            name="prospectStatusAfterNoAnswer"
            label="Statut prospect suite NRP"
          >
            <Select>
              {prospectStatusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="noTransactionOnLeadImport" valuePropName="checked">
            <Checkbox>Sans transaction sur import de lead</Checkbox>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Délais et Suppressions"
          icon={<FieldTimeOutlined />}
          className="bg-yellow-50"
        >
          <FormItem
            name="daysBeforeAutomaticProspectDeletion"
            label="Jours avant suppression auto des prospects"
          >
            <InputNumber min={0} />
          </FormItem>
          <FormItem
            name="daysBeforeAutomaticProjectQuoteDeletion"
            label="Jours avant suppression auto des projets/devis"
          >
            <InputNumber min={30} />
          </FormItem>
          <FormItem
            name="daysConsideringProspectDuplicateSameSource"
            label="Jours pour considérer un prospect en doublon"
          >
            <InputNumber min={0} />
          </FormItem>
          <FormItem
            name="daysToResetReceivedProspectAsNewFile"
            label="Jours pour remettre le prospect en nouvelle fiche"
          >
            <InputNumber min={0} />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Rappels et Assignations"
          icon={<DeleteOutlined />}
          className="bg-red-50"
        >
          <FormItem
            name="urgentCallbackAssignedTo"
            label="Rappel urgent affecté à"
          >
            <Select>
              <Option value="user1">Utilisateur 1</Option>
              <Option value="user2">Utilisateur 2</Option>
              {/* Add more options as needed */}
            </Select>
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default CRMTab;
