import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { Controller } from "react-hook-form";
import { getItems } from "./../../../../services/api"; // Adjust the import path as necessary
import { useError } from "./../../../../contexts/ErrorContext";

const ApiMultiSelect = ({
  fieldConfig,
  control,
  errorMessage,
  commonClasses,
}) => {
  const { setError, clearError } = useError();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const { data } = await getItems(
          fieldConfig.relation || fieldConfig.module,
          {
            pageSize: 1000,
          }
        );
        const apiOptions = data.map((item) => ({
          value: item.id,
          label: item[fieldConfig.attribute || "name"],
        }));
        setOptions(apiOptions);
        clearError();
      } catch (error) {
        console.error("Error fetching options:", error);
        setError({
          statusCode: error.response?.status || 500,
          message:
            error.response?.data?.message || "An unexpected error occurred",
          errors: error.response?.data?.errors,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [fieldConfig.module, fieldConfig.relation, fieldConfig.attribute]);

  return (
    <Controller
      name={fieldConfig.name}
      control={control}
      rules={{ required: fieldConfig.required }}
      render={({ field }) => {
        // Ensure the value is always an array of IDs
        const selectValue = Array.isArray(field.value)
          ? field.value.map((item) => {
              if (typeof item === "object" && item !== null) {
                return item.id;
              }
              return item;
            })
          : [];

        const handleChange = (newValues) => {
          const selectedOptions = newValues.map((value) => {
            const option = options.find((opt) => opt.value === value);
            return option
              ? {
                  id: option.value,
                  [fieldConfig.attribute || "name"]: option.label,
                }
              : { id: value, [fieldConfig.attribute || "name"]: "" };
          });
          field.onChange(selectedOptions);
        };

        return (
          <>
            <Select
              {...field}
              mode="multiple"
              value={selectValue}
              onChange={handleChange}
              className={commonClasses}
              style={{ width: "100%" }}
              options={options}
              loading={loading}
              disabled={loading}
            />
            {errorMessage && (
              <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
            )}
          </>
        );
      }}
    />
  );
};

export default ApiMultiSelect;
