import { FaUser } from "react-icons/fa6";

import FormConfig from "./user.form";
import TableConfig from "./user.table";

export const userConfig = {
  module: "user",
  single: "user",
  plural: "users",
  label: "Utilisateurs",
  path: "/user",
  api: "users",
  icon: FaUser,
  displayColumns: [
    "id",
    "username",
    "code",
    "code_externe",
    "offre",
    "company",
  ],
  excludeColumns: [
    "password",
    "token_publique",
    "ip_authorized",
    "createdAt",
    "updatedAt",
  ],
  table: TableConfig,
  form: FormConfig,
};

export default userConfig;
