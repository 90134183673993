import React, { useState, useCallback } from "react";
import { AutoComplete, Spin } from "antd";
import axios from "axios";
import debounce from "lodash/debounce";
import { useError } from "../../../../contexts/ErrorContext";

const AddressAutocomplete = ({ value, onChange, placeholder }) => {
  const { setError, clearError } = useError();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAddress = async (text) => {
    if (text.length < 3) {
      setOptions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(
          text
        )}&limit=5`
      );
      const addresses = response.data.features.map((feature) => ({
        value: feature.properties.label,
        label: feature.properties.label,
        extraData: {
          coordinates: feature.geometry.coordinates,
          type: feature.properties.type,
          postcode: feature.properties.postcode,
          city: feature.properties.city,
          context: feature.properties.context,
        },
      }));
      setOptions(addresses);
      clearError();
    } catch (error) {
      console.error("Error fetching addresses:", error);
      setError({
        statusCode: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        errors: error.response?.data?.errors,
      });
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearchAddress = useCallback(debounce(searchAddress, 300), []);

  const handleSearch = (text) => {
    debouncedSearchAddress(text);
  };

  const handleChange = (value, option) => {
    if (onChange) {
      onChange(value, option);
    }
  };

  return (
    <AutoComplete
      value={value}
      options={options}
      onSearch={handleSearch}
      onChange={handleChange}
      placeholder={placeholder || "Enter an address"}
      style={{ width: "100%" }}
      notFoundContent={loading ? <Spin size="small" /> : null}
    />
  );
};

export default AddressAutocomplete;
