const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: ["code", "nom", "providers"],
    },
  ],
  fields: [
    {
      name: "code",
      label: "Code",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      validation: {
        minLength: 1,
        maxLength: 50,
      },
    },
    {
      name: "nom",
      label: "Nom",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      validation: {
        minLength: 1,
        maxLength: 150,
      },
    },
    {
      name: "providers",
      label: "Fournisseurs",
      type: "table",
      filtrable: true,
      required: false,
      className: "col-span-12",
      relation: "provider",
      multiple: true,
      columns: [
        { key: "id", label: "ID" },
        { key: "nom", label: "Nom" },
        { key: "orias", label: "Orias" },
        // Add more columns as needed
      ],
      pageSize: 10,
      module: "providers",
    },
  ],
};

export default form;
