import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Steps, Button, message, Spin, Card, Modal, Tooltip } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import {
  SendOutlined,
  CheckOutlined,
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
  UserOutlined,
  TeamOutlined,
  ShoppingOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  BankOutlined,
} from "@ant-design/icons";
import { useError } from "../../contexts/ErrorContext";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import Layout from "../layout";
import {
  EmailSender,
  FamilyCompositionForm,
  QuestionRenderer,
  ProductDisplay,
  SelectedProduct,
  generateDummyData,
  useFormData,
  useQuestions,
  useProductSolutions,
  SummaryView,
  AdditionalInformationStep,
  AdditionalInformationStep2,
  AdditionalInformationStep3,
} from "./new-project-hooks-and-helpers";
import {
  createItem,
  updateItem,
  sendEmailWithFormData,
} from "../../services/api";

const { Step } = Steps;

const MultiStepForm = () => {
  const { setError, clearError } = useError();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("Votre demande de tarif");
  const [emailText, setEmailText] = useState(
    "Please find attached your insurance offer details."
  );
  const [customerId, setCustomerId] = useState(null);

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isProductConfirmed, setIsProductConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [venteId, setVenteId] = useState(id || null);
  const [initialProductIdentifier, setInitialProductIdentifier] =
    useState(null);

  const { formData, setFormData, isExistingEntry, fetchItemData } =
    useFormData(id);
  const { questions, categories, loading: questionsLoading } = useQuestions();
  const { productSolutions, fetchProductData } = useProductSolutions(formData);

  useEffect(() => {
    if (id) {
      fetchItemData(id).then((data) => {
        console.log("Fetched item data:", data);
        setVenteId(id);
        setFormData(data);
        if (data?.selectedProduct) {
          setInitialProductIdentifier(
            getProductIdentifier(data?.selectedProduct)
          );
          setIsProductConfirmed(true);
        }

        if (data?.customerId) {
          setCustomerId(data.customerId);
        }
      });
    }
  }, [id, fetchItemData, setFormData]);

  useEffect(() => {
    if (formData?.createdAt) {
      const timer = setInterval(() => {
        const now = dayjs();
        const created = dayjs(formData.createdAt);
        const validUntil = created.add(24, "hour");
        const remaining = validUntil.diff(now, "second");

        if (remaining <= 0) {
          clearInterval(timer);
          setTimeRemaining(null);
        } else {
          setTimeRemaining(remaining);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [formData?.createdAt]);

  const getProductIdentifier = useCallback((selectedProduct) => {
    if (
      !selectedProduct ||
      !selectedProduct.product ||
      !selectedProduct.option ||
      !selectedProduct.offer
    ) {
      return null;
    }
    return `${selectedProduct.product.code}${selectedProduct.option.code}${selectedProduct.offer.code}`;
  }, []);

  const handleProductSelection = useCallback(
    (selectedProduct) => {
      setFormData((prev) => ({ ...prev, selectedProduct }));
      setIsProductConfirmed(true);
    },
    [setFormData]
  );

  const handleNext = useCallback(async () => {
    if (currentStep < categories.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else if (
      currentStep === categories.length - 1 &&
      !formData.selectedProduct
    ) {
      setIsLoadingProducts(true);
      try {
        await fetchProductData();
        setCurrentStep((prev) => prev + 1);
      } catch (error) {
        console.error("Error fetching product data:", error);
        message.error("Failed to fetch product data. Please try again.");
      } finally {
        setIsLoadingProducts(false);
      }

      // After family composition, fetch product data
    } else if (
      currentStep === categories.length - 1 &&
      formData.selectedProduct
    ) {
      setCurrentStep((prev) => prev + 2);
    } else if (currentStep === categories.length) {
      // After product selection
      if (!isProductConfirmed) {
        message.warning("Please select a product before proceeding.");
        return;
      }

      const currentProductIdentifier = getProductIdentifier(
        formData?.selectedProduct
      );
      const productChanged =
        currentProductIdentifier !== initialProductIdentifier;

      try {
        console.log("formData", formData.familyComposition);
        const venteData = {
          formData: formData,
          familyComposition: {
            mainInsuredBirthdate: dayjs(
              formData.familyComposition.mainInsuredBirthdate
            ).toISOString(),
            startDate: dayjs(
              formData.familyComposition.startDate
            ).toISOString(),
            postalCode: formData.familyComposition.postalCode,
            regime: formData.familyComposition.regime,
            gamme: formData.familyComposition.gamme,
            city: formData.familyComposition.city,
          },
          selectedProduct: formData?.selectedProduct,
          isCompleted: false,
          validation: "draft",
          familyMembers:
            formData.familyMembers?.map((member) => ({
              ...member,
              birthdate: dayjs(member.birthdate).toISOString(),
            })) || [],
        };

        console.log("formData", venteData);

        let updatedVente;
        if (!venteId) {
          // Create new vente
          updatedVente = await createItem("ventes", venteData);
          setVenteId(updatedVente.id);
          message.success("New vente created successfully.");
        } else if (productChanged) {
          // Update existing vente with new product
          updatedVente = await updateItem("ventes", venteId, venteData);

          message.success("Vente updated with new product.");
        } else {
          // No changes, just proceed to next step
          message.info("No changes detected. Proceeding to next step.");
        }

        if (updatedVente) {
          setFormData((prev) => ({
            ...prev,
            ...updatedVente,
            pdfLinks: updatedVente?.pdfLinks || {},
          }));
          setInitialProductIdentifier(currentProductIdentifier);
        }
        setCurrentStep((prev) => prev + 1);
      } catch (error) {
        console.error("Error handling vente:", error);
        message.error("Failed to process vente. Please try again.");
      }
    }
  }, [
    currentStep,
    categories.length,
    isProductConfirmed,
    fetchProductData,
    formData,
    venteId,
    clearError,
    navigate,
    setError,
    getProductIdentifier,
    initialProductIdentifier,
    setFormData,
    customerId,
    setCustomerId,
  ]);

  const handlePrev = useCallback(() => {
    if (currentStep === categories.length + 1 && formData.selectedProduct) {
      setCurrentStep((prev) => prev - 2);
    } else if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  }, [currentStep]);

  const fillWithDummyData = useCallback(() => {
    setFormData(generateDummyData());
    message.success("Form filled with dummy data");
  }, [setFormData]);

  const handleSendEmail = useCallback(
    async (email) => {
      try {
        await sendEmailWithFormData(email, emailSubject, emailText, formData);
        message.success("Email sent successfully");
        const updatedItem = await updateItem("ventes", venteId, {
          ...formData,
          emailsSent: true,
        });
        setFormData((prev) => ({
          ...prev,
          ...updatedItem,
        }));
        console.log("Updated item:", updatedItem);
        setShowEmailModal(false);
      } catch (error) {
        console.error("Error sending email:", error);
        message.error("Failed to send email. Please try again.");
      }
    },
    [formData, emailSubject, emailText]
  );

  const handleValidation = useCallback(async () => {
    try {
      const updatedItem = await updateItem("ventes", venteId, {
        ...formData,
        validation: "validated",
      });

      setFormData((prev) => ({
        ...prev,
        ...updatedItem,
      }));
      console.log("Updated item:", updatedItem);
      message.success("Offer validated successfully!");
    } catch (error) {
      console.error("Error validating offer:", error);
      message.error("Failed to validate offer. Please try again.");
    }
  }, [venteId, formData]);

  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const stepGroups = useMemo(
    () => [
      {
        key: "personal",
        title: "Personal Information",
        icon: <UserOutlined />,
      },
      { key: "family", title: "Family Composition", icon: <TeamOutlined /> },
      {
        key: "product",
        title: "Product Selection",
        icon: <ShoppingOutlined />,
      },
      { key: "summary", title: "Summary", icon: <FileTextOutlined /> },
    ],
    []
  );

  const getCurrentStepGroup = useCallback(() => {
    if (currentStep < categories.length - 1) return 0; // Personal
    if (currentStep === categories.length - 1) return 1; // Family
    if (currentStep === categories.length) return 2; // Product
    if (currentStep === categories.length + 1) return 3; // Summary

    return 4; // Coordonnées
  }, [currentStep, categories]);

  const renderSteps = useCallback(
    () => (
      <div className="mb-8">
        <Steps current={getCurrentStepGroup()} responsive>
          {stepGroups.map(({ key, title, icon }) => (
            <Step
              key={key}
              title={<span className="hidden md:inline">{title}</span>}
              icon={<Tooltip title={title}>{icon}</Tooltip>}
            />
          ))}
        </Steps>
      </div>
    ),
    [getCurrentStepGroup, stepGroups]
  );

  const renderContent = useCallback(() => {
    if (currentStep < categories.length - 1) {
      return (
        <>
          <h3 className="text-xl font-semibold mb-4 text-gray-700">
            {categories[currentStep]}
          </h3>
          {questions
            .filter((q) => q.category.name === categories[currentStep])
            .map((question) => (
              <QuestionRenderer
                key={question.id}
                question={question}
                formData={formData}
                handleInputChange={(question, value) => {
                  console.log("handleInputChange", question, value);
                  setFormData((prev) => ({ ...prev, [question.id]: value }));
                }}
              />
            ))}
        </>
      );
    } else if (currentStep === categories.length - 1) {
      return (
        <FamilyCompositionForm formData={formData} setFormData={setFormData} />
      );
    } else if (
      currentStep === categories.length &&
      !formData?.selectedProduct
    ) {
      return (
        <ProductDisplay
          solutions={productSolutions}
          onSelectProduct={handleProductSelection}
          isLoading={isLoadingProducts}
          isProductConfirmed={isProductConfirmed}
        />
      );
    } else if (currentStep === categories.length && formData?.selectedProduct) {
      return <SelectedProduct product={formData.selectedProduct} />;
    } else if (currentStep === categories.length + 1) {
      return (
        <SummaryView
          formData={formData}
          setFormData={setFormData}
          hamdlelePrev={handlePrev}
          onSendEmail={() => setShowEmailModal(true)}
          onValidate={handleValidation}
        />
      );
    }
  }, [
    currentStep,
    categories,
    questions,
    formData,
    setFormData,
    productSolutions,
    isLoadingProducts,
    isProductConfirmed,
    handleValidation,
    handleProductSelection,
    customerId,
  ]);

  const actionButtons = useMemo(
    () => [
      <Button
        key="previous"
        onClick={handlePrev}
        icon={<LeftOutlined />}
        disabled={currentStep === 0}
      >
        Previous
      </Button>,
      <Button
        key="next"
        onClick={handleNext}
        type="primary"
        icon={
          isSubmitting || isLoadingProducts ? (
            <LoadingOutlined />
          ) : (
            <RightOutlined />
          )
        }
        disabled={
          (currentStep === categories.length && !isProductConfirmed) ||
          currentStep > categories.length
        }
      >
        {currentStep === categories.length + 4
          ? isSubmitting
            ? "Submitting..."
            : "Submit"
          : isLoadingProducts
          ? "Loading Products..."
          : "Next"}
      </Button>,
      !isExistingEntry && (
        <Button
          key="fillDummy"
          onClick={fillWithDummyData}
          type="primary"
          className="bg-green-500 hover:bg-green-600"
        >
          Fill with Dummy Data
        </Button>
      ),
    ],
    [
      currentStep,
      categories.length,
      handlePrev,
      handleNext,
      isExistingEntry,
      fillWithDummyData,
      isProductConfirmed,
      isSubmitting,
      isLoadingProducts,
    ]
  );

  if (questionsLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout pageTitle="Nouveau projet / Vente" actionComponents={actionButtons}>
      <div className="container p-4 sm:p-8">
        <Card className="shadow-lg rounded-lg overflow-hidden">
          {renderSteps()}
          <div className="mb-8">{renderContent()}</div>
        </Card>
      </div>
      <Modal
        title="Send Email"
        visible={showEmailModal}
        onCancel={() => setShowEmailModal(false)}
        footer={null}
      >
        <EmailSender
          onSendEmail={handleSendEmail}
          initialSubject={emailSubject}
          initialText={emailText}
          onSubjectChange={setEmailSubject}
          onTextChange={setEmailText}
          pdfLinks={formData?.pdfLinks}
          formData={formData}
        />
      </Modal>
    </Layout>
  );
};

export default MultiStepForm;
