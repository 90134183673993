import React, { useState, useEffect } from "react";
import { Card, Typography, Space, Button, Divider, message } from "antd";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

const ErrorDisplay = ({ error, onClose, onReportError }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);

  useEffect(() => {
    if (error?.response?.data?.message) {
      setErrorMessages(error.response.data.message);
    } else {
      setErrorMessages(console.message);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setIsVisible(true);
    }
  }, [error]);

  if (!error) return null;

  const handleReportError = () => {
    onReportError(error);
    message.success(
      "Error reported successfully. Thank you for your feedback."
    );
  };

  return (
    <div
      className={`transition-all duration-300 ease-in-out ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4"
      }`}
    >
      <Card
        className="mb-6 shadow-lg border-l-4 border-indigo-500 overflow-hidden"
        bodyStyle={{ padding: 0 }}
        extra={
          <Space>
            <Button
              type="text"
              icon={<FlagOutlined />}
              onClick={handleReportError}
              aria-label="Report error"
              className="text-indigo-600 hover:text-indigo-800 transition-colors duration-200"
            >
              Envoyez à l'équipe d'administrateur
            </Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => {
                setIsVisible(false);
                setTimeout(onClose, 300);
              }}
              aria-label="Close error message"
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
            />
          </Space>
        }
      >
        <div className="bg-gradient-to-r from-indigo-50 to-white p-4">
          <Space align="center" className="w-full justify-between">
            <Space>
              <ExclamationCircleOutlined className="text-2xl text-indigo-500" />
              <Title level={4} className="m-0 text-gray-800">
                Error {error.statusCode}
              </Title>
            </Space>
            <Text className="text-sm text-gray-500">
              {new Date().toLocaleString()}
            </Text>
          </Space>
        </div>

        <Divider className="my-0" />

        <div className="p-4">
          <Text strong className="text-gray-700 block mb-3 text-lg">
            Error Details:
          </Text>
          <ul className="list-none m-0 p-0 space-y-2">
            {Array.isArray(errorMessages) ? (
              errorMessages.map((message, index) => (
                <li
                  key={index}
                  className="transition-all duration-300 ease-in-out"
                  style={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible
                      ? "translateX(0)"
                      : "translateX(-20px)",
                    transitionDelay: `${index * 100}ms`,
                  }}
                >
                  <Card
                    size="small"
                    className="bg-white shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <Text className="text-gray-600">{message}</Text>
                  </Card>
                </li>
              ))
            ) : (
              <li
                className="transition-all duration-300 ease-in-out"
                style={{
                  opacity: isVisible ? 1 : 0,
                  transform: isVisible ? "translateX(0)" : "translateX(-20px)",
                }}
              >
                <Card
                  size="small"
                  className="bg-white shadow-sm hover:shadow-md transition-shadow duration-200"
                >
                  <Text className="text-gray-600">{error.message}</Text>
                </Card>
              </li>
            )}
          </ul>
        </div>
      </Card>
    </div>
  );
};

export default ErrorDisplay;
