import { FaBoxesPacking } from "react-icons/fa6";

import FormConfig from "./question.form";
import TableConfig from "./question.table";

export const questionCategConfig = {
  module: "questions",
  single: "Category question",
  plural: "Categories questions",
  label: "Category Question",
  path: "/question-category",
  api: "questions/categories",
  icon: FaBoxesPacking,
  displayColumns: ["id", "text"],
  excludeColumns: ["createdAt", "updatedAt", "userResponses"],
  table: TableConfig,
  form: FormConfig,
  addon: "category",
};

export default questionCategConfig;
