const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: ["code", "name", "teamLeader"],
    },
    {
      label: "Membres et fournisseurs",
      fields: ["teamMembers", "providers"],
    },
  ],
  fields: [
    {
      name: "code",
      label: "Code",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      validation: {
        maxLength: 50,
      },
    },
    {
      name: "name",
      label: "Nom",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      validation: {
        maxLength: 100,
      },
    },
    {
      name: "teamLeader",
      label: "Chef d'équipe",
      relation: "users",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      attribute: "username",
    },
    {
      name: "teamMembers",
      label: "Membres de l'équipe",
      relation: "users",
      type: "api-multi-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      attribute: "username",
    },
    {
      name: "providers",
      label: "Fournisseurs",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12",
      module: "providers",
      attribute: "nom",
    },
  ],
};

export default form;
