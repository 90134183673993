import React from "react";
import {
  Form,
  Input,
  Checkbox,
  InputNumber,
  Select,
  Card,
  Typography,
  Tooltip,
} from "antd";
import {
  ShoppingOutlined,
  FieldTimeOutlined,
  FileProtectOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-green-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const CheckboxItem = ({ name, children }) => (
  <FormItem name={name} valuePropName="checked" className="mb-2">
    <Checkbox className="text-sm text-gray-700">{children}</Checkbox>
  </FormItem>
);

const SalesTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-green-800">
        Paramètres de Vente
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Paramètres généraux de vente"
          icon={<ShoppingOutlined />}
          className="bg-green-50"
        >
          <CheckboxItem name="showOnlyRecommendations">
            Afficher uniquement les préconisations (hors budget)
          </CheckboxItem>
          <FormItem name="defaultSaleType" label="Type de vente par défaut">
            <Select>
              <Option value="cold">Vente à froid</Option>
              <Option value="hot">Vente à chaud</Option>
            </Select>
          </FormItem>
          <CheckboxItem name="askSellerForSaleType">
            Type de vente (chaud / froid) demandé au vendeur
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Timing et attente"
          icon={<FieldTimeOutlined />}
          className="bg-yellow-50"
        >
          <FormItem
            name="pricingWebServiceWaitTime"
            label="Attente web service tarification"
            tooltip="Temps d'attente en secondes"
          >
            <InputNumber min={0} className="w-full" addonAfter="secondes" />
          </FormItem>
          <FormItem
            name="coldSaleWaitMinutes"
            label="Attente pour vente froide"
            tooltip="Nombre de minutes d'attente pour vente froide"
          >
            <InputNumber min={0} className="w-full" addonAfter="minutes" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Gestion des documents"
          icon={<FileProtectOutlined />}
          className="bg-blue-50"
        >
          <CheckboxItem name="forbidNonImageDocumentUpload">
            Interdire la dépose de document non images reconnues
          </CheckboxItem>
          <CheckboxItem name="showFormulaBlockDescription">
            '?' pour affichage du descriptif sur chaque bloc formule
          </CheckboxItem>
          <CheckboxItem name="disableClientDocumentSpace">
            Désactiver l'espace document client
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Signature et régénération"
          icon={<UserSwitchOutlined />}
          className="bg-purple-50"
        >
          <CheckboxItem name="changeEffectiveDateToSignatureRequest">
            Date d'effet changée à la date de demande de signature
          </CheckboxItem>
          <Tooltip title="Régénère les documents avant signature">
            <div className="text-xs text-gray-500 ml-6 mb-2">
              (Régénère les documents avant signature)
            </div>
          </Tooltip>
          <CheckboxItem name="regenerateDocumentsBeforeSignatureOnCoupledSale">
            Régénère document avant signature sur vente couplée
          </CheckboxItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default SalesTab;
