import React from "react";
import { Card, Button, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

const CardView = ({ data, moduleConfig, onDelete, navigate }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {data.map((item) => (
        <Card
          key={item.id}
          title={item[moduleConfig.displayField] || `Item ${item.id}`}
          extra={
            <div className="flex space-x-2">
              <Tooltip title="Edit">
                <Button
                  icon={<EditOutlined />}
                  onClick={() =>
                    navigate(`/edit/${moduleConfig.singular}/${item.id}`, {
                      state: { item },
                    })
                  }
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      onDelete(item.id);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="View Details">
                <Button icon={<EyeOutlined />} onClick={() => {}} />
              </Tooltip>
            </div>
          }
        >
          {Object.entries(item).map(([key, value]) => (
            <p key={key}>
              <strong>{key}: </strong>
              {String(value)}
            </p>
          ))}
        </Card>
      ))}
    </div>
  );
};

export default CardView;
