import React from "react";
import { Form, Select, Card, Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-purple-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const statusOptions = [
  { value: "none", label: "Aucun" },
  { value: "01", label: "01 DEMANDE ACCORD SERVICE QUALITE" },
  { value: "02", label: "02 REJET SERVICE QUALITE" },
  { value: "03", label: "03 ACCORD SERVICE QUALITE" },
  { value: "04", label: "04 RENONCIATION" },
];

const StatusQualityTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-purple-800">
        Paramètres de Statut et Qualité
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Statut après signature"
          icon={<CheckCircleOutlined />}
          className="bg-purple-50"
        >
          <FormItem
            name="statusAfterSignature"
            label="Statut suite à signature"
          >
            <Select>
              {statusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Contrôle Qualité"
          icon={<ExclamationCircleOutlined />}
          className="bg-yellow-50"
        >
          <FormItem
            name="statusTriggeringQualityControl"
            label="Statut déclenchant le contrôle Qualité"
          >
            <Select>
              {statusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Rejet du contrôle Qualité"
          icon={<CloseCircleOutlined />}
          className="bg-red-50"
        >
          <FormItem
            name="statusAfterQualityControlRejection"
            label="Statut suite rejet du contrôle Qualité"
          >
            <Select>
              {statusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Validation du contrôle Qualité"
          icon={<SyncOutlined />}
          className="bg-green-50"
        >
          <FormItem
            name="statusAfterQualityControlValidation"
            label="Statut suite à validation du contrôle Qualité"
          >
            <Select>
              {statusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default StatusQualityTab;
