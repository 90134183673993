import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { getConfigForModule } from "../../models";
import Layout from "./../layout";
import TableComponent from "./table-hooks-and-helpers/TableComponent";
import CardView from "./table-hooks-and-helpers/CardView";
import Toolbar from "./table-hooks-and-helpers/Toolbar";
import { useColumns } from "./table-hooks-and-helpers/useColumns.hook";
import { useColumnVisibility } from "./table-hooks-and-helpers/useColumnVisibility";
import { useTableData } from "./table-hooks-and-helpers/useTableData.hook";

const DataTable = ({ module }) => {
  const navigate = useNavigate();
  const [moduleConfig, setModuleConfig] = useState(null);
  const [viewMode, setViewMode] = useState("table");
  const [selectedRows, setSelectedRows] = useState([]);

  const {
    loading,
    actionLoading,
    data,
    pageCount,
    pagination,
    filters,
    fetchData,
    handleDelete,
    handleBulkDelete,
    handlePageChange,
    getActionHandler,
    handleAdd,
    handleEdit,
    handleExport,
    handleImport,
    handleFilterChange,
  } = useTableData(moduleConfig);

  const columns = useColumns(data, moduleConfig, handleDelete, navigate);
  const [columnVisibility, toggleColumnVisibility] =
    useColumnVisibility(columns);

  const visibleColumns = useMemo(() => {
    return columns.filter((column) => columnVisibility[column.id] !== false);
  }, [columns, columnVisibility]);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const config = await getConfigForModule(module);
        setModuleConfig(config);
      } catch (error) {
        console.error(`Error loading config for module ${module}:`, error);
      }
    };
    loadConfig();
  }, [module]);

  if (!moduleConfig) {
    return <Spin size="large" />;
  }

  return (
    <Layout pageTitle={moduleConfig.label}>
      <div className="container py-8">
        <Toolbar
          moduleConfig={moduleConfig}
          viewMode={viewMode}
          setViewMode={setViewMode}
          selectedRows={selectedRows}
          columns={columns}
          columnVisibility={columnVisibility}
          toggleColumnVisibility={toggleColumnVisibility}
          actionLoading={actionLoading}
          getActionHandler={getActionHandler}
          handleBulkDelete={handleBulkDelete}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleExport={handleExport}
          handleImport={handleImport}
          filters={filters}
          setFilters={handleFilterChange}
        />
        {viewMode === "table" ? (
          <TableComponent
            data={data}
            pageCount={pageCount}
            fetchData={handlePageChange}
            moduleConfig={moduleConfig}
            onDelete={handleDelete}
            setSelectedRows={setSelectedRows}
            navigate={navigate}
            columns={visibleColumns}
            columnVisibility={columnVisibility}
            pagination={pagination}
            loading={loading}
          />
        ) : (
          <CardView
            data={data}
            moduleConfig={moduleConfig}
            onDelete={handleDelete}
            navigate={navigate}
          />
        )}
      </div>
    </Layout>
  );
};

export default DataTable;
