import { useState, useCallback } from "react";
import { message } from "antd";
import { getItemById } from "../../../services/api";
import { structureFormData } from "./form-data-helper";

export const useFormData = (id) => {
  const [formData, setFormData] = useState({});
  const [isExistingEntry, setIsExistingEntry] = useState(false);

  const fetchItemData = useCallback(async (itemId) => {
    try {
      console.log("Fetching item data...", itemId);
      const itemData = await getItemById("ventes", itemId);
      if (itemData) {
        console.log("Fetched item data:", itemData);
        const structuredFormData = structureFormData(itemData);
        console.log("Structured form data:", structuredFormData);
        setFormData(structuredFormData);
        setIsExistingEntry(true);
        return structuredFormData;
      }
    } catch (error) {
      console.error("Error fetching item data:", error);
      message.error("Failed to fetch item data");
    }
  }, []);

  return { formData, setFormData, isExistingEntry, fetchItemData };
};
