import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import tokenManager from "../services/tokenManager";
import { auth } from "../firebase";
import api from "../services/api";

export const AuthContext = createContext({
  user: null,
  loading: true,
  error: null,
  isInitialized: false,
  getIdToken: async () => null,
  setUser: () => {},
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const getIdToken = useCallback(async () => {
    if (auth.currentUser) {
      try {
        const token = await auth.currentUser.getIdToken(true);
        tokenManager.setToken(token);
        return token;
      } catch (error) {
        console.error("Error getting ID token:", error);
        setError("Failed to get authentication token");
        return null;
      }
    }
    return null;
  }, []);

  const fetchUserData = useCallback(async (firebaseUser) => {
    try {
      const token = await firebaseUser.getIdToken();
      tokenManager.setToken(token);
      const userFromApi = await api.getuserByFirebaseUid(firebaseUser.uid);

      if (!userFromApi.role || !userFromApi.role.permissions) {
        throw new Error("User data is missing role or permissions");
      }

      setUser(userFromApi);
      setError(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data");
      tokenManager.clearToken();
      setUser(null);
    } finally {
      setLoading(false);
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setLoading(true);
      if (firebaseUser) {
        await fetchUserData(firebaseUser);
      } else {
        tokenManager.clearToken();
        setUser(null);
        setError(null);
        setLoading(false);
        setIsInitialized(true);
      }
    });

    return () => unsubscribe();
  }, [fetchUserData]);

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      error,
      isInitialized,
      getIdToken,
      setUser: (newUser) => {
        if (newUser && (!newUser.role || !newUser.role.permissions)) {
          console.error("Attempting to set user without role or permissions");
          return;
        }
        setUser(newUser);
        if (!newUser) {
          tokenManager.clearToken();
        }
      },
    }),
    [user, loading, error, isInitialized, getIdToken]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
