import React from "react";

const CellRenderer = ({ cell, moduleConfig }) => {
  const value = cell.value;
  const column = cell.column;

  const fieldConfig = moduleConfig.form.fields.find((item) => {
    return item.name === column.id;
  });

  // If the cell has a custom renderer, use it

  // Custom renderer for object values
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    return (
      <span title={JSON.stringify(value)}>
        {value[fieldConfig?.attribute] ||
          value.name ||
          value.label ||
          JSON.stringify(value)}
      </span>
    );
  }

  // Custom renderer for array values
  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((item) => (
          <li
            key={
              item[fieldConfig?.attribute] ||
              item.id ||
              item.name ||
              item.label ||
              JSON.stringify(item)
            }
          >
            {item[fieldConfig?.attribute] ||
              item.id ||
              item.name ||
              item.label ||
              JSON.stringify(item)}
          </li>
        ))}
      </ul>
    );
  }

  if (
    cell.isGrouped ||
    cell.isAggregated ||
    cell.isPlaceholder ||
    column.Cell
  ) {
    return cell.render("Cell");
  }

  // Custom renderer for specific column types
  switch (column.type) {
    case "boolean":
      return value ? "✅" : "❌";

    case "date":
      return value ? new Date(value).toLocaleDateString() : "";

    case "currency":
      return value
        ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value)
        : "";

    case "custom":
      // You can define custom rendering logic here
      if (column.render) {
        return column.render({ value, row: cell.row.original });
      }
      break;

    // Add more cases as needed

    default:
      return value;
  }
};

export default CellRenderer;
