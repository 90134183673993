const venteSpec = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
    {
      label: "Soumettre",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: [
        "startDate",
        "postalCode",
        "mainInsuredBirthdate",
        "regime",
        "isCompleted",
      ],
    },
    {
      label: "Données du formulaire",
      fields: ["formData"],
    },
    {
      label: "Composition familiale",
      fields: ["familyMembers"],
    },
  ],
  fields: [
    {
      name: "startDate",
      label: "Date d'effet souhaitée",
      type: "date",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "postalCode",
      label: "Code Postal",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "mainInsuredBirthdate",
      label: "Date de naissance de l'assuré principal",
      type: "date",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "regime",
      label: "Régime",
      type: "select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      options: [
        { value: "regime", label: "Régime" },
        { value: "alsaceMoselle", label: "Alsace Moselle" },
        { value: "regimeAgricole", label: "Régime Agricole" },
        { value: "securiteSociale", label: "Sécurité sociale" },
        { value: "tns", label: "TNS" },
      ],
    },
    {
      name: "isCompleted",
      label: "Formulaire complété",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "formData",
      label: "Données du formulaire",
      type: "json",
      filtrable: false,
      required: true,
      className: "col-span-12",
    },
    {
      name: "familyMembers",
      label: "Membres de la famille",
      type: "array",
      filtrable: false,
      required: false,
      className: "col-span-12",
      fields: [
        {
          name: "type",
          label: "Type",
          type: "select",
          required: true,
          className: "col-span-12 md:col-span-4",
          options: [
            { value: "conjoint", label: "Conjoint" },
            { value: "enfant", label: "Enfant" },
          ],
        },
        {
          name: "birthDate",
          label: "Date de naissance",
          type: "date",
          required: true,
          className: "col-span-12 md:col-span-4",
        },
        {
          name: "regime",
          label: "Régime",
          type: "select",
          required: true,
          className: "col-span-12 md:col-span-4",
          options: [
            { value: "regime", label: "Régime" },
            { value: "alsaceMoselle", label: "Alsace Moselle" },
            { value: "regimeAgricole", label: "Régime Agricole" },
            { value: "securiteSociale", label: "Sécurité sociale" },
            { value: "tns", label: "TNS" },
          ],
        },
      ],
    },
  ],
};

export default venteSpec;
