import { useState, useCallback } from "react";
import { message } from "antd";
import { getQuotation } from "../../../services/api";
import { toISOStringOrNull } from "./date.helper";

export const useProductSolutions = (formData) => {
  const [productSolutions, setProductSolutions] = useState([]);

  const fetchProductData = useCallback(async () => {
    try {
      const quotationData = {
        donneesPolice: {
          dateNaissanceSouscripteur: toISOStringOrNull(
            formData.familyComposition?.mainInsuredBirthdate
          ),
          regimeSocialSouscripteur: formData.familyComposition?.regime,
          codePostalVille: {
            label:
              formData.familyComposition?.postalCode +
              " " +
              formData.familyComposition?.city.toUpperCase(),
            value:
              formData.familyComposition?.postalCode +
              " " +
              formData.familyComposition?.city.toUpperCase(),
          },
          conjoint:
            formData.familyComposition?.familyMembers?.some(
              (member) => member.type === "Conjoint"
            ) || false,
          dateNaissanceConjoint: toISOStringOrNull(
            formData.familyComposition?.familyMembers?.find(
              (member) => member.type === "Conjoint"
            )?.birthDate
          ),
          regimeSocialConjoint:
            formData.familyComposition?.familyMembers?.find(
              (member) => member.type === "Conjoint"
            )?.regime || null,
          listeEnfants:
            formData.familyComposition?.familyMembers
              ?.filter((member) => member.type === "Enfant")
              .map((child) => ({
                dateNaissance: toISOStringOrNull(child.birthDate),
                regimeSocial: child.regime,
              }))
              .filter((child) => child.dateNaissance !== null) || [],
          dateEffet: toISOStringOrNull(formData.familyComposition?.startDate),
          dateEffetComplementaire: toISOStringOrNull("2019-12-31"),
          nonFumeur: true,
          nonPrisEnChargeCentPourCentSecu: true,
          nonTitulaireRenteInvalidite: true,
          pasEnArretProfessionnelle: true,
          selectionGamme: {
            gamme: formData?.familyComposition?.gamme,
            gammeOptions: [],
          },
        },
      };

      const data = await getQuotation("products", quotationData);
      setProductSolutions(data.solutions);
    } catch (error) {
      console.error("Error fetching product data:", error);
      message.error(`Failed to fetch product data: ${error.message}`);
    }
  }, [formData]);

  return { productSolutions, fetchProductData };
};
