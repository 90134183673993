import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Input,
  Select,
  Switch,
  DatePicker,
  Skeleton,
  message,
  Tabs,
  Modal,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getItemById, updateItem } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import moment from "moment";
import Layout from "./../layout";

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const ProfilePage = () => {
  const {
    user,
    loading: authLoading,
    error: authError,
  } = useContext(AuthContext);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    const fetchUserData = async () => {
      if (authLoading) return;
      if (!user) {
        setError("You must be logged in to view this page.");
        setLoading(false);
        return;
      }

      try {
        console.log(user);
        const userData = await getItemById("users", user.id);
        console.log("User data from API:", userData);

        // Use the phone number from the user context
        userData.phoneNumber = user.phoneNumber;

        console.log("Final user data:", userData);
        setProfileData(userData);
        Object.keys(userData).forEach((key) => {
          if (key === "birthDate" && userData[key]) {
            setValue(key, moment(userData[key]));
          } else {
            setValue(key, userData[key]);
          }
        });
      } catch (err) {
        setError("Failed to fetch user data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user, authLoading, setValue]);

  const onSubmit = async (data) => {
    confirm({
      title: "Are you sure you want to update your profile?",
      icon: <ExclamationCircleOutlined />,
      content: "This action will update your personal information.",
      onOk: async () => {
        try {
          await updateItem("users", user.uid, data);
          message.success("Profile updated successfully!");
          setProfileData(data);
        } catch (err) {
          setError("Failed to update profile. Please try again.");
        }
      },
    });
  };

  if (authLoading || loading) {
    return <Skeleton active />;
  }

  if (authError) {
    return <Alert message={authError} type="error" />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (!user) {
    return (
      <Alert message="Please log in to view your profile." type="warning" />
    );
  }

  return (
    <Layout>
      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6">User Profile</h1>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Personal Information" key="1">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              <div>
                <Controller
                  name="phoneNumber"
                  disabled
                  control={control}
                  defaultValue={profileData?.phoneNumber || ""}
                  rules={{
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Invalid phone number",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      prefix={<PhoneOutlined />}
                      placeholder="Phone Number"
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Birth Date"
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      style={{ width: "100%" }}
                      placeholder="Select Role"
                      disabled
                    >
                      <Option value="admin">Admin</Option>
                      <Option value="user">User</Option>
                      <Option value="manager">Manager</Option>
                    </Select>
                  )}
                />
              </div>
              <div className="flex justify-end space-x-4">
                <Button onClick={() => navigate(-1)}>Cancel</Button>
                <Button type="primary" htmlType="submit" disabled={!isDirty}>
                  Save Changes
                </Button>
              </div>
            </form>
          </TabPane>
          <TabPane tab="Security" key="2">
            <div className="space-y-4">
              <h2 className="text-xl font-semibold">Change Password</h2>
              <Alert
                message="Password changes are managed through your authentication provider."
                type="info"
                showIcon
              />
            </div>
          </TabPane>
          <TabPane tab="Preferences" key="3">
            <div className="space-y-4">
              <h2 className="text-xl font-semibold">Notification Settings</h2>
              <div className="flex items-center justify-between">
                <span>Email Notifications</span>
                <Switch defaultChecked />
              </div>
              <div className="flex items-center justify-between">
                <span>SMS Notifications</span>
                <Switch />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default ProfilePage;
