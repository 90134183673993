import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Layout from "../layout";
import { models } from "../../models";
import RenderField from "./form-hooks-and-helpers/renderField";
import TabHeader from "./form-hooks-and-helpers/TabHeader";
import { createItem, updateItem } from "../../services/api";
import { useError } from "../../contexts/ErrorContext";

const TabbedForm = ({ moduleName, initialData = {} }) => {
  const config = models[moduleName];
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state?.item;
  const { setError, clearError } = useError();

  const [activeTab, setActiveTab] = useState(0);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: item || initialData,
  });

  const onSubmit = async (data) => {
    try {
      if (data.id) {
        await updateItem(config.api, data.id, data);
      } else {
        await createItem(config.api, data);
      }
      clearError();
      navigate(-1);
    } catch (error) {
      setError({
        statusCode: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        errors: error.response?.data?.errors,
      });
    }
  };

  const actionComponents = config.form.actions.map((action, index) => (
    <button
      key={index}
      type={action.type}
      onClick={handleSubmit(onSubmit)}
      className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      {action.label}
    </button>
  ));

  return (
    <Layout pageTitle={config.single} actionComponents={actionComponents}>
      <form className="space-y-6">
        <TabHeader
          config={config}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-9">
            <div className="grid grid-cols-12 gap-4">
              {config.form.tabs[activeTab].fields.map((field) => {
                const fieldConfig = config.form.fields.find(
                  (f) => f.name === field
                );
                if (!fieldConfig) return null;

                return (
                  <div key={field} className={fieldConfig.className}>
                    <label
                      htmlFor={field}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {fieldConfig.label}
                    </label>
                    <RenderField
                      config={config}
                      register={register}
                      control={control}
                      setValue={setValue}
                      field={field}
                      errors={errors}
                    />
                    {errors[field] && (
                      <p className="mt-2 text-sm text-red-600">
                        This field is required
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default TabbedForm;
