import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Typography,
  Divider,
  Button,
  Space,
  Row,
  Col,
  Modal,
  Input,
  message,
} from "antd";
import { EditOutlined, EyeOutlined, MailOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  requestSignature,
  verifySignature,
  getSignatureStatus,
} from "./../../../services/api";

const { Title, Text } = Typography;

const FinalSummaryView = ({
  formData,
  onModifyInfo,
  onViewProspect,
  onSendEmail,
  onReturnToProjects,
}) => {
  const [isSignatureModalVisible, setIsSignatureModalVisible] = useState(false);
  const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { selectedProduct, familyComposition } = formData;
  const fullName = `${formData.title || ""} ${formData.firstName || ""} ${
    formData.lastName || ""
  }`.trim();
  const projectName = `Projet ${
    selectedProduct?.product?.code || ""
  } : ${fullName}`;
  const effectiveDate = dayjs(familyComposition?.startDate).format(
    "D MMM. YYYY"
  );

  const handleSendSMS = async () => {
    setIsSignatureModalVisible(true);
  };

  const handleRequestSignature = async () => {
    setIsLoading(true);
    try {
      await requestSignature(formData.id, phoneNumber);
      message.success("Signature request sent successfully");
      setIsSignatureModalVisible(false);
    } catch (error) {
      message.error("Failed to send signature request");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifySignature = async () => {
    setIsVerifyModalVisible(true);
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    try {
      await verifySignature(formData.id, verificationCode);
      message.success("Signature verified successfully");
      setIsVerifyModalVisible(false);
    } catch (error) {
      message.error("Failed to verify signature");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestartSignature = async () => {
    try {
      const status = await getSignatureStatus(formData.id);
      if (status === "pending") {
        setIsSignatureModalVisible(true);
      } else {
        message.info(
          "No pending signature request found. Please start a new signature process."
        );
      }
    } catch (error) {
      message.error("Failed to check signature status");
    }
  };

  return (
    <Card className="shadow-lg rounded-lg overflow-hidden">
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>{projectName}</Title>
          <Text>Date d'effet demandée: {effectiveDate}</Text>
        </Col>
        <Col>
          <Button type="link">Changer l'affectation</Button>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" align="top" className="mb-4">
        <Col span={18}>
          <Title level={4} className="text-blue-500 mb-0">
            {selectedProduct?.product?.libelle}
          </Title>
          <Text>Gamme de la formule: {selectedProduct?.product?.code}</Text>
          <br />
          <Text>Branche de la gamme: {selectedProduct?.product?.famille}</Text>
          <br />
          <Text>Id Compagnie: {selectedProduct?.product?.ident}</Text>
        </Col>
        <Col span={6} className="text-right">
          <Text>
            Montant mensuel{" "}
            {selectedProduct?.offer?.cotisationMensuelle.toFixed(2)} €
          </Text>
          <br />
          <Text>
            Montant annuel{" "}
            {selectedProduct?.offer?.cotisationAnnuelle.toFixed(2)} €
          </Text>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Space>
            <Button icon={<EditOutlined />} onClick={onModifyInfo}>
              Modifier les informations
            </Button>
            <Button icon={<EyeOutlined />} onClick={onViewProspect}>
              Voir le prospect
            </Button>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button icon={<MailOutlined />} onClick={onSendEmail}>
              Envoi par EMAIL
            </Button>
            <Button onClick={handleSendSMS}>Envoi par SMS</Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4}>Montant mensuel total</Title>
        </Col>
        <Col>
          <Title level={4} className="text-blue-500">
            {selectedProduct?.offer?.cotisationMensuelle.toFixed(2)} €
          </Title>
        </Col>
      </Row>
      <Row justify="space-between" className="mt-8">
        <Col>
          <Button onClick={onReturnToProjects}>Retour liste projets</Button>
        </Col>
        <Col>
          <Space>
            <Button type="primary" onClick={handleRestartSignature}>
              Recommencer signature
            </Button>
            <Button type="primary" onClick={handleVerifySignature}>
              Vérifier signature
            </Button>
          </Space>
        </Col>
      </Row>
      <Text className="text-xs mt-4 block">
        Les utilisateurs reconnaissent et acceptent que les services proposés
        sur ce site, basés sur les technologies les plus avancées, ont pour seul
        objet de leur faciliter la recherche de l'assurance convenant à leur
        besoins, mais que les offres présentées ne peuvent être considérées
        comme fermes et engageantes pour l'assureur proposant l'offre. En
        conséquence, l'utilisateur décharge le propriétaire du site et
        l'assureur proposant l'offre, de toute responsabilité en cas
        d'inexactitudes portant sur les garanties, les franchises, les prix ou
        les services proposés. L'utilisateur s'engage à lire les conditions
        générales et particulières du contrat définitif qu'il aura à souscrire,
        qui sont les seuls documents engageant la responsabilité de l'assureur
        et du propriétaire du site. La signature manuscrite ou électronique
        apposée sur le bulletin de souscription, n'engage que le souscripteur.
        L'utilisateur reconnaît et accepte également qu'en raison de la nature
        technique des moyens utilisés pour rendre le service, il lui appartient
        de vérifier à réception de son devis ou lors de l'entretien de
        téléconsultation ou à la réception de son contrat définitif, la
        conformité entre sa situation et les données enregistrées par
        l'assureur.
      </Text>

      <Modal
        title="Demande de signature"
        visible={isSignatureModalVisible}
        onOk={handleRequestSignature}
        onCancel={() => setIsSignatureModalVisible(false)}
        confirmLoading={isLoading}
      >
        <Input
          placeholder="Numéro de téléphone"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Modal>

      <Modal
        title="Vérification de signature"
        visible={isVerifyModalVisible}
        onOk={handleVerifyCode}
        onCancel={() => setIsVerifyModalVisible(false)}
        confirmLoading={isLoading}
      >
        <Input
          placeholder="Code de vérification"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
      </Modal>
    </Card>
  );
};

FinalSummaryView.propTypes = {
  formData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    selectedProduct: PropTypes.shape({
      product: PropTypes.shape({
        code: PropTypes.string,
        libelle: PropTypes.string,
        famille: PropTypes.string,
        ident: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      offer: PropTypes.shape({
        cotisationMensuelle: PropTypes.number,
        cotisationAnnuelle: PropTypes.number,
      }),
    }),
    familyComposition: PropTypes.shape({
      startDate: PropTypes.string,
    }),
  }).isRequired,
  onModifyInfo: PropTypes.func.isRequired,
  onViewProspect: PropTypes.func.isRequired,
  onSendEmail: PropTypes.func.isRequired,
  onReturnToProjects: PropTypes.func.isRequired,
};

export default FinalSummaryView;
