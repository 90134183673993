import React, { useState, useEffect } from "react";
import { Table, Input, Button, message, Modal, Form } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { getItems, createItem } from "../../../../services/api"; // Adjust the import path as needed
import { useError } from "./../../../../contexts/ErrorContext";
const RelationTableComponent = ({ spec, value, onChange }) => {
  const { setError, clearError } = useError();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: spec.pageSize || 10,
    total: 0,
  });
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState(value || []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(pagination),
    JSON.stringify(filters),
    JSON.stringify(sorter),
  ]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getItems(spec.module, {
        pageIndex: pagination.current - 1,
        pageSize: pagination.pageSize,
        filters: filters,
        sortBy: sorter.field
          ? `${sorter.field}:${sorter.order === "ascend" ? "ASC" : "DESC"}`
          : undefined,
      });

      setData(response.data);
      setPagination((prev) => ({
        ...prev,
        total: response.totalCount,
      }));
      clearError();
    } catch (error) {
      message.error("Failed to fetch data");
      setError({
        statusCode: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        errors: error.response?.data?.errors,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setFilters(filters);
    setSorter(sorter);
  };

  const handleRowSelection = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    onChange(selectedRowKeys);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      await createItem(spec.relation, values);
      message.success("Item created successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchData(); // Refresh the table data
      clearError();
    } catch (error) {
      message.error("Failed to create item");
      setError({
        statusCode: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        errors: error.response?.data?.errors,
      });
    }
  };

  const columns = spec.columns.map((column) => ({
    ...column,
    dataIndex: column.key,
    filterable: spec.filtrable,
    sorter: true,
    render: (text, record) => {
      const value = column.key
        .split(".")
        .reduce((obj, key) => obj && obj[key], record);
      return value !== undefined ? value.toString() : "";
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${column.label}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  }));

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{ marginBottom: 16 }}
      >
        Add New Item
      </Button>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowSelection={{
          type: spec.multiple ? "checkbox" : "radio",
          selectedRowKeys,
          onChange: handleRowSelection,
        }}
      />
      <Modal
        title="Create New Item"
        visible={isModalVisible}
        onOk={handleCreate}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          {spec.columns.map((column) => (
            <Form.Item
              key={column.key}
              name={column.key}
              label={column.label}
              rules={[
                {
                  required: column.required,
                  message: `Please input ${column.label}!`,
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  );
};

export default RelationTableComponent;
