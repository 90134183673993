import React from "react";
import {
  Form,
  Input,
  Checkbox,
  InputNumber,
  Tooltip,
  Card,
  Typography,
} from "antd";
import {
  SettingOutlined,
  UserOutlined,
  LockOutlined,
  GlobalOutlined,
  MailOutlined,
  PictureOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-blue-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const CheckboxItem = ({ name, children }) => (
  <FormItem name={name} valuePropName="checked" className="mb-2">
    <Checkbox className="text-sm text-gray-700">{children}</Checkbox>
  </FormItem>
);

const GeneralTab = ({ form }) => {
  return (
    <div className="max-w-5xl  p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-blue-800">
        Paramètres généraux
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Paramètres utilisateur"
          icon={<UserOutlined />}
          className="bg-blue-50"
        >
          <CheckboxItem name="sepaWithApporteurName">
            Sepa avec Nom apporteur
          </CheckboxItem>
          <CheckboxItem name="searchByDefaultNetwork">
            Recherche par réseau par défaut (si chef d'équipe)
          </CheckboxItem>
          <CheckboxItem name="withoutConjointSignature">
            Sans signature conjoint Docaposte
          </CheckboxItem>
          <CheckboxItem name="nameAndFirstNameUppercase">
            Nom et prénom forcés en majuscule
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Sécurité"
          icon={<LockOutlined />}
          className="bg-green-50"
        >
          <FormItem
            name="passwordValidityMonths"
            label="Durée validité mot de passe"
            className="mb-4"
          >
            <InputNumber
              min={0}
              className="w-full"
              addonAfter="mois"
              placeholder="0 = illimité"
            />
          </FormItem>
          <CheckboxItem name="withOpeningLogging">
            Avec journalisation des ouvertures
          </CheckboxItem>
          <CheckboxItem name="withoutExternalApporteurCodeVerification">
            Sans vérification code externe apporteur
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Paramètres globaux"
          icon={<GlobalOutlined />}
          className="bg-yellow-50"
        >
          <CheckboxItem name="firstNumericCharForbiddenInAddress">
            Premier caractère numérique interdit dans adresse
          </CheckboxItem>
          <CheckboxItem name="spoolerActive">Spooler actif</CheckboxItem>
          <FormItem
            name="phoneCountries"
            label="Pays des téléphones"
            tooltip="Séparez les pays par des virgules"
          >
            <Input placeholder="FR, BE, CH" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Communication"
          icon={<MailOutlined />}
          className="bg-purple-50"
        >
          <FormItem
            name="traceEmail"
            label="Email de trace"
            tooltip="Cet email sera en copie cachée de tous les emails"
          >
            <Input type="email" placeholder="trace@example.com" />
          </FormItem>
          <FormItem name="mailServiceEmail" label="Email du service courrier">
            <Input type="email" placeholder="courrier@example.com" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Apparence"
          icon={<PictureOutlined />}
          className="bg-red-50 col-span-full"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormItem
              name="applicationLogo"
              label="Logo de l'application"
              tooltip="URL du logo de l'application"
            >
              <Input placeholder="https://example.com/logo.png" />
            </FormItem>
            <FormItem
              name="applicationIcon"
              label="Icône de l'application"
              tooltip="URL de l'icône de l'application"
            >
              <Input placeholder="https://example.com/icon.png" />
            </FormItem>
          </div>
          <FormItem name="connectionMessage" label="Message de connexion">
            <Input.TextArea
              rows={3}
              placeholder="Entrez le message affiché lors de la connexion"
              className="resize-none"
            />
          </FormItem>
          <FormItem name="bannerMessage" label="Message du bandeau">
            <Input.TextArea
              rows={3}
              placeholder="Entrez le message affiché dans le bandeau"
              className="resize-none"
            />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Informations système"
          icon={<InfoCircleOutlined />}
          className="bg-gray-100 col-span-full"
        >
          <FormItem name="version" label="Version">
            <Input disabled />
          </FormItem>
          <FormItem
            name="resilCertificateDownloadSite"
            label="Adresse site téléchargement certificat resil"
            tooltip="Spécifier si différent du site par défaut"
          >
            <Input placeholder="https://example.com/certificat" />
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default GeneralTab;
