import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { Controller, useWatch } from "react-hook-form";
import { getItems } from "./../../../../services/api"; // Adjust the import path as necessary
import { useError } from "./../../../../contexts/ErrorContext";

const ApiSelect = ({ fieldConfig, control, errorMessage, commonClasses }) => {
  const { setError, clearError } = useError();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Watch the field value
  const fieldValue = useWatch({
    control,
    name: fieldConfig.name,
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const { data } = await getItems(
          fieldConfig.relation || fieldConfig.module,
          {
            pageSize: 1000,
          }
        );
        console.log(data);
        const apiOptions = data.map((item) => ({
          value: item.id,
          label: item[fieldConfig.attribute || "name"], // Use the specified attribute or default to 'name'
        }));
        setOptions(apiOptions);

        // If fieldValue is an object and not in the options, add it
        if (
          fieldValue &&
          fieldValue.id &&
          fieldValue[fieldConfig.attribute || "name"] &&
          !apiOptions.some((option) => option.value === fieldValue.id)
        ) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              value: fieldValue.id,
              label: fieldValue[fieldConfig.attribute || "name"],
            },
          ]);
          clearError(); // Clear any existing errors on success
        }
      } catch (error) {
        console.error("Error fetching options:", error);
        setError({
          statusCode: error.response?.status || 500,
          message:
            error.response?.data?.message || "An unexpected error occurred",
          errors: error.response?.data?.errors,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [fieldConfig.module, fieldConfig.attribute, fieldValue]);

  return (
    <Controller
      name={fieldConfig.name}
      control={control}
      rules={{ required: fieldConfig.required }}
      render={({ field }) => {
        // Adjust the value to be just the ID for the Select component
        const selectValue =
          field.value && field.value.id ? field.value.id : field.value;

        // Adjust the onChange to set the entire object
        const handleChange = (newValue) => {
          const selectedOption = options.find(
            (option) => option.value === newValue
          );
          field.onChange(
            selectedOption
              ? {
                  id: selectedOption.value,
                  [fieldConfig.attribute || "name"]: selectedOption.label,
                }
              : newValue
          );
        };

        return (
          <>
            <Select
              {...field}
              value={selectValue}
              onChange={handleChange}
              className={commonClasses}
              style={{ width: "100%" }}
              options={options}
              loading={loading}
              disabled={loading}
            />
            {errorMessage && (
              <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
            )}
          </>
        );
      }}
    />
  );
};

export default ApiSelect;
