import { FaListCheck } from "react-icons/fa6";

export const Roles = {
  module: "role",
  single: "Role",
  plural: "Roles",
  label: "Role",
  path: "/roles",
  link: "roles",
  icon: FaListCheck,
};

export default Roles;
