// src/config/companyConfig.js

import { FaBuilding } from "react-icons/fa6";

import FormConfig from "./company.form";
import TableConfig from "./company.table";

export const companyConfig = {
  module: "company",
  single: "company",
  plural: "companies",
  label: "Entreprises",
  path: "/company",
  api: "companies",
  icon: FaBuilding,
  displayColumns: ["id", "raisonSociale", "siret", "orias"],
  excludeColumns: ["password", "createdAt", "updatedAt"],
  table: TableConfig,
  form: FormConfig,
};
