import { FaFlag } from "react-icons/fa6";

import FormConfig from "./project-objective.form";
import TableConfig from "./project-objective.table";

export const projectObjectiveConfig = {
  module: "projectObjective",
  single: "projectObjective",
  plural: "Objectifs projet",
  label: "Objectifs du projet",
  path: "/project-objective",
  api: "project-objectives",
  icon: FaFlag,
  displayColumns: ["id", "name"],
  excludeColumns: ["createdAt", "updatedAt", "providers"],
  table: TableConfig,
  form: FormConfig,
};

export default projectObjectiveConfig;
