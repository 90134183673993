const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: [
        "header",
        "text",
        "help",
        "type",
        "category",
        "gamme",
        "answerOptions",
      ],
    },
  ],
  fields: [
    {
      name: "header",
      label: "Entête question",
      type: "textarea",
      filtrable: true,
      required: false,
      className: "col-span-12",
    },
    {
      name: "text",
      label: "Texte de la question",
      type: "textarea",
      filtrable: true,
      required: true,
      className: "col-span-12",
    },
    {
      name: "help",
      label: "Aide",
      type: "textarea",
      filtrable: true,
      required: false,
      className: "col-span-12",
    },
    {
      name: "type",
      label: "Type de question",
      type: "select",
      options: [
        { value: "free", label: "Réponse libre" },
        { value: "radio", label: "Choix unique" },
        { value: "checkbox", label: "Choix multiple" },
      ],
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-4",
    },
    {
      name: "category",
      label: "Catégorie",
      type: "api-select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-4",
      module: "questions/categories",
    },
    {
      name: "gamme",
      label: "Gamme",
      type: "api-select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-4",
      module: "questions/gammes",
    },
    {
      name: "answerOptions",
      label: "Options de réponse",
      type: "array",
      filtrable: false,
      required: false,
      className: "col-span-12",
      conditional: {
        field: "type",
        value: ["radio", "checkbox"],
      },
      attribute: "text",
      fields: [
        {
          name: "text",
          label: "Texte de l'option",
          type: "text",
          required: true,
          className: "col-span-12 md:col-span-6",
        },
        {
          name: "isCorrect",
          label: "Est correcte",
          type: "checkbox",
          required: false,
          className: "col-span-12 md:col-span-6",
        },
      ],
    },
  ],
};

export default form;
