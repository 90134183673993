import { useState, useEffect, useMemo } from "react";
import { message } from "antd";
import { getItems } from "../../../services/api";

export const useQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await getItems("questions", { pageSize: 1000 });
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
        message.error("Failed to fetch questions");
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const categories = useMemo(() => {
    const categorySet = new Set(questions.map((q) => q.category.name));
    return [...categorySet, "Family Composition"];
  }, [questions]);

  return { questions, categories, loading };
};
