import axios from "axios";
import qs from "qs";
import config from "../config";
import { message } from "antd";
import tokenManager from "./tokenManager";

const apiClient = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Add request interceptor
apiClient.interceptors.request.use((request) => {
  // console.log("Starting Request", JSON.stringify(request, null, 2));
  return request;
});

apiClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use(
  async (config) => {
    const token = tokenManager.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // console.log("Response:", JSON.stringify(response, null, 2));
    return response;
  },
  (error) => {
    console.log(
      "Response Error:",
      error.response ? JSON.stringify(error.response, null, 2) : error.message
    );
    return Promise.reject(error);
  }
);

const handleApiError = (error, operation, module) => {
  console.error(`Error ${operation} ${module}:`, error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Error data:", error.response.data);
    console.error("Error status:", error.response.status);
    console.error("Error headers:", error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.error("Error request:", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error message:", error.message);
  }
  throw error; // Re-throw the error so it can be caught by the calling function
};

export const getItems = async (
  module,
  { pageIndex = 0, pageSize = 10, filters = {}, sortBy = [] }
) => {
  try {
    const queryParams = {
      pageIndex,
      pageSize,
      sortBy: sortBy,
      ...filters,
    };

    // Remove undefined values
    Object.keys(queryParams).forEach(
      (key) => queryParams[key] === undefined && delete queryParams[key]
    );

    const url = `/${module}?${qs.stringify(queryParams, {
      arrayFormat: "repeat",
      skipNulls: true,
      encode: false,
    })}`;

    const response = await apiClient.get(url);

    return {
      data: response.data?.data || [],
      pageCount: Math.ceil(response.data.total / pageSize),
      totalCount: response.data.total,
    };
  } catch (error) {
    handleApiError(error, "fetching", module);
    return { data: [], pageCount: 0, totalCount: 0 };
  }
};

export const getItemById = async (module, id) => {
  try {
    const response = await apiClient.get(`/${module}/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", `${module} with id ${id}`);
  }
};

export const getQuotation = async (module, data) => {
  try {
    const response = await apiClient.post(`/${module}/quotation`, data);
    return response.data;
  } catch (error) {
    handleApiError(error, "creating", "quote");
  }
};

export const sendEmailWithFormData = async (email, subject, text, formData) => {
  try {
    const response = await apiClient.post("/email/send-vente-pdfs", {
      email,
      subject,
      text,
      venteId: formData.id,
    });
    return response.data;
  } catch (error) {
    handleApiError(error, "sending email with form data");
  }
};

// New function to generate PDFs
export const generatePdfs = async (venteId) => {
  try {
    const response = await apiClient.post(`/ventes/${venteId}/generate-pdfs`);
    return response.data;
  } catch (error) {
    handleApiError(error, "generating PDFs");
  }
};

// New function to get PDF links
export const getPdfLinks = async (venteId) => {
  try {
    const response = await apiClient.get(`/ventes/${venteId}/pdf-links`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching PDF links");
  }
};

// New function to regenerate PDFs
export const regeneratePdfs = async (venteId) => {
  try {
    const response = await apiClient.post(`/ventes/${venteId}/regenerate-pdfs`);
    return response.data;
  } catch (error) {
    handleApiError(error, "regenerating PDFs");
  }
};

// Update the existing createItem function to include PDF generation
export const createItem = async (module, data) => {
  try {
    const response = await apiClient.post(`/${module}`, data);
    message.success("Item créé avec succès!");

    return response.data;
  } catch (error) {
    handleApiError(error, "creating", module);
  }
};

export const updateItem = async (module, id, data, isPartial = false) => {
  try {
    let response;
    if (isPartial) {
      console.log("Updating item partially");
      response = await apiClient.patch(`/${module}/${id}`, data);
    } else {
      response = await apiClient.put(`/${module}/${id}`, data);
    }

    message.success(
      `Item ${isPartial ? "partiellement " : ""}mis à jour avec succès!`
    );
    return response.data;
  } catch (error) {
    handleApiError(
      error,
      `${isPartial ? "partially " : ""}updating`,
      `${module} with id ${id}`
    );
  }
};

export const deleteItem = async (module, id) => {
  try {
    const response = await apiClient.delete(`/${module}/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "deleting", `${module} with id ${id}`);
  }
};

// New function to handle bulk operations
export const bulkOperation = async (module, operation, ids) => {
  try {
    const response = await apiClient.post(`/${module}/bulk`, {
      operation,
      ids,
    });
    return response.data;
  } catch (error) {
    handleApiError(error, `bulk ${operation}`, module);
  }
};

// New function to handle file uploads
export const uploadFile = async (module, id, file, onUploadProgress) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(`/${module}/${id}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });

    return response.data;
  } catch (error) {
    handleApiError(error, "uploading file for", `${module} with id ${id}`);
  }
};

// New function to handle import
export const importItems = async (module, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post(`/${module}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error, "importing", module);
  }
};

// New function to handle export
export const exportItems = async (module, filters = {}) => {
  try {
    const queryParams = qs.stringify(filters, {
      arrayFormat: "repeat",
      skipNulls: true,
      encode: false,
    });

    const response = await apiClient.get(`/${module}/export?${queryParams}`, {
      responseType: "blob",
    });

    return response;
  } catch (error) {
    handleApiError(error, "exporting", module);
  }
};

export const fetchParameters = async () => {
  try {
    const response = await apiClient.get("/parameters");
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", "parameters");
  }
};

export const updateParameters = async (data) => {
  try {
    const response = await apiClient.patch("/parameters", data);
    message.success("Paramètres mis à jour avec succès!");
    return response.data;
  } catch (error) {
    handleApiError(error, "updating", "parameters");
  }
};

export const getParameterById = async (id) => {
  try {
    const response = await apiClient.get(`/parameters/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", `parameter with id ${id}`);
  }
};

export const getUserByPhone = async (phone) => {
  try {
    console.log("Token before getUserByPhone:", tokenManager.getToken());
    const response = await apiClient.get(`/users/phone/${phone}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", `user with phone ${phone}`);
  }
};

export const getuserByFirebaseUid = async (uid) => {
  try {
    const response = await apiClient.get(`/users/firebase/${uid}`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", `user with Firebase uid ${uid}`);
  }
};

export const createParameter = async (data) => {
  try {
    const response = await apiClient.post("/parameters", data);
    message.success("Paramètre créé avec succès!");
    return response.data;
  } catch (error) {
    handleApiError(error, "creating", "parameter");
  }
};

export const deleteParameter = async (id) => {
  try {
    const response = await apiClient.delete(`/parameters/${id}`);
    message.success("Paramètre supprimé avec succès!");
    return response.data;
  } catch (error) {
    handleApiError(error, "deleting", `parameter with id ${id}`);
  }
};

export const bulkOperationParameters = async (operation, ids) => {
  try {
    const response = await apiClient.post("/parameters/bulk", {
      operation,
      ids,
    });
    return response.data;
  } catch (error) {
    handleApiError(error, `bulk ${operation}`, "parameters");
  }
};

export const importParameters = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.post("/parameters/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error, "importing", "parameters");
  }
};

export const linkFirebaseUser = async (userId, firebaseUid) => {
  try {
    console.log("Token before linkFirebaseUser:", tokenManager.getToken());

    const response = await apiClient.put(`/users/${userId}/link-firebase`, {
      firebaseUid,
    });
    return response.data;
  } catch (error) {
    console.error("Error linking Firebase UID to user:", error);
    throw error;
  }
};

export const exportParameters = async (filters = {}) => {
  try {
    const queryParams = qs.stringify(filters, {
      arrayFormat: "repeat",
      skipNulls: true,
      encode: false,
    });

    const response = await apiClient.get(`/parameters/export?${queryParams}`, {
      responseType: "blob",
    });

    return response;
  } catch (error) {
    handleApiError(error, "exporting", "parameters");
  }
};

export const createOrUpdateAndLinkCustomer = async ({
  customerId,
  customerData,
  venteId,
}) => {
  try {
    const response = await apiClient.post(
      "/customers/create-or-update-and-link",
      { customerId, customerData, venteId }
    );

    console.log("Response from createOrUpdateAndLinkCustomer:", response);

    return response.data;
  } catch (error) {
    handleApiError(error, "exporting", "parameters");
  }
};

export const requestSignature = async (venteId, phoneNumber) => {
  try {
    const response = await apiClient.post(`/signatures/${venteId}/request`, {
      phoneNumber,
    });
    message.success("Signature request sent successfully");
    return response.data;
  } catch (error) {
    handleApiError(error, "requesting", "signature");
  }
};

export const verifySignature = async (venteId, code) => {
  try {
    const response = await apiClient.post(`/signatures/${venteId}/verify`, {
      code,
    });
    message.success("Signature verified successfully");
    return response.data;
  } catch (error) {
    handleApiError(error, "verifying", "signature");
  }
};

export const getSignatureStatus = async (venteId) => {
  try {
    const response = await apiClient.get(`/signatures/${venteId}/status`);
    return response.data;
  } catch (error) {
    handleApiError(error, "fetching", "signature status");
  }
};

export default {
  getItems,
  getItemById,
  createItem,
  updateItem,
  deleteItem,
  bulkOperation,
  uploadFile,
  importItems,
  exportItems,
  generatePdfs,
  getPdfLinks,
  regeneratePdfs,
  fetchParameters,
  updateParameters,
  getParameterById,
  createParameter,
  deleteParameter,
  getUserByPhone,
  getuserByFirebaseUid,
  bulkOperationParameters,
  importParameters,
  exportParameters,
  linkFirebaseUser,
  createOrUpdateAndLinkCustomer,
  requestSignature,
  verifySignature,
  getSignatureStatus,
};
