import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  List,
  Modal,
  Collapse,
  Typography,
  Row,
  Col,
  message,
} from "antd";
import {
  FilePdfOutlined,
  MailOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import PdfPreview from "./PdfPreview";
import dayjs from "dayjs";

const { Panel } = Collapse;
const { Title } = Typography;

const calculateAge = (birthdate) => {
  return dayjs().diff(dayjs(birthdate), "year");
};

const generateEmailText = (formData) => {
  const { familyComposition, selectedProduct } = formData;
  const fullName = `${
    formData[62] === 137 ? "Madame " : "Monsieur "
  }${formData[63].charAt(0).toUpperCase()}${formData[63].slice(
    1
  )} ${formData[64].charAt(0).toUpperCase()}${formData[64].slice(1)} `;
  const age = calculateAge(familyComposition.mainInsuredBirthdate);

  return `Bonjour ${fullName},

Veuillez trouver ci-joint, les informations relatives à votre demande de tarif pour :
Assuré (${age} ans) - Gamme: ${selectedProduct.product.libelle} - Formule: ${
    selectedProduct.offer.libelle
  } pour un montant de ${selectedProduct.offer.cotisationMensuelle.toFixed(2)} €

Nous restons à votre disposition pour toute information complémentaire.

Cordialement,
Votre équipe d'assurance`;
};

const EmailSender = ({
  onSendEmail,
  initialSubject,
  onSubjectChange,
  onTextChange,
  pdfLinks,
  formData,
}) => {
  const [email, setEmail] = useState(formData.email || "");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState(initialSubject);
  const [text, setText] = useState(() => generateEmailText(formData));
  const [previewVisible, setPreviewVisible] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const generatedText = generateEmailText(formData);
    setText(generatedText);
    onTextChange(generatedText);
  }, [formData, onTextChange]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSendEmail(email, cc, subject, text);
      message.success("Email sent successfully!");
    } catch (error) {
      message.error("Failed to send email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = (url) => {
    setCurrentPdfUrl(url);
    setPreviewVisible(true);
  };

  const pdfLinksData = [
    { name: "Devoir Conseil", url: pdfLinks["devoir-conseil"] },
    { name: "Devis Générique", url: pdfLinks["devis-generique"] },
    { name: "Mandat Courtage", url: pdfLinks["mandat-courtage"] },
    { name: "Demande Adhésion", url: pdfLinks["demande-adhesion"] },
    { name: "Mandat Résiliation", url: pdfLinks["mandat-resiliation"] },
  ];

  return (
    <Form layout="vertical" className="max-w-2xl mx-auto">
      <Title level={4} className="mb-4">
        Send Email
      </Title>
      <Form.Item
        name="subject"
        required
        rules={[{ required: true, message: "Please enter a subject" }]}
        className="mb-2"
        initialValue={initialSubject}
      >
        <Input
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
            onSubjectChange(e.target.value);
          }}
          placeholder="Subject"
        />
      </Form.Item>
      <Row gutter={16} className="mb-2">
        <Col span={12}>
          <Form.Item
            name="email"
            required
            rules={[
              { required: true, message: "Please enter recipient's email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
            className="mb-0"
            initialValue={formData.email}
          >
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="To"
              prefix={<MailOutlined className="text-gray-400" />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="cc"
            rules={[{ type: "email", message: "Please enter a valid email" }]}
            className="mb-0"
          >
            <Input
              type="email"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
              placeholder="CC (optional)"
              prefix={<MailOutlined className="text-gray-400" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="message"
        required
        rules={[{ required: true, message: "Please enter a message" }]}
        className="mb-2"
        initialValue={generateEmailText(formData)}
      >
        <Input.TextArea
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            onTextChange(e.target.value);
          }}
          rows={6}
          className="font-mono"
          placeholder="Message"
        />
      </Form.Item>
      <Form.Item className="mb-2">
        <Collapse
          className="bg-gray-50 border border-gray-200 rounded-md"
          expandIcon={({ isActive }) =>
            isActive ? (
              <MinusOutlined className="text-blue-500" />
            ) : (
              <PlusOutlined className="text-blue-500" />
            )
          }
        >
          <Panel header="Attachments" key="1">
            <List
              size="small"
              dataSource={pdfLinksData}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="link"
                      onClick={() => handlePreview(item.url)}
                      className="text-blue-500 hover:text-blue-700 p-0"
                    >
                      Preview
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<FilePdfOutlined className="text-red-500" />}
                    title={item.name}
                  />
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          onClick={handleSubmit}
          icon={<MailOutlined />}
          className="w-full"
          loading={loading}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Email"}
        </Button>
      </Form.Item>
      <Modal
        title="PDF Preview"
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width={800}
      >
        <PdfPreview url={currentPdfUrl} />
      </Modal>
    </Form>
  );
};

export default EmailSender;
