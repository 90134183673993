import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaHome,
  FaEnvelope,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { NavBar } from "../models";
import { usePermissions } from "./../hooks/use-permissions.hook";
import { Spin } from "antd";
import { AuthContext } from "../contexts/AuthContext";

const SidebarNavigation = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [navItems, setNavItems] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const navRef = useRef(null);
  const { checkPermission, loading: permissionsLoading } = usePermissions();
  const { user, loading: userLoading, isInitialized } = useContext(AuthContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const hasPermissionForNavItem = useCallback(
    (navItem) => {
      return Object.values(navItem.menus).some((menuItem) => {
        return checkPermission(`${menuItem.module}:getAllFrom`, menuItem.addon);
      });
    },
    [checkPermission]
  );

  useEffect(() => {
    if (
      isInitialized &&
      !userLoading &&
      user &&
      user.role &&
      user.role.permissions
    ) {
      const filteredNavItems = NavBar.filter(hasPermissionForNavItem);
      setNavItems(filteredNavItems);
    }
  }, [isInitialized, userLoading, user, hasPermissionForNavItem]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleSubmenu = (navItemName, event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenSubmenus((prev) => ({
      ...prev,
      [navItemName]: !prev[navItemName],
    }));
  };

  const handleNavItemClick = (navItem, event) => {
    if (navItem.menus && Object.keys(navItem.menus).length > 0) {
      toggleSubmenu(navItem.name, event);
    } else {
      navigate(`/list/${navItem.plural.toLowerCase()}`);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const hasPermissionForSubMenu = (menuItem) => {
    return checkPermission(`${menuItem.module}:getAllFrom`, menuItem.addon);
  };

  if (!isInitialized || userLoading || permissionsLoading) {
    return (
      <div className="fixed left-0 top-0 h-full bg-white shadow-lg w-16 flex items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!user || !user.role || !user.role.permissions) {
    console.log("No user data or permissions available");
    return null;
  }

  return (
    <div
      ref={sidebarRef}
      className={`fixed left-0 top-0 h-full bg-white shadow-lg transition-all duration-300 ease-in-out z-50 ${
        sidebarOpen ? "w-64" : "w-16"
      }`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute top-4 right-4 p-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        aria-label={sidebarOpen ? "Close sidebar" : "Open sidebar"}
      >
        <FaBars className="text-gray-600 w-5 h-5" />
      </button>

      <nav
        ref={navRef}
        className="mt-16 p-4 h-[calc(100vh-4rem)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
      >
        <ul className="space-y-2">
          <li>
            <Link
              to="/"
              className={`flex items-center p-2 rounded-lg transition-colors duration-200 ${
                isActive("/")
                  ? "bg-indigo-100 text-indigo-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
              aria-label="Home"
            >
              <div
                className={`flex items-center justify-center ${
                  sidebarOpen ? "w-5" : "w-full"
                }`}
              >
                <FaHome className="w-5 h-5" />
              </div>
              {sidebarOpen && <span className="ml-3 font-medium">Home</span>}
            </Link>
          </li>

          {navItems.map((navItem) => {
            const IconComponent = navItem.icon;
            const hasSubMenu =
              navItem.menus && Object.keys(navItem.menus).length > 0;
            const isNavItemActive = isActive(
              `/${navItem.plural.toLowerCase()}`
            );

            return (
              <li key={navItem.name}>
                <div className="relative">
                  <button
                    onClick={(e) => handleNavItemClick(navItem, e)}
                    className={`w-full flex items-center justify-between p-2 rounded-lg transition-colors duration-200 ${
                      isNavItemActive
                        ? "bg-indigo-100 text-indigo-700"
                        : "text-gray-700 hover:bg-gray-100"
                    }`}
                    aria-expanded={openSubmenus[navItem.name]}
                    aria-haspopup={hasSubMenu}
                  >
                    <div className="flex items-center">
                      <div
                        className={`flex items-center justify-center ${
                          sidebarOpen ? "w-5" : "w-full"
                        }`}
                      >
                        <IconComponent className="w-5 h-5" />
                      </div>
                      {sidebarOpen && (
                        <span className="ml-3 font-medium">
                          {navItem.plural}
                        </span>
                      )}
                    </div>
                    {sidebarOpen && hasSubMenu && (
                      <span className="ml-2">
                        {openSubmenus[navItem.name] ? (
                          <FaChevronUp className="w-4 h-4 text-gray-500" />
                        ) : (
                          <FaChevronDown className="w-4 h-4 text-gray-500" />
                        )}
                      </span>
                    )}
                  </button>
                  {sidebarOpen && hasSubMenu && openSubmenus[navItem.name] && (
                    <ul className="mt-2 ml-6 space-y-2">
                      {Object.entries(navItem.menus).map(([key, menuItem]) => {
                        if (!hasPermissionForSubMenu(menuItem)) return null;

                        const SubMenuIcon = menuItem.icon;
                        const linkPath = menuItem.link
                          ? `/${menuItem.link}`
                          : `/list/${menuItem.plural.toLowerCase()}`;
                        return (
                          <li key={key}>
                            <Link
                              to={linkPath}
                              className={`flex items-center p-2 rounded-lg transition-colors duration-200 ${
                                isActive(linkPath)
                                  ? "bg-indigo-50 text-indigo-700"
                                  : "text-gray-600 hover:bg-gray-50"
                              }`}
                            >
                              <SubMenuIcon className="w-4 h-4 mr-3" />
                              <span className="text-sm">{menuItem.plural}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </li>
            );
          })}

          <li>
            <Link
              to="/contact"
              className={`flex items-center p-2 rounded-lg transition-colors duration-200 ${
                isActive("/contact")
                  ? "bg-indigo-100 text-indigo-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
              aria-label="Contact Us"
            >
              <div
                className={`flex items-center justify-center ${
                  sidebarOpen ? "w-5" : "w-full"
                }`}
              >
                <FaEnvelope className="w-5 h-5" />
              </div>
              {sidebarOpen && (
                <span className="ml-3 font-medium">Contact Us</span>
              )}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SidebarNavigation;
