import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Typography,
  Divider,
  Button,
  Descriptions,
  Tag,
  Tooltip,
  Space,
  Collapse,
  Statistic,
} from "antd";
import {
  SendOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
  MailOutlined,
} from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const SummaryView = ({ formData, onSendEmail, onValidate }) => {
  const { selectedProduct } = formData;

  if (!selectedProduct) {
    return (
      <Card className="shadow-lg rounded-lg">
        <Title level={3} className="text-center text-gray-500">
          No product selected
        </Title>
      </Card>
    );
  }

  const { product, option, offer } = selectedProduct;

  return (
    <Card className="shadow-lg rounded-lg overflow-hidden bg-white">
      <Space wrap style={{ display: "flex", justifyContent: "space-between" }}>
        <Button icon={<DownloadOutlined />} type="primary" ghost size="large">
          Download Details
        </Button>
        <Title level={2} className="mb-6 text-center text-blue-700">
          Product Summary
        </Title>
        {onSendEmail && (
          <Tooltip title="You need to send the email to proceed to the next step">
            <Button
              onClick={onSendEmail}
              icon={<MailOutlined />}
              type="primary"
              size="large"
            >
              Send Quote by Email
            </Button>
          </Tooltip>
        )}
      </Space>

      <Collapse defaultActiveKey={["1"]} className="mb-6">
        <Panel header={<Title level={4}>Product Information</Title>} key="1">
          <Descriptions bordered column={1} className="bg-gray-50">
            <Descriptions.Item
              label={<span className="font-semibold">Name</span>}
            >
              <Text strong className="text-lg">
                {product.libelle}
              </Text>
              <Text className="text-gray-500 ml-2">({product.code})</Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Insurer</span>}
            >
              {product.assureur}
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Family</span>}
            >
              <Tag color="blue" className="text-sm px-3 py-1">
                {product.famille}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Type</span>}
            >
              <Tag color="green" className="text-sm px-3 py-1">
                {product.type}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Distributor Code</span>}
            >
              {product.codeDistributeur}
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Description</span>}
            >
              <Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {product.description.replace(/<br\/>/g, "\n")}
              </Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>

      <Collapse className="mb-6">
        <Panel header={<Title level={4}>Selected Option</Title>} key="2">
          <Descriptions bordered column={1} className="bg-gray-50">
            <Descriptions.Item
              label={<span className="font-semibold">Option Name</span>}
            >
              <Text strong>{option.libelle}</Text>
              <Text className="text-gray-500 ml-2">({option.code})</Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span className="font-semibold">Description</span>}
            >
              {option.description}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>

      <Card className="mb-6 bg-blue-50 border-blue-200">
        <Title level={4} className="mb-4 text-blue-700">
          Offer Details
        </Title>
        <Descriptions bordered column={1}>
          <Descriptions.Item
            label={<span className="font-semibold">Offer Name</span>}
          >
            <Text strong>{offer.libelle}</Text>
            <Text className="text-gray-500 ml-2">({offer.code})</Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={<span className="font-semibold">Description</span>}
          >
            {offer.description}
          </Descriptions.Item>
        </Descriptions>
        <Space className="mt-4" size="large" wrap>
          <Statistic
            title="Monthly Premium"
            value={offer.cotisationMensuelle}
            precision={2}
            prefix="€"
          />
          <Statistic
            title="Annual Premium"
            value={offer.cotisationAnnuelle}
            precision={2}
            prefix="€"
          />
          <Tooltip title="Includes upfront fees and direct debit fees">
            <Statistic
              title={
                <span>
                  Management Fees{" "}
                  <InfoCircleOutlined className="text-blue-500" />
                </span>
              }
              value={offer.fraisGestionComptant + offer.fraisPrelevement}
              precision={2}
              prefix="€"
            />
          </Tooltip>
        </Space>
      </Card>

      <Divider />

      <div className="flex justify-between items-center mb-6">
        <Title level={3}>Total Monthly Premium</Title>
        <Title level={2} className="text-blue-600">
          €{offer.cotisationMensuelle.toFixed(2)}
        </Title>
      </div>
    </Card>
  );
};

SummaryView.propTypes = {
  formData: PropTypes.shape({
    selectedProduct: PropTypes.shape({
      product: PropTypes.object.isRequired,
      option: PropTypes.object.isRequired,
      offer: PropTypes.object.isRequired,
    }),
  }).isRequired,
  onSendEmail: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default SummaryView;
