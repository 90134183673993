import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Select,
  DatePicker,
  Button,
  Card,
  Radio,
  Typography,
  Space,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import CityInput from "./../form-hooks-and-helpers/renderField/cities";

const { Option } = Select;
const { Title, Text } = Typography;

const FamilyCompositionForm = ({ formData, setFormData }) => {
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isPJSelected, setIsPJSelected] = useState(false);
  const [cityData, setCityData] = useState(null);

  console.log("FamilyCompositionForm -> formData", formData);
  const categories = [
    { key: "SANTE", label: "Complémentaire santé", icon: "🏥" },
    { key: "PREVOYANCE", label: "Prévoyance", icon: "🛡️" },
  ];

  const regimeOptions = [
    { value: "SAL", label: "Sécurité Sociale" },
    { value: "TNS", label: "TNS" },
    { value: "SAL-AGR", label: "Salarié Agricole" },
    { value: "SAL-AM", label: "Alsace / Moselle" },
    { value: "TNS-AGR", label: "Exploitant Agricole" },
  ];

  useEffect(() => {
    if (formData.familyComposition?.familyMembers) {
      setFamilyMembers(
        formData.familyComposition.familyMembers.map((member) => ({
          ...member,
          birthDate: member.birthDate ? dayjs(member.birthDate) : null,
        }))
      );
    }

    const firstDayOfNextMonth = dayjs().add(1, "month").startOf("month");
    handleInputChange("startDate", firstDayOfNextMonth.format("YYYY-MM-DD"));
  }, [formData.familyComposition?.familyMembers]);

  useEffect(() => {
    setSelectedCategory(formData.familyComposition?.gamme);
  }, [formData.familyComposition?.gamme]);

  const handleInputChange = useCallback(
    (field, value) => {
      console.log(
        "FamilyCompositionForm -> handleInputChange -> field, value",
        field,
        value
      );
      setFormData((prevData) => ({
        ...prevData,
        familyComposition: {
          ...prevData.familyComposition,
          [field]: value,
        },
      }));
    },
    [setFormData]
  );

  const handleCityChange = useCallback(
    (value) => {
      handleInputChange("city", value);
    },
    [handleInputChange]
  );

  const handleCityExtraDataChange = useCallback(
    (extraData) => {
      setCityData(extraData);
      if (extraData && extraData.code && extraData.code.length > 0) {
        handleInputChange("postalCode", extraData.code);
      }
    },
    [handleInputChange]
  );

  const handleFamilyMemberChange = useCallback(
    (index, field, value) => {
      setFamilyMembers((prevMembers) => {
        const updatedMembers = [...prevMembers];
        updatedMembers[index] = {
          ...updatedMembers[index],
          [field]: field === "birthDate" ? value?.format("YYYY-MM-DD") : value,
        };
        handleInputChange("familyMembers", updatedMembers);
        return updatedMembers;
      });
    },
    [handleInputChange]
  );

  const addFamilyMember = useCallback(
    (type) => {
      if (
        type === "Conjoint" &&
        familyMembers.some((member) => member.type === "Conjoint")
      ) {
        return;
      }
      const newMember = { type, birthDate: null, regime: "SAL" };
      setFamilyMembers((prevMembers) => [...prevMembers, newMember]);
      handleInputChange("familyMembers", [...familyMembers, newMember]);
    },
    [familyMembers, handleInputChange]
  );

  const removeFamilyMember = useCallback(
    (index) => {
      setFamilyMembers((prevMembers) => {
        const updatedMembers = prevMembers.filter((_, i) => i !== index);
        handleInputChange("familyMembers", updatedMembers);
        return updatedMembers;
      });
    },
    [handleInputChange]
  );

  const handleCategoryChange = useCallback(
    (e) => {
      const category = e.target.value;
      setSelectedCategory(category);
      handleInputChange("gamme", category);
    },
    [handleInputChange]
  );

  const togglePJ = useCallback(() => {
    setIsPJSelected((prev) => !prev);
    handleInputChange("selectionGamme", {
      gamme: selectedCategory,
      gammeOptions: isPJSelected ? [] : ["PJ"],
    });
  }, [isPJSelected, selectedCategory, handleInputChange]);

  const renderFamilyMember = useCallback(
    (member, index) => (
      <Card
        key={index}
        className="mb-4 shadow-sm hover:shadow-md transition-shadow duration-300"
        title={
          <div className="flex justify-between items-center">
            <Text strong className="text-lg text-blue-500">
              {member.type}
            </Text>
            <Button
              icon={<CloseOutlined />}
              onClick={() => removeFamilyMember(index)}
              type="text"
              danger
            />
          </div>
        }
      >
        <Space direction="vertical" size="middle" className="w-full">
          <DatePicker
            className="w-full"
            format="DD/MM/YYYY"
            placeholder="Date de naissance"
            value={member.birthDate ? dayjs(member.birthDate) : null}
            onChange={(date) =>
              handleFamilyMemberChange(index, "birthDate", date)
            }
          />
          <Select
            className="w-full"
            placeholder="Régime"
            value={member.regime}
            onChange={(value) =>
              handleFamilyMemberChange(index, "regime", value)
            }
          >
            {regimeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Space>
      </Card>
    ),
    [handleFamilyMemberChange, removeFamilyMember]
  );

  return (
    <div className="bg-gray-50 p-6 rounded-lg shadow-lg font-sans">
      <Title level={2} className="mb-6">
        Choix de la gamme et composition de la famille
      </Title>

      <Card className="mb-6">
        <Title level={4} className="mb-4">
          Sélection de la gamme
        </Title>
        <Space direction="vertical" size="large" className="w-full">
          <Radio.Group
            onChange={handleCategoryChange}
            value={selectedCategory}
            className="w-full"
          >
            <Space direction="vertical" className="w-full">
              {categories.map((category) => (
                <Radio
                  key={category.key}
                  value={category.key}
                  className="w-full p-3 border rounded-md hover:bg-gray-100 transition-colors duration-300"
                >
                  <Space>
                    <span className="text-2xl">{category.icon}</span>
                    <span>{category.label}</span>
                  </Space>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </Card>

      <Card className="mb-6">
        <Title level={4} className="mb-4">
          Informations générales
        </Title>
        <Space direction="vertical" size="middle" className="w-full">
          <div>
            <Text strong className="block mb-2">
              Date d'effet souhaitée
            </Text>
            <DatePicker
              className="w-full"
              format="DD/MM/YYYY"
              value={
                formData.familyComposition?.startDate
                  ? dayjs(formData.familyComposition.startDate)
                  : null
              }
              disabled={true}
            />
          </div>
          <div>
            <Text strong className="block mb-2">
              Code Postal / Ville
            </Text>
            <CityInput
              value={`${formData.familyComposition?.postalCode || ""} ${
                formData.familyComposition?.city || ""
              }`.trim()}
              onChange={handleCityChange}
              onExtraDataChange={handleCityExtraDataChange}
              placeholder="Entrez un code postal ou une ville"
            />
          </div>
        </Space>
      </Card>

      <Card title={<Title level={4}>Assuré principal</Title>} className="mb-6">
        <Space direction="vertical" size="middle" className="w-full">
          <DatePicker
            className="w-full"
            format="DD/MM/YYYY"
            placeholder="Date de naissance"
            value={
              formData.familyComposition?.mainInsuredBirthdate
                ? dayjs(formData.familyComposition.mainInsuredBirthdate)
                : null
            }
            onChange={(date) =>
              handleInputChange(
                "mainInsuredBirthdate",
                date?.format("YYYY-MM-DD")
              )
            }
          />
          <Select
            value={formData.familyComposition?.regime}
            onChange={(value) => handleInputChange("regime", value)}
            className="w-full"
            placeholder="Sélectionnez un régime"
          >
            {regimeOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Space>
      </Card>

      <Card
        title={
          <Space>
            <Title level={4}>Membres de la famille</Title>
            <Tooltip title="Ajoutez ici les membres de votre famille que vous souhaitez assurer">
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        className="mb-6"
      >
        {familyMembers.map(renderFamilyMember)}
        <Space className="mt-4">
          <Button
            icon={<PlusOutlined />}
            onClick={() => addFamilyMember("Conjoint")}
            type="dashed"
            disabled={familyMembers.some(
              (member) => member.type === "Conjoint"
            )}
          >
            Ajouter un conjoint
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => addFamilyMember("Enfant")}
            type="dashed"
          >
            Ajouter un enfant
          </Button>
        </Space>
      </Card>

      {selectedCategory && (
        <Card title={<Title level={4}>Gamme sélectionnée</Title>}>
          <Text strong>
            {categories.find((c) => c.key === selectedCategory)?.label}
          </Text>
          {isPJSelected && (
            <Text className="block mt-2">
              + Protection Juridique Individuelle
            </Text>
          )}
        </Card>
      )}
    </div>
  );
};

FamilyCompositionForm.propTypes = {
  formData: PropTypes.shape({
    familyComposition: PropTypes.shape({
      startDate: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
      mainInsuredBirthdate: PropTypes.string,
      regime: PropTypes.string,
      familyMembers: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          birthDate: PropTypes.string,
          regime: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default React.memo(FamilyCompositionForm);
