import React, { useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useRowSelect,
  useColumnOrder,
} from "react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableHeader from "./DraggableHeader";
import { useHorizontalScroll } from "./useHorizontalScroll.hook";
import Pagination from "./Pagination";
import { FaSpinner } from "react-icons/fa";
import CellRenderer from "./cell-render";
import { usePermissions } from "./../../../hooks/use-permissions.hook";

const TableComponent = ({
  data,
  pageCount: controlledPageCount,
  fetchData,
  moduleConfig,
  onDelete,
  setSelectedRows,
  navigate,
  columns,
  columnVisibility,
  pagination,
  loading,
}) => {
  const [tableRef, hasHorizontalScroll] = useHorizontalScroll();

  const visibleColumns = useMemo(() => {
    return columns
      .filter((column) => {
        const columnKey = column.id || column.accessor;
        // Check if the column should be excluded based on moduleConfig
        const shouldExclude = moduleConfig.excludeColumns?.includes(columnKey);
        return columnKey && columnVisibility[columnKey] && !shouldExclude;
      })
      .map((column) => ({
        ...column,
        // You can add any additional column config here if needed
      }));
  }, [columns, columnVisibility, moduleConfig.excludeColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy, filters },
    setColumnOrder,
  } = useTable(
    {
      columns: visibleColumns,
      data,
      initialState: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters });
  }, [fetchData, pageIndex, pageSize, sortBy, filters]);

  const moveColumn = (dragIndex, hoverIndex) => {
    const newOrder = [...headerGroups[0].headers];
    const dragColumn = newOrder[dragIndex];
    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragColumn);
    setColumnOrder(newOrder.map((column) => column.id));
  };

  const renderTableContent = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={visibleColumns.length} className="text-center py-8">
            <FaSpinner className="inline-block animate-spin text-2xl text-blue-500 mr-2" />
            <span className="text-gray-600">Loading data...</span>
          </td>
        </tr>
      );
    }

    if (data.length === 0) {
      return (
        <tr>
          <td colSpan={visibleColumns.length} className="text-center py-8">
            <p className="text-gray-500">No data available</p>
            <p className="text-sm text-gray-400 mt-2">
              Try adjusting your filters or add new entries
            </p>
          </td>
        </tr>
      );
    }

    return page.map((row) => {
      prepareRow(row);
      const { key, ...rowProps } = row.getRowProps();
      return (
        <tr key={key} {...rowProps}>
          {row.cells.map((cell) => {
            const { key: cellKey, ...cellProps } = cell.getCellProps();

            return (
              <td
                key={cellKey}
                {...cellProps}
                className="px-4 py-2 whitespace-nowrap"
              >
                <CellRenderer cell={cell} moduleConfig={moduleConfig} />
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <>
      <div className="overflow-x-auto" ref={tableRef}>
        <DndProvider backend={HTML5Backend}>
          <table
            {...getTableProps()}
            className="min-w-full divide-y divide-gray-200"
          >
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr key={key} {...headerGroupProps}>
                    {headerGroup.headers.map((column, index) => (
                      <DraggableHeader
                        key={column.id}
                        column={column}
                        index={index}
                        moveColumn={moveColumn}
                      />
                    ))}
                  </tr>
                );
              })}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200"
            >
              {renderTableContent()}
            </tbody>
          </table>
        </DndProvider>
        {hasHorizontalScroll && (
          <div className="text-center mt-2 text-gray-500">
            ⬅️ Scroll horizontally to see more ➡️
          </div>
        )}
      </div>
      {!loading && data.length > 0 && (
        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageOptions={pageOptions}
        />
      )}
    </>
  );
};

export default TableComponent;
