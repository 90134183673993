import { useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const usePermissions = () => {
  const { user, loading: userLoading, isInitialized } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isInitialized && !userLoading) {
      setLoading(false);
    }
  }, [isInitialized, userLoading]);

  const checkPermission = useCallback(
    (action, addon) => {
      if (!user || !user.role || !user.role.permissions) {
        return false;
      }

      const [module, method] = action.split(":");

      const moduleName =
        module.charAt(0).toUpperCase() + module.slice(1) + "Module";
      const controllerName =
        module.charAt(0).toUpperCase() + module.slice(1) + "Controller";
      const methodName = addon
        ? method + addon.charAt(0).toUpperCase() + addon.slice(1)
        : method + module.charAt(0).toUpperCase() + module.slice(1);

      return (
        user.role.permissions &&
        user.role.permissions[moduleName] &&
        user.role.permissions[moduleName][controllerName] &&
        !!user.role.permissions[moduleName][controllerName][
          `${moduleName}:${controllerName}:${methodName}`
        ]
      );
    },
    [user]
  );

  return { checkPermission, loading };
};
