import { FaTeamspeak } from "react-icons/fa6";

import FormConfig from "./team.form";
import TableConfig from "./team.table";

export const teamConfig = {
  module: "team",
  single: "team",
  plural: "teams",
  label: "Équipes",
  path: "/team",
  api: "teams",
  icon: FaTeamspeak,
  displayColumns: ["id", "code", "name", "teamLeader"],
  excludeColumns: ["createdAt", "updatedAt", "teamMembers", "providers"],
  table: TableConfig,
  form: FormConfig,
};

export default teamConfig;
