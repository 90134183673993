import React, { useState, useRef, useEffect } from "react";
import { FiChevronDown, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const TabHeader = ({ config, activeTab, setActiveTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const tabsRef = useRef(null);

  const scrollTabs = (direction) => {
    if (tabsRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      tabsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const checkForArrows = () => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    checkForArrows();
    window.addEventListener("resize", checkForArrows);
    return () => window.removeEventListener("resize", checkForArrows);
  }, []);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      if (activeTabElement) {
        activeTabElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
    checkForArrows();
  }, [activeTab]);

  return (
    <div className="container w-full">
      <div className="border-b border-gray-200 relative w-full">
        {/* Desktop version */}
        <div className="hidden md:block relative">
          <div className="flex items-center">
            {showLeftArrow && (
              <button
                onClick={() => scrollTabs("left")}
                className="absolute left-0 z-10 p-2 bg-white"
              >
                <FiChevronLeft />
              </button>
            )}
            <div
              ref={tabsRef}
              className="flex overflow-x-auto scrollbar-hide w-[90%]"
              onScroll={checkForArrows}
            >
              {config.form.tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => setActiveTab(index)}
                  className={`
                    whitespace-nowrap py-4 px-4 border-b-2 font-medium text-sm flex-shrink-0
                    ${
                      activeTab === index
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    }
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            {showRightArrow && (
              <button
                onClick={() => scrollTabs("right")}
                className="absolute right-0 z-10 p-2 bg-white"
              >
                <FiChevronRight />
              </button>
            )}
          </div>
        </div>

        {/* Mobile version */}
        <div className="md:hidden relative">
          <button
            type="button"
            className="w-full py-4 px-4 text-left text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex justify-between items-center"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>{config.form.tabs[activeTab].label}</span>
            <FiChevronDown className="ml-2 h-5 w-5 text-gray-400" />
          </button>

          {isOpen && (
            <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {config.form.tabs.map((tab, index) => (
                <button
                  key={index}
                  type="button"
                  className={`
                    ${
                      activeTab === index
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-700"
                    }
                    block w-full px-4 py-2 text-left text-sm hover:bg-gray-50
                  `}
                  onClick={() => {
                    setActiveTab(index);
                    setIsOpen(false);
                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabHeader;
