const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: [
        "username",
        "password",
        "code",
        "code_externe",
        "token_publique",
        "offre",
      ],
    },
    {
      label: "Paramètres",
      fields: [
        "ignore",
        "date_fin",
        "is_prospect_reserved",
        "connexion_interne_seulement",
        "ip_authorized",
      ],
    },
    {
      label: "Associations",
      fields: ["company", "provider", "team", "role"],
    },
  ],
  fields: [
    {
      name: "username",
      label: "Nom d'utilisateur",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "password",
      label: "Mot de passe",
      type: "password",
      filtrable: false,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code",
      label: "Code",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_externe",
      label: "Code externe",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "token_publique",
      label: "Token public",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "offre",
      label: "Offre",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "ignore",
      label: "Ignorer",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "date_fin",
      label: "Date de fin",
      type: "date",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "is_prospect_reserved",
      label: "Prospect réservé",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "connexion_interne_seulement",
      label: "Connexion interne seulement",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "ip_authorized",
      label: "IP autorisées",
      type: "array",
      filtrable: false,
      required: false,
      className: "col-span-12",
      response: "string",
      fields: [
        {
          name: "ip",
          label: "Adresse IP",
          type: "text",
          required: true,
          className: "col-span-12 md:col-span-6",
        },
      ],
    },
    {
      name: "company",
      label: "Entreprise",
      type: "api-select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      module: "companies",
      attribute: "raisonSociale",
    },
    {
      name: "provider",
      label: "Fournisseur",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      module: "providers",
      attribute: "nom",
    },
    {
      name: "team",
      label: "Équipe",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      module: "teams",
      attribute: "name",
    },
    {
      name: "role",
      label: "Role",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      module: "roles",
      attribute: "name",
    },
  ],
};

export default form;
