import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Button,
  Tooltip,
  Modal,
  Tag,
  Progress,
  Typography,
  Layout,
  Row,
  Col,
} from "antd";
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const ProductDisplay = ({ solutions, onSelectProduct, isProductConfirmed }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleProductSelect = useCallback((product) => {
    setSelectedProduct(product);
    setSelectedOption(null);
    setSelectedOffer(null);
  }, []);

  const handleOptionSelect = useCallback((option) => {
    setSelectedOption(option);
    setSelectedOffer(null);
  }, []);

  const handleOfferSelect = useCallback((offer) => {
    setSelectedOffer(offer);
  }, []);

  const handleInfoClick = useCallback((content) => {
    setModalContent(content);
    setShowModal(true);
  }, []);

  const handleConfirm = useCallback(() => {
    if (selectedProduct && selectedOption && selectedOffer) {
      const { options, ...productWithoutOptions } = selectedProduct;
      const { offres, ...optionWithoutOffers } = selectedOption;
      onSelectProduct({
        product: productWithoutOptions,
        option: optionWithoutOffers,
        offer: selectedOffer,
      });
    }
  }, [selectedProduct, selectedOption, selectedOffer, onSelectProduct]);

  const productCards = useMemo(
    () =>
      solutions.map((product) => (
        <Card
          key={product.ident}
          className={`mb-4 shadow-sm hover:shadow-md transition-all duration-300 ${
            selectedProduct?.ident === product.ident
              ? "border-blue-600 border-2"
              : "border-gray-200"
          }`}
          onClick={() => handleProductSelect(product)}
          hoverable
        >
          <div className="flex justify-between items-start">
            <Title level={5} className="text-blue-800 m-0">
              {product.libelle}
            </Title>
            <Tooltip title="Voir les détails">
              <Button
                type="text"
                icon={<InfoCircleOutlined />}
                className="text-blue-600 p-0"
                onClick={(e) => {
                  e.stopPropagation();
                  handleInfoClick(product.description);
                }}
                aria-label="Voir les détails du produit"
              />
            </Tooltip>
          </div>
          <Paragraph className="text-gray-600 mt-1 text-sm">
            {product.assureur}
          </Paragraph>
          <Tag color="blue" className="mt-2 text-xs">
            {product.famille}
          </Tag>
        </Card>
      )),
    [solutions, selectedProduct, handleProductSelect, handleInfoClick]
  );

  const optionCards = useMemo(
    () =>
      selectedProduct?.options?.map((option) => (
        <Card
          key={option.code}
          className={`mb-4 cursor-pointer hover:shadow-md transition-all duration-300 ${
            selectedOption?.code === option.code
              ? "border-blue-600 border-2"
              : "border-gray-200"
          }`}
          onClick={() => handleOptionSelect(option)}
        >
          <Title level={5} className="text-blue-800 m-0">
            {option.libelle}
          </Title>
          <Paragraph className="text-gray-600 mt-1 text-sm">
            {option.description}
          </Paragraph>
        </Card>
      )),
    [selectedProduct, selectedOption, handleOptionSelect]
  );

  const offerCards = useMemo(
    () =>
      selectedOption?.offres?.map((offer) => {
        const maxOffer = selectedOption.offres.reduce((max, o) =>
          o.cotisationMensuelle > max.cotisationMensuelle ? o : max
        );
        const percentage = Math.round(
          (offer.cotisationMensuelle / maxOffer.cotisationMensuelle) * 100
        );

        return (
          <Card
            key={offer.code}
            className={`mb-4 cursor-pointer hover:shadow-md transition-all duration-300 ${
              selectedOffer?.code === offer.code
                ? "border-green-600 border-2"
                : "border-gray-200"
            }`}
            onClick={() => handleOfferSelect(offer)}
          >
            <div className="flex justify-between items-center">
              <div>
                <Title level={5} className="text-blue-800 m-0">
                  {offer.libelle}
                </Title>
                <Paragraph className="text-gray-600 mt-1 text-sm">
                  {offer.description}
                </Paragraph>
              </div>
              <div className="text-right">
                <Paragraph className="text-xl font-bold text-green-700 m-0">
                  €{offer.cotisationMensuelle.toFixed(2)}
                  <span className="text-xs font-normal text-gray-500">
                    /mois
                  </span>
                </Paragraph>
                <Paragraph className="text-xs text-gray-500 m-0">
                  €{offer.cotisationAnnuelle.toFixed(2)}/an
                </Paragraph>
                <Progress
                  percent={percentage}
                  size="small"
                  showInfo={false}
                  strokeColor="#047857"
                />
              </div>
            </div>
          </Card>
        );
      }),
    [selectedOption, selectedOffer, handleOfferSelect]
  );

  if (solutions.length === 0) {
    return (
      <div className="text-center p-8">
        <Title level={2} className="text-gray-700">
          Aucun produit disponible
        </Title>
        <Paragraph className="text-gray-600">
          Désolé, aucun produit ne correspond aux critères fournis.
        </Paragraph>
      </div>
    );
  }

  return (
    <Layout className="bg-gray-50 min-h-screen">
      <Header className="bg-white border-b border-gray-200 px-4 flex justify-between items-center">
        <Title level={3} className="text-blue-900 m-0">
          Sélection de Produit d'Assurance
        </Title>
        <Button
          type="primary"
          size="large"
          icon={<CheckCircleOutlined />}
          onClick={handleConfirm}
          disabled={!selectedOffer}
          className={`${
            isProductConfirmed
              ? "bg-green-600 border-green-700 hover:bg-green-700"
              : "bg-blue-600 hover:bg-blue-700"
          } text-white`}
        >
          Confirmer la sélection
        </Button>
      </Header>
      <Content className="p-6">
        <Row gutter={16}>
          <Col
            span={6}
            className="overflow-auto"
            style={{ height: "calc(100vh - 140px)" }}
          >
            <Title level={4} className="text-gray-800 mb-4">
              Produits
            </Title>
            {productCards}
          </Col>
          <Col
            span={6}
            className="overflow-auto"
            style={{ height: "calc(100vh - 140px)" }}
          >
            {selectedProduct && (
              <>
                <Title level={4} className="text-gray-800 mb-4">
                  Options pour {selectedProduct.libelle}
                </Title>
                {optionCards}
              </>
            )}
          </Col>
          <Col
            span={12}
            className="overflow-auto"
            style={{ height: "calc(100vh - 140px)" }}
          >
            {selectedOption && (
              <>
                <Title level={4} className="text-gray-800 mb-4">
                  Offres pour {selectedOption.libelle}
                </Title>
                {offerCards}
              </>
            )}
          </Col>
        </Row>
      </Content>

      <Modal
        title={
          <Title level={4} className="text-blue-800">
            Détails du Produit
          </Title>
        }
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="close" onClick={() => setShowModal(false)} size="large">
            Fermer
          </Button>,
        ]}
        width={700}
      >
        <div
          className="text-gray-700 text-base"
          dangerouslySetInnerHTML={{ __html: modalContent }}
        />
      </Modal>
    </Layout>
  );
};

ProductDisplay.propTypes = {
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      ident: PropTypes.number.isRequired,
      libelle: PropTypes.string.isRequired,
      assureur: PropTypes.string.isRequired,
      famille: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          libelle: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          offres: PropTypes.arrayOf(
            PropTypes.shape({
              code: PropTypes.string.isRequired,
              libelle: PropTypes.string.isRequired,
              description: PropTypes.string,
              cotisationMensuelle: PropTypes.number.isRequired,
              cotisationAnnuelle: PropTypes.number.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  isProductConfirmed: PropTypes.bool.isRequired,
};

export default React.memo(ProductDisplay);
