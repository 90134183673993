import React from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableHeader = ({ column, index, moveColumn }) => {
  const [, drag] = useDrag({
    type: "COLUMN",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "COLUMN",
    hover(item) {
      if (item.index !== index) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  const { key, ...headerProps } = column.getHeaderProps(
    column.getSortByToggleProps()
  );

  return (
    <th
      key={key}
      ref={(node) => drag(drop(node))}
      {...headerProps}
      className="px-4 py-2 bg-gray-100 font-bold text-left"
    >
      {column.render("Header")}
      <span>
        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
      </span>
    </th>
  );
};

export default DraggableHeader;
