const ThreeStateSwitch = ({ state, onChange }) => {
  const getBackgroundColor = () => {
    switch (state) {
      case "checked":
        return "#1890ff";
      case "intermediate":
        return "#91d5ff";
      default:
        return "#aaa";
    }
  };

  const getHandlePosition = () => {
    switch (state) {
      case "checked":
        return "calc(100% - 22px)";
      case "intermediate":
        return "50%";
      default:
        return "12px";
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onChange(state === "checked" ? "unchecked" : "checked");
      }}
      style={{
        width: "54px",
        height: "22px",
        backgroundColor: getBackgroundColor(),
        borderRadius: "11px",
        cursor: "pointer",
        position: "relative",
        transition: "background-color 0.3s",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: getHandlePosition(),
          top: "2px",
          width: "18px",
          height: "18px",
          backgroundColor: "white",
          borderRadius: "9px",
          transition: "left 0.3s",
          transform: state === "checked" ? "none" : "translateX(-50%)",
        }}
      />
    </div>
  );
};

export default ThreeStateSwitch;
