const table = {
  actions: [
    {
      label: "Import",
      type: "upload",
      endpoint: "/api/provider-groups/import",
      accept: ".csv",
    },
    {
      label: "Exporter",
      type: "download",
      endpoint: "/api/provider-groups/export",
      filename: "provider-groups.csv",
    },
    {
      label: "Ajouter",
      type: "add",
      endpoint: "/api/provider-groups",
    },
  ],
};

export default table;
