import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  Card,
  Typography,
  Space,
  Divider,
  Tooltip,
} from "antd";
import {
  BankOutlined,
  GlobalOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { getItemById } from "../../../services/api";
import { savePartialCustomerData } from "./savePartialCustomerData";
import AddressAutocomplete from "./../form-hooks-and-helpers/renderField/address";

const { Title } = Typography;
const { Option } = Select;

const AdditionalInformationStep2 = ({
  formData,
  setFormData,
  customerId,
  setCustomerId,
  venteId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      console.log("customerId", customerId);
      if (customerId) {
        try {
          setLoading(true);
          const customerData = await getItemById("customers", customerId);
          form.setFieldsValue(customerData);
        } catch (error) {
          console.error("Error fetching customer data:", error);
          message.error("Failed to load customer data");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCustomerData();
  }, [customerId, form]);

  const handleAddressChange = (address, extraData) => {
    form.setFieldsValue({
      address: address,
      postalCode: extraData?.postcode,
      city: extraData?.city,
      country: "france",
    });
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await savePartialCustomerData(
        values,
        customerId,
        setCustomerId,
        venteId,
        setFormData
      );
      message.success("Information saved successfully");
    } catch (error) {
      console.log(error);
      message.error("Failed to save information");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="shadow-lg rounded-lg bg-white">
      <Title level={3} className="mb-6 text-center text-gray-800">
        Payment and Bank Information
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={formData}
        className="max-w-2xl mx-auto"
      >
        <Space direction="vertical" size="large" className="w-full">
          <Form.Item
            name="paymentFrequency"
            label="Payment Frequency"
            rules={[
              { required: true, message: "Please select a payment frequency" },
            ]}
          >
            <Select placeholder="Select payment frequency">
              <Option value="monthly">Monthly</Option>
              <Option value="quarterly">Quarterly</Option>
              <Option value="annually">Annually</Option>
            </Select>
          </Form.Item>

          <Divider orientation="left">Bank Details</Divider>

          <Form.Item
            name="address"
            label="Bank Address"
            rules={[
              { required: true, message: "Please enter the bank address" },
            ]}
          >
            <AddressAutocomplete
              onChange={(value, option) =>
                handleAddressChange(value, option?.extraData)
              }
              placeholder="Enter bank address"
              className="w-full"
            />
          </Form.Item>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item
              name="bankCity"
              label="Bank City"
              rules={[
                { required: true, message: "Please enter the bank city" },
              ]}
            >
              <Input prefix={<BankOutlined />} placeholder="Enter bank city" />
            </Form.Item>
            <Form.Item
              name="bankCountry"
              label="Bank Country"
              rules={[
                { required: true, message: "Please select the bank country" },
              ]}
            >
              <Select
                placeholder="Select bank country"
                prefix={<GlobalOutlined />}
              >
                <Option value="france">France</Option>
                <Option value="belgium">Belgium</Option>
                <Option value="switzerland">Switzerland</Option>
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            name="iban"
            label="IBAN"
            rules={[{ required: true, message: "Please enter your IBAN" }]}
          >
            <Input
              prefix={<CreditCardOutlined />}
              placeholder="Enter your IBAN"
            />
          </Form.Item>

          <Form.Item
            name="bic"
            label="BIC"
            rules={[{ required: true, message: "Please enter your BIC" }]}
          >
            <Input prefix={<BankOutlined />} placeholder="Enter your BIC" />
          </Form.Item>

          <Form.Item
            name="prestationBancaire"
            label={
              <span>
                Prestation Bancaire
                <Tooltip title="Indicates whether you require additional banking services">
                  <QuestionCircleOutlined className="ml-2" />
                </Tooltip>
              </span>
            }
            rules={[{ required: true, message: "Please select an option" }]}
          >
            <Select placeholder="Select an option">
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Form.Item>
        </Space>

        <Form.Item className="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="w-full h-10 text-lg font-semibold bg-blue-600 hover:bg-blue-700"
          >
            {loading ? "Saving..." : "Save Information"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdditionalInformationStep2;
