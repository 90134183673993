import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { Button, Menu, Dropdown, Spin } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { useError } from "../contexts/ErrorContext";
import ErrorDisplay from "./common/ErrorDisplay";
import {
  ArrowLeftOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SidebarNavigation from "./sidebar-navigation";
import { usePermissions } from "./../hooks/use-permissions.hook";
import { AuthContext } from "../contexts/AuthContext";

const Layout = ({ children, pageTitle, actionComponents = [] }) => {
  const { globalError, clearError } = useError();
  const navigate = useNavigate();
  const location = useLocation();
  const { checkPermission } = usePermissions();
  const {
    user,
    loading: userLoading,
    error: authError,
  } = useContext(AuthContext);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/auth");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const menuNew = [
    {
      key: "profileMenu",
      label: checkPermission(`profile:read`) && (
        <div key="profile" style="flex ">
          <UserOutlined />
          <Link to="/profile">Your Profile</Link>
        </div>
      ),
    },
    {
      key: "change-password",
      label: (
        <div
          key="signout"
          style={{
            flex: "1 1 auto",
            minWidth: "100px",
            cursor: "pointer",
          }}
          onClick={handleSignOut}
        >
          <LogoutOutlined />
          <span> Sign Out</span>
        </div>
      ),
    },
  ].filter(Boolean);

  if (userLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  if (authError) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ErrorDisplay error={authError} onClose={() => {}} />
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-100">
      <SidebarNavigation />

      {/* Main Content */}
      <div className="flex-1 flex flex-col ml-16">
        {/* Header */}
        <header className="bg-white shadow-md flex items-center justify-between h-16 px-4">
          <div className="flex items-center">
            {location.pathname !== "/" && (
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={handleGoBack}
                className="mr-4"
              >
                Go Back
              </Button>
            )}
            <h1 className="text-xl font-semibold mr-4">Ascrib</h1>
            {pageTitle && (
              <h2 className="text-lg font-medium text-gray-600">{pageTitle}</h2>
            )}
          </div>
          <div className="flex items-center">
            {actionComponents.map((Component, index) => (
              <div key={index} className="mr-2">
                {Component}
              </div>
            ))}
            <Dropdown menu={{ items: menuNew }} trigger={["click"]}>
              <Button className="flex items-center" icon={<FaUserCircle />}>
                {user?.username || "Profile"}
              </Button>
            </Dropdown>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 p-4 overflow-y-auto">
          <ErrorDisplay error={globalError} onClose={clearError} />
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
