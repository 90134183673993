import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import HomePage from "./components/pages/Home.page";
import AuthPage from "./components/pages/Auth.page";
import ContactPage from "./components/pages/Contact.page";
import ProfilePage from "./components/pages/Profile.page";
import { models } from "./models";
import DataTable from "./components/pages/table.page";
import TabbedForm from "./components/pages/form.page";
import RoleManagement from "./components/pages/roleManagement.page";
import NewProject from "./components/pages/new-project.page";
import NewProjectConfirmation from "./components/pages/new-project-confirmation.page";
import LoadingComponent from "./components/loading-component";
import Parameters from "./components/pages/parameters.page";
import UnauthorizedPage from "./components/pages/unauthorized.page";
import PdfListPage from "./components/pages/signature-pdflist";
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/signature-pdfs/:id" element={<PdfListPage />} />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<HomePage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="parametres" element={<Parameters />} />
          <Route path="roles" element={<RoleManagement />} />
          <Route path="nouveau-projet" element={<NewProject />} />
          <Route path="nouveau-projet/:id" element={<NewProject />} />{" "}
          <Route
            path="nouveau-projet-confirmation/:id"
            element={<NewProjectConfirmation />}
          />
          {/* Dynamic routes for models */}
          {Object.keys(models).map((moduleName) => {
            const moduleConfig = models[moduleName];
            return (
              <React.Fragment key={moduleConfig.plural}>
                <Route
                  path={`list/${moduleConfig.plural}`}
                  element={<DataTable module={moduleName} />}
                />
                <Route
                  path={`new/${moduleConfig.single}`}
                  element={<TabbedForm moduleName={moduleName} />}
                />
                <Route
                  path={`edit/${moduleConfig.single}/:id`}
                  element={<TabbedForm moduleName={moduleName} />}
                />
              </React.Fragment>
            );
          })}
        </Route>

        {/* Catch-all route for non-existent pages */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
