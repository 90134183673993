import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { auth } from "../../firebase";
import loginImage from "../../assets/pexels-canvastudio-3194524.jpg";
import { AuthContext } from "../../contexts/AuthContext";
import { useError } from "../../contexts/ErrorContext";
import tokenManager from "../../services/tokenManager";
import api from "../../services/api";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaSpinner,
  FaPhone,
  FaLock,
  FaExclamationCircle,
} from "react-icons/fa";
import "react-phone-number-input/style.css";

const AuthPage = () => {
  const { user, setUser, getIdToken } = useContext(AuthContext);
  const { setError: setGlobalError } = useError();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [step, setStep] = useState("phone");
  const confirmationResultRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const initializeRecaptcha = useCallback(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {},
          "expired-callback": () => {
            if (window.recaptchaVerifier) {
              window.recaptchaVerifier.reset();
            }
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      script.onload = initializeRecaptcha;
      script.onerror = () => {
        console.error("Failed to load ReCAPTCHA script");
        setError("Failed to load ReCAPTCHA. Please refresh the page.");
      };
      document.head.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, [initializeRecaptcha]);

  const handleSendCode = useCallback(async () => {
    setVerifying(true);
    setError("");

    const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;

    if (!window.recaptchaVerifier) {
      setError("ReCAPTCHA not initialized. Please refresh the page.");
      setVerifying(false);
      return;
    }

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        window.recaptchaVerifier
      );
      confirmationResultRef.current = confirmationResult;
      setStep("code");
    } catch (error) {
      console.error("Error sending verification code:", error, formattedPhone);
      setError(getErrorMessage(error));
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.reset();
      }
    } finally {
      setVerifying(false);
    }
  }, [phone]);

  const handleVerifyCode = async () => {
    try {
      const result = await confirmationResultRef.current.confirm(code);
      const firebaseUser = result.user;
      const idToken = await firebaseUser.getIdToken();

      // Set the token immediately
      tokenManager.setToken(idToken);

      // Log the token after setting it
      console.log("Token after setting:", tokenManager.getToken());

      try {
        // Check if the user exists in our system
        const existingUser = await api.getUserByPhone(phone);

        // User exists, link Firebase UID if not already linked
        if (existingUser.firebaseUid !== firebaseUser.uid) {
          await api.linkFirebaseUser(existingUser.id, firebaseUser.uid);
        }

        console.log("User linked successfully:", firebaseUser);

        setUser(existingUser);

        // Refresh the token to ensure it's up-to-date
        const newToken = await getIdToken();
        tokenManager.setToken(newToken);

        navigate("/", { replace: true });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // User not found in our system
          setError(
            "You are not authorized to access this application. Please contact the administrator."
          );
          setGlobalError("Unauthorized access attempt detected.");
          await handleSignOut();
        } else if (error.response && error.response.status === 401) {
          setError("Authentication failed. Please try logging in again.");
          setGlobalError("Authentication error occurred.");
          await handleSignOut();
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error("Error during verification:", error);
      setError(getErrorMessage(error));
      setGlobalError("An error occurred during the authentication process.");
      await handleSignOut();
    }
  };

  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      tokenManager.clearToken();
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
      setGlobalError("An error occurred while signing out. Please try again.");
    }
  }, [setUser, setGlobalError]);

  const getErrorMessage = (error) => {
    switch (error.code) {
      case "auth/invalid-phone-number":
        return "The phone number is invalid. Please check and try again.";
      case "auth/too-many-requests":
        return "Too many requests. Please try again later.";
      case "auth/invalid-verification-code":
        return "Invalid verification code. Please try again.";
      case "auth/network-request-failed":
        return "Network error. Please check your internet connection and try again.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  if (user) {
    return null;
  }

  return (
    <div className="h-screen flex flex-col md:flex-row bg-gray-100">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-8 bg-white">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-md w-full"
        >
          <h1 className="text-3xl font-bold mb-2 text-[#1e0e4b] text-center">
            Welcome to <span className="text-[#7747ff]">Assurscribe Main</span>
          </h1>
          <p className="text-sm font-normal mb-8 text-center text-gray-600">
            Secure, fast, and reliable insurance management
          </p>
          <div id="recaptcha-container"></div>
          <AnimatePresence mode="wait">
            {step === "phone" ? (
              <PhoneForm
                key="phone"
                phone={phone}
                setPhone={setPhone}
                handleSendCode={handleSendCode}
                verifying={verifying}
              />
            ) : (
              <CodeForm
                key="code"
                code={code}
                setCode={setCode}
                handleVerifyCode={handleVerifyCode}
                verifying={verifying}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {error && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 text-sm text-center mt-4 flex items-center justify-center"
                role="alert"
              >
                <FaExclamationCircle className="mr-2" />
                {error}
              </motion.p>
            )}
          </AnimatePresence>
          <p className="text-sm text-center mt-8">
            Don't have an account yet?{" "}
            <a
              className="text-[#7747ff] hover:underline transition-all duration-200"
              href="/contact"
            >
              Contactez-nous!
            </a>
          </p>
        </motion.div>
      </div>
      <div className="hidden md:block w-1/2 relative overflow-hidden">
        <img
          src={loginImage}
          alt="Insurance professional at work"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-white text-center">
            <h2 className="text-4xl font-bold mb-4">
              Simplify Your Insurance Workflow
            </h2>
            <p className="text-xl">
              Join Assurscribe Main for streamlined operations
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const PhoneForm = React.memo(
  ({ phone, setPhone, handleSendCode, verifying }) => (
    <motion.form
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col gap-6"
    >
      <div className="relative">
        <label
          htmlFor="phone"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Phone Number
        </label>
        <div className="relative">
          <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <PhoneInput
            international
            defaultCountry="FR"
            value={phone}
            onChange={setPhone}
            placeholder="Enter phone number"
            disabled={verifying}
            className="w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-[#7747ff] focus:border-transparent"
          />
        </div>
      </div>
      <button
        type="button"
        className="bg-[#7747ff] w-full py-3 rounded-md text-white text-sm font-medium transition-all duration-200 hover:bg-[#5f35cc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7747ff]"
        onClick={handleSendCode}
        disabled={verifying}
        aria-disabled={verifying}
        aria-label={
          verifying ? "Sending verification code, please wait" : "Send Code"
        }
      >
        {verifying ? (
          <FaSpinner className="animate-spin inline-block mr-2" />
        ) : null}
        {verifying ? "Sending..." : "Send Code"}
      </button>
    </motion.form>
  )
);

const CodeForm = React.memo(
  ({ code, setCode, handleVerifyCode, verifying }) => (
    <motion.form
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col gap-6"
    >
      <div className="relative">
        <label
          htmlFor="code"
          className="block text-gray-700 text-sm font-medium mb-2"
        >
          Verification Code
        </label>
        <div className="relative">
          <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            id="code"
            className="w-full py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-[#7747ff] focus:border-transparent"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter verification code"
            disabled={verifying}
            aria-label="Verification Code"
          />
        </div>
      </div>
      <button
        type="button"
        className="bg-[#7747ff] w-full py-3 rounded-md text-white text-sm font-medium transition-all duration-200 hover:bg-[#5f35cc] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7747ff]"
        onClick={handleVerifyCode}
        disabled={verifying}
        aria-disabled={verifying}
        aria-label={verifying ? "Verifying code, please wait" : "Verify Code"}
      >
        {verifying ? (
          <FaSpinner className="animate-spin inline-block mr-2" />
        ) : null}
        {verifying ? "Verifying..." : "Verify Code"}
      </button>
    </motion.form>
  )
);

export default AuthPage;
