// hooks/useColumnVisibility.js
import { useState, useEffect, useCallback } from "react";

export const useColumnVisibility = (initialColumns) => {
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    const initialVisibility = {};
    initialColumns.forEach((column) => {
      const key = column.id || column.accessor;
      if (key) {
        initialVisibility[key] = true;
      }
    });
    setColumnVisibility(initialVisibility);
  }, [initialColumns]);

  const toggleColumnVisibility = useCallback((columnKey) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  }, []);

  return [columnVisibility, toggleColumnVisibility];
};
