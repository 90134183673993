import React, { useState, useEffect } from "react";
import { AutoComplete } from "antd";
import axios from "axios";
import { useError } from "../../../../contexts/ErrorContext";

const CityInput = ({ value, onChange, onExtraDataChange, placeholder }) => {
  const { setError, clearError } = useError();
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (value) {
      setSearchText(value);
    }
  }, [value]);

  const searchCity = async (text) => {
    if (text.length < 2) return;

    try {
      let url = "https://geo.api.gouv.fr/communes?";
      if (/^\d+$/.test(text)) {
        url += `codePostal=${text}`;
      } else {
        url += `nom=${text}&boost=population`;
      }

      const response = await axios.get(url);
      const cities = response.data.map((city) => ({
        value: city.nom,
        label: `${city.nom} (${
          city.codeDepartement
        }) - ${city.codesPostaux.join(", ")}`,
        extraData: {
          code: city.code,
          codeDepartement: city.codeDepartement,
          codeRegion: city.codeRegion,
        },
      }));
      setOptions(cities);
      clearError();
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError({
        statusCode: error.response?.status || 500,
        message:
          error.response?.data?.message || "An unexpected error occurred",
        errors: error.response?.data?.errors,
      });
      setOptions([]);
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    searchCity(text);
  };

  const handleSelect = (value, option) => {
    onChange(value);

    if (onExtraDataChange) {
      onExtraDataChange(option.extraData);
    }
    setSearchText(value);
  };

  return (
    <AutoComplete
      value={searchText}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onChange={(text) => setSearchText(text)}
      placeholder={placeholder || "Enter city name or postal code"}
      style={{ width: "100%" }}
    />
  );
};

export default CityInput;
