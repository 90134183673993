import React from "react";
import { Drawer, Form, Input, Select, DatePicker, Button, Space } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterDrawer = ({ visible, onClose, filters, setFilters, columns }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const newFilters = Object.fromEntries(
      Object.entries(values).filter(
        ([_, value]) => value !== undefined && value !== ""
      )
    );

    setFilters(newFilters);
    onClose();
  };

  const renderFilterInput = (column) => {
    switch (column.type) {
      case "number":
        return (
          <Input type="number" placeholder={`Filter by ${column.Header}`} />
        );
      case "select":
        return (
          <Select placeholder={`Filter by ${column.Header}`} allowClear>
            {column.options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        );
      case "date":
        return <RangePicker />;
      case "boolean":
        return (
          <Select placeholder={`Filter by ${column.Header}`} allowClear>
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        );
      case "text":
      default:
        return <Input placeholder={`Filter by ${column.Header}`} />;
    }
  };

  return (
    <Drawer
      title="Filter Data"
      placement="right"
      onClose={onClose}
      open={visible}
      width={320}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={filters}
      >
        {columns
          .filter((column) => column.filtrable)
          .map((column) => (
            <Form.Item key={column.id} name={column.id} label={column.Header}>
              {renderFilterInput(column)}
            </Form.Item>
          ))}
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Apply Filters
            </Button>
            <Button
              onClick={() => {
                form.resetFields();
                setFilters({});
              }}
            >
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FilterDrawer;
