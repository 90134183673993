import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Card, List, Typography, Spin, Alert, Button, Space } from 'antd';
import { FilePdfOutlined, DownloadOutlined, EyeOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import api from '../../services/api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const { Content, Sider } = Layout;
const { Title, Text } = Typography;

const PdfListPage = () => {
  const { id: venteId } = useParams();
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const fetchPdfLinks = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const links = await api.getPdfLinks(venteId);
      const files = Object.entries(links).map(([name, url]) => ({ name, url }));
      setPdfFiles(files);
      if (files.length > 0) setSelectedFile(files[0]);
    } catch (err) {
      console.error("Error fetching PDF links:", err);
      setError("Failed to load PDF list. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [venteId]);

  useEffect(() => {
    fetchPdfLinks();
  }, [fetchPdfLinks]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    setNumPages(null);
  };

  const handleDownload = () => {
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile.url;
      link.download = selectedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto mt-8">
        <Alert message="Error" description={error} type="error" showIcon />
      </div>
    );
  }

  return (
    <Layout className="min-h-screen bg-gray-50">
      <Content className="p-6">
        <div className="max-w-7xl mx-auto">
          <Card className="shadow-sm rounded-lg overflow-hidden">
            <div className="px-6 py-4 bg-gray-100 border-b border-gray-200">
              <Title level={3} className="m-0 text-gray-800">Documents - SG_G11_{venteId}</Title>
              <Text className="text-gray-600">PROTECTION JURIDIQUE</Text>
            </div>
            
            <Layout className="bg-white">
              <Sider
                width={300}
                collapsible
                collapsed={collapsed}
                onCollapse={setCollapsed}
                trigger={null}
                className="bg-gray-50 border-r border-gray-200"
              >
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  className="w-full text-left my-2"
                />
                <List
                  dataSource={pdfFiles}
                  renderItem={(file) => (
                    <List.Item
                      onClick={() => handleFileSelect(file)}
                      className={`cursor-pointer transition-all duration-200 ${
                        selectedFile === file ? "bg-gray-200" : "hover:bg-gray-100"
                      } border-b border-gray-200`}
                    >
                      <div className="flex items-center w-full p-3">
                        <FilePdfOutlined className={`text-xl mr-3 ${selectedFile === file ? "text-gray-700" : "text-gray-400"}`} />
                        <Text strong className={selectedFile === file ? "text-gray-800" : "text-gray-600"}>
                          {file.name}
                        </Text>
                      </div>
                    </List.Item>
                  )}
                />
              </Sider>
              
              <Content className="p-6">
                {selectedFile ? (
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-center mb-4">
                      <Title level={4} className="m-0 text-gray-800">{selectedFile.name}</Title>
                      <Space>
                        <Button
                          icon={<EyeOutlined />}
                          onClick={() => window.open(selectedFile.url, '_blank')}
                          className="border-gray-300 text-gray-600 hover:text-gray-800 hover:border-gray-400"
                        >
                          Open in New Tab
                        </Button>
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={handleDownload}
                          className="border-gray-300 text-gray-600 hover:text-gray-800 hover:border-gray-400"
                        >
                          Download
                        </Button>
                      </Space>
                    </div>
                    <div className="flex-grow bg-gray-100 rounded-lg p-4 overflow-auto">
                      <Document
                        file={selectedFile.url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        className="flex flex-col items-center"
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={Math.min(600, window.innerWidth - 60)}
                            className="mb-4 shadow-md"
                          />
                        ))}
                      </Document>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <Text italic className="text-gray-500">Select a document to preview</Text>
                  </div>
                )}
              </Content>
            </Layout>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default PdfListPage;