const table = {
  actions: [
    {
      label: "Import",
      type: "upload",
      endpoint: "/api/teams/import",
      accept: ".csv",
    },
    {
      label: "Exporter",
      type: "download",
      endpoint: "/api/teams/export",
      filename: "teams.csv",
    },
    {
      label: "Ajouter",
      type: "add",
      endpoint: "/api/teams",
    },
  ],
};

export default table;
