import { FaUsers } from "react-icons/fa6";

import FormConfig from "./provider-group.form";
import TableConfig from "./provider-group.table";

export const providerGroupConfig = {
  module: "providerGroup",
  single: "providerGroup",
  plural: "providerGroups",
  label: "Groupes de fournisseurs",
  path: "/provider-group",
  api: "provider-groups",
  icon: FaUsers,
  displayColumns: ["id", "code", "nom"],
  excludeColumns: ["createdAt", "updatedAt", "providers"],
  table: TableConfig,
  form: FormConfig,
};

export default providerGroupConfig;
