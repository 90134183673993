import { FaCircleQuestion } from "react-icons/fa6";

import FormConfig from "./question.form";
import TableConfig from "./question.table";

export const questionAdditionalConfig = {
  module: "questions",
  single: "question additionnelle",
  plural: "questions additionnelles",
  label: "Questions additionnelles",
  path: "/question-additional",
  api: "questions/additional",
  icon: FaCircleQuestion,
  displayColumns: ["id", "text"],
  excludeColumns: ["createdAt", "updatedAt", "userResponses"],
  table: TableConfig,
  form: FormConfig,
  addon: "question",
};

export default questionAdditionalConfig;
