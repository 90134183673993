import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Form,
  Input,
  Select,
  Steps,
  Button,
  message,
  Spin,
  Card,
  Modal,
  Tooltip,
  Space,
  Divider,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  EnvironmentOutlined,
  BankOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useError } from "../../contexts/ErrorContext";
import dayjs from "dayjs";
import Layout from "../layout";
import {
  EmailSender,
  generateDummyData,
  useFormData,
  useQuestions,
  useProductSolutions,
  SummaryView,
} from "./new-project-hooks-and-helpers";
import {
  updateItem,
  sendEmailWithFormData,
  getItemById,
} from "../../services/api";
import { savePartialCustomerData } from "./new-project-hooks-and-helpers/savePartialCustomerData";
import AddressAutocomplete from "./form-hooks-and-helpers/renderField/address";
import CityAutocomplete from "./form-hooks-and-helpers/renderField/cities";

const { Step } = Steps;
const { Option } = Select;

const MultiStepForm = () => {
  const [form] = Form.useForm();
  const { setError, clearError } = useError();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState("Votre demande de tarif");
  const [emailText, setEmailText] = useState(
    "Please find attached your insurance offer details."
  );
  const [customerId, setCustomerId] = useState(null);

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isProductConfirmed, setIsProductConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [venteId, setVenteId] = useState(id || null);
  const [initialProductIdentifier, setInitialProductIdentifier] =
    useState(null);

  const { formData, setFormData, isExistingEntry, fetchItemData } =
    useFormData(id);
  const { questions, categories, loading: questionsLoading } = useQuestions();
  const { productSolutions, fetchProductData } = useProductSolutions(formData);

  useEffect(() => {
    if (id) {
      fetchItemData(id).then((data) => {
        console.log("Fetched item data:", data);
        setVenteId(id);
        setFormData(data);
        if (data?.selectedProduct) {
          setInitialProductIdentifier(
            getProductIdentifier(data?.selectedProduct)
          );
          setIsProductConfirmed(true);
        }
        if (data?.customerId) {
          setCustomerId(data.customerId);
        }
      });
    }
  }, [id, fetchItemData, setFormData]);

  useEffect(() => {
    const fetchCustomerData = async () => {
      if (customerId) {
        try {
          const customerData = await getItemById("customers", customerId);
          form.setFieldsValue(customerData);
        } catch (error) {
          console.error("Error fetching customer data:", error);
          message.error("Failed to load customer data");
        }
      }
    };

    fetchCustomerData();
  }, [customerId, form]);

  useEffect(() => {
    if (formData?.createdAt) {
      const timer = setInterval(() => {
        const now = dayjs();
        const created = dayjs(formData.createdAt);
        const validUntil = created.add(24, "hour");
        const remaining = validUntil.diff(now, "second");

        if (remaining <= 0) {
          clearInterval(timer);
          setTimeRemaining(null);
        } else {
          setTimeRemaining(remaining);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [formData?.createdAt]);

  const getProductIdentifier = useCallback((selectedProduct) => {
    if (
      !selectedProduct ||
      !selectedProduct.product ||
      !selectedProduct.option ||
      !selectedProduct.offer
    ) {
      return null;
    }
    return `${selectedProduct.product.code}${selectedProduct.option.code}${selectedProduct.offer.code}`;
  }, []);

  const handleProductSelection = useCallback(
    (selectedProduct) => {
      setFormData((prev) => ({ ...prev, selectedProduct }));
      setIsProductConfirmed(true);
    },
    [setFormData]
  );

  const fillWithDummyData = useCallback(() => {
    setFormData(generateDummyData());
    message.success("Form filled with dummy data");
  }, [setFormData]);

  const handleSendEmail = useCallback(
    async (email) => {
      try {
        await sendEmailWithFormData(email, emailSubject, emailText, formData);
        message.success("Email sent successfully");
        const updatedItem = await updateItem("ventes", venteId, {
          ...formData,
          emailsSent: true,
        });
        setFormData((prev) => ({
          ...prev,
          ...updatedItem,
        }));
        console.log("Updated item:", updatedItem);
        setShowEmailModal(false);
      } catch (error) {
        console.error("Error sending email:", error);
        message.error("Failed to send email. Please try again.");
      }
    },
    [formData, emailSubject, emailText]
  );

  const handleValidation = useCallback(async () => {
    try {
      const updatedItem = await updateItem("ventes", venteId, {
        ...formData,
        validation: "validated",
      });

      setFormData((prev) => ({
        ...prev,
        ...updatedItem,
      }));
      console.log("Updated item:", updatedItem);
      message.success("Offer validated successfully!");
    } catch (error) {
      console.error("Error validating offer:", error);
      message.error("Failed to validate offer. Please try again.");
    }
  }, [venteId, formData]);

  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const stepGroups = useMemo(
    () => [
      { key: "summary", title: "Summary", icon: <FileTextOutlined /> },
      { key: "personal", title: "Personal Info", icon: <UserOutlined /> },
      { key: "address", title: "Address", icon: <EnvironmentOutlined /> },
      { key: "payment", title: "Payment & Bank", icon: <BankOutlined /> },
    ],
    []
  );

  const handleNext = useCallback(async () => {
    try {
      await form.validateFields();
      if (currentStep < stepGroups.length - 1) {
        setCurrentStep((prev) => prev + 1);
      } else {
        // Handle form submission
        const values = form.getFieldsValue(true);
        await savePartialCustomerData(
          values,
          customerId,
          setCustomerId,
          venteId,
          setFormData
        );
        message.success("Form submitted successfully");
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  }, [currentStep, stepGroups.length, form, customerId, venteId, setFormData]);

  const handlePrev = useCallback(() => {
    setCurrentStep((prev) => prev - 1);
  }, []);

  const handleAddressChange = (address, extraData) => {
    form.setFieldsValue({
      address: address,
      postalCode: extraData?.postcode,
      city: extraData?.city,
      country: "france",
    });
  };

  const handleBankAddressChange = (address, extraData) => {
    form.setFieldsValue({
      bankAddress: address,
      bankCity: extraData?.postcode + " " + extraData?.city,
      bankCountry: "france",
    });
  };

  const handleCityChange = (city, extraData) => {
    form.setFieldsValue({
      city: city,
      postalCode: extraData.codesPostaux[0],
    });
  };

  const renderContent = useCallback(() => {
    switch (currentStep) {
      case 0:
        return (
          <SummaryView
            formData={formData}
            setFormData={setFormData}
            handlePrev={handlePrev}
            onValidate={handleValidation}
          />
        );
      case 1:
        return (
          <Space direction="vertical" size="large" className="w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please select a title" }]}
              >
                <Select placeholder="Select title">
                  <Option value="mr">Mr</Option>
                  <Option value="mrs">Mrs</Option>
                  <Option value="ms">Ms</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="text-gray-400" />}
                  placeholder="Enter your first name"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="text-gray-400" />}
                  placeholder="Enter your last name"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="text-gray-400" />}
                  placeholder="Enter your email"
                />
              </Form.Item>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter your mobile number",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined className="text-gray-400" />}
                  placeholder="Enter your mobile number"
                />
              </Form.Item>
            </div>
          </Space>
        );
      case 2:
        return (
          <Space direction="vertical" size="large" className="w-full">
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please enter your address" }]}
            >
              <AddressAutocomplete
                onChange={(value, option) =>
                  handleAddressChange(value, option?.extraData)
                }
                placeholder="Enter an address"
                className="w-full"
              />
            </Form.Item>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Form.Item
                name="postalCode"
                label="Postal Code"
                rules={[
                  { required: true, message: "Please enter your postal code" },
                ]}
              >
                <Input
                  prefix={<HomeOutlined className="text-gray-400" />}
                  placeholder="Enter postal code"
                />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: "Please enter your city" }]}
              >
                <CityAutocomplete
                  onChange={(value, option) =>
                    handleCityChange(value, option?.extraData)
                  }
                  placeholder="Enter a city"
                  className="w-full"
                />
              </Form.Item>
            </div>
            <Form.Item
              name="country"
              label="Country"
              rules={[{ required: true, message: "Please select a country" }]}
            >
              <Select placeholder="Select country" prefix={<GlobalOutlined />}>
                <Option value="france">France</Option>
                <Option value="belgium">Belgium</Option>
                <Option value="switzerland">Switzerland</Option>
              </Select>
            </Form.Item>
          </Space>
        );
      case 3:
        return (
          <Space direction="vertical" size="large" className="w-full">
            <Form.Item
              name="paymentFrequency"
              label="Payment Frequency"
              rules={[
                {
                  required: true,
                  message: "Please select a payment frequency",
                },
              ]}
            >
              <Select placeholder="Select payment frequency">
                <Option value="monthly">Monthly</Option>
                <Option value="quarterly">Quarterly</Option>
                <Option value="annually">Annually</Option>
              </Select>
            </Form.Item>

            <Divider orientation="left">Bank Details</Divider>

            <Form.Item
              name="bankAddress"
              label="Bank Address"
              rules={[
                { required: true, message: "Please enter the bank address" },
              ]}
            >
              <AddressAutocomplete
                onChange={(value, option) =>
                  handleBankAddressChange(value, option?.extraData)
                }
                placeholder="Enter bank address"
                className="w-full"
              />
            </Form.Item>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Form.Item
                name="bankCity"
                label="Bank City"
                rules={[
                  { required: true, message: "Please enter the bank city" },
                ]}
              >
                <Input
                  prefix={<BankOutlined className="text-gray-400" />}
                  placeholder="Enter bank city"
                />
              </Form.Item>
              <Form.Item
                name="bankCountry"
                label="Bank Country"
                rules={[
                  { required: true, message: "Please select the bank country" },
                ]}
              >
                <Select
                  placeholder="Select bank country"
                  prefix={<GlobalOutlined />}
                >
                  <Option value="france">France</Option>
                  <Option value="belgium">Belgium</Option>
                  <Option value="switzerland">Switzerland</Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              name="iban"
              label="IBAN"
              rules={[{ required: true, message: "Please enter your IBAN" }]}
            >
              <Input
                prefix={<CreditCardOutlined className="text-gray-400" />}
                placeholder="Enter your IBAN"
              />
            </Form.Item>

            <Form.Item
              name="bic"
              label="BIC"
              rules={[{ required: true, message: "Please enter your BIC" }]}
            >
              <Input
                prefix={<BankOutlined className="text-gray-400" />}
                placeholder="Enter your BIC"
              />
            </Form.Item>

            <Form.Item
              name="prestationBancaire"
              label={
                <span>
                  Prestation Bancaire
                  <Tooltip title="Indicates whether you require additional banking services">
                    <QuestionCircleOutlined className="ml-2 text-gray-400" />
                  </Tooltip>
                </span>
              }
              rules={[{ required: true, message: "Please select an option" }]}
            >
              <Select placeholder="Select an option">
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
          </Space>
        );
      default:
        return null;
    }
  }, [
    currentStep,
    formData,
    setFormData,
    questions,
    handlePrev,
    handleValidation,
    form,
  ]);

  const renderQuestionInput = useCallback((question) => {
    switch (question.type) {
      case "text":
        return <Input placeholder="Enter your answer" />;
      case "number":
        return <Input type="number" placeholder="Enter a number" />;
      case "select":
        return (
          <Select placeholder="Select an option">
            {question.options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        );
      default:
        return <Input placeholder="Enter your answer" />;
    }
  }, []);

  const actionButtons = useMemo(
    () => [
      <Button
        key="previous"
        onClick={handlePrev}
        icon={<LeftOutlined />}
        disabled={currentStep === 0}
        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Previous
      </Button>,
      <Button
        key="next"
        onClick={handleNext}
        type="primary"
        icon={isSubmitting ? <LoadingOutlined /> : <RightOutlined />}
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
      >
        {currentStep === stepGroups.length - 1
          ? isSubmitting
            ? "Submitting..."
            : "Submit"
          : "Next"}
      </Button>,
      !isExistingEntry && (
        <Button
          key="fillDummy"
          onClick={fillWithDummyData}
          type="primary"
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Fill with Dummy Data
        </Button>
      ),
    ],
    [
      currentStep,
      stepGroups.length,
      handlePrev,
      handleNext,
      isExistingEntry,
      fillWithDummyData,
      isSubmitting,
    ]
  );

  if (questionsLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-screen">
          <Spin size="large" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout pageTitle="Nouveau projet / Vente" actionComponents={actionButtons}>
      <div className="container p-4 sm:p-8">
        <Card className="shadow-lg rounded-lg overflow-hidden">
          <Steps current={currentStep} className="mb-8">
            {stepGroups.map(({ key, title, icon }) => (
              <Step
                key={key}
                title={<span className="hidden md:inline">{title}</span>}
                icon={<Tooltip title={title}>{icon}</Tooltip>}
              />
            ))}
          </Steps>
          <Form
            form={form}
            layout="vertical"
            initialValues={formData}
            onFinish={handleNext}
            className="w-full"
          >
            <div className="bg-gray-50 p-6 rounded-lg mb-8">
              {renderContent()}
            </div>
            <div className="flex justify-between items-center">
              <Button
                onClick={handlePrev}
                disabled={currentStep === 0}
                icon={<LeftOutlined />}
                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Previous
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                icon={isSubmitting ? <LoadingOutlined /> : <SaveOutlined />}
                loading={isSubmitting}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
              >
                {currentStep === stepGroups.length - 1 ? "Submit" : "Next"}
              </Button>
            </div>
          </Form>
        </Card>
      </div>
      <Modal
        title="Send Email"
        visible={showEmailModal}
        onCancel={() => setShowEmailModal(false)}
        footer={null}
      >
        <EmailSender
          onSendEmail={handleSendEmail}
          initialSubject={emailSubject}
          initialText={emailText}
          onSubjectChange={setEmailSubject}
          onTextChange={setEmailText}
          pdfLinks={formData?.pdfLinks}
          formData={formData}
        />
      </Modal>
    </Layout>
  );
};

export default MultiStepForm;
