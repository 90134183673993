import { useState, useEffect } from "react";
import { Form, message } from "antd";
import { fetchParameters, updateParameters } from "../../../services/api";

const useParametersForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadParameters();
  }, []);

  const loadParameters = async () => {
    try {
      const data = await fetchParameters();
      form.setFieldsValue(data);
    } catch (error) {
      message.error("Failed to fetch parameters");
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await updateParameters(values);
      message.success("Parameters updated successfully");
    } catch (error) {
      message.error("Failed to update parameters");
    } finally {
      setLoading(false);
    }
  };

  return { form, loading, onFinish };
};

export default useParametersForm;
