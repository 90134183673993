import React from "react";
import PropTypes from "prop-types";
import { Card, Input, Checkbox, Tooltip, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const QuestionRenderer = ({ question, formData, handleInputChange }) => {
  const renderQuestionInput = () => {
    switch (question.type) {
      case "free":
        return (
          <Input
            value={(formData && formData[question.id]) || ""}
            onChange={(e) => handleInputChange(question, e.target.value)}
            placeholder="Enter your answer"
            className="w-full transition-all duration-300 border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
            aria-label={question.text}
          />
        );
      case "radio":
        const selectedRadioValue =
          formData && formData[question.id]
            ? Object.keys(formData[question.id]).find(
                (key) => formData[question.id][key]
              )
            : undefined;
        return (
          <div className="flex flex-wrap gap-3">
            {question.answerOptions.map((option) => (
              <Button
                key={option.id}
                type={
                  selectedRadioValue === option.id.toString()
                    ? "primary"
                    : "default"
                }
                onClick={() =>
                  handleInputChange(question, { [option.id]: true })
                }
                className="flex-grow sm:flex-grow-0 transition-all duration-300"
              >
                {option.text}
              </Button>
            ))}
          </div>
        );
      case "checkbox":
        const selectedCheckboxValues =
          formData && formData[question.id]
            ? Object.keys(formData[question.id]).filter(
                (key) => formData[question.id][key]
              )
            : [];
        return (
          <Checkbox.Group
            value={selectedCheckboxValues}
            onChange={(checkedValues) => {
              const newValue = {};
              question.answerOptions.forEach((option) => {
                newValue[option.id] = checkedValues.includes(
                  option.id.toString()
                );
              });
              handleInputChange(question, newValue);
            }}
            className="w-full"
          >
            {question.answerOptions.map((option) => (
              <Checkbox
                key={option.id}
                value={option.id.toString()}
                className="block mb-3 transition-all duration-300 hover:bg-gray-50 p-2 rounded-md"
              >
                {option.text}
              </Checkbox>
            ))}
          </Checkbox.Group>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      key={question?.id}
      className="mb-6 shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg"
      bodyStyle={{ padding: "1.5rem" }}
    >
      {question.header && (
        <div className="bg-blue-50 p-4 -mx-6 -mt-6 mb-6 border-b border-blue-100">
          <h3 className="text-lg font-semibold text-blue-800">
            {question.header}
          </h3>
        </div>
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="flex-grow pr-0 lg:pr-6 mb-6 lg:mb-0">
          <label className="block text-base font-medium text-gray-800 mb-3">
            {question.text}
            {question.help && (
              <Tooltip title="Click for help" placement="right">
                <InfoCircleOutlined className="ml-2 text-blue-500 cursor-pointer" />
              </Tooltip>
            )}
          </label>
          {renderQuestionInput()}
        </div>
        {question.help && (
          <div className="w-full lg:w-1/3 pl-0 lg:pl-6 border-t lg:border-t-0 lg:border-l border-gray-200 pt-4 lg:pt-0">
            <div className="bg-gray-50 p-4 rounded-md">
              <div className="flex items-center text-blue-700 mb-2">
                <InfoCircleOutlined className="mr-2" />
                <span className="font-medium">Help Information</span>
              </div>
              <p className="text-sm text-gray-600">{question.help}</p>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

QuestionRenderer.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    header: PropTypes.string,
    help: PropTypes.string,
    answerOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  formData: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default React.memo(QuestionRenderer);
