import { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import {
  getItems,
  deleteItem,
  createItem,
  importItems,
  exportItems,
} from "../../../services/api";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useError } from "../../../contexts/ErrorContext";

export const useTableData = (moduleConfig) => {
  const { setError, clearError } = useError();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({});

  const navigate = useNavigate();

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy, newFilters }) => {
      if (!moduleConfig) return;
      setLoading(true);

      try {
        const sortParam =
          sortBy && sortBy.length > 0
            ? `${sortBy[0].id}:${sortBy[0].desc ? "DESC" : "ASC"}`
            : undefined;

        // Process filters
        const processedFilters =
          newFilters && typeof newFilters === "object"
            ? Object.entries(newFilters).reduce((acc, [key, value]) => {
                if (Array.isArray(value) && value.length === 2) {
                  // It's a date range
                  acc[`${key}_gte`] = moment(value[0])
                    .startOf("day")
                    .toISOString();
                  acc[`${key}_lte`] = moment(value[1])
                    .endOf("day")
                    .toISOString();
                } else if (value === "true" || value === "false") {
                  // It's a boolean
                  acc[key] = value === "true";
                } else {
                  // It's a regular filter
                  acc[key] = value;
                }
                return acc;
              }, {})
            : { ...(moduleConfig.table.filters || {}) };

        const response = await getItems(moduleConfig.api, {
          pageIndex,
          pageSize,
          sortBy: sortParam,
          filters: processedFilters,
        });

        setData(response.data || []);
        setPageCount(Math.ceil(response.totalCount / pageSize));
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    },
    [moduleConfig]
  );

  const handleDelete = useCallback(
    async (id) => {
      if (!moduleConfig) return;
      try {
        await deleteItem(moduleConfig.api, id);
        await fetchData(pagination);
        clearError();
        message.success("Item deleted successfully");
      } catch (error) {
        setError({
          statusCode: error.response?.status || 500,
          message:
            error.response?.data?.message || "An unexpected error occurred",
          errors: error.response?.data?.errors,
        });
        console.error("Error deleting item:", error);
        message.error("Failed to delete item");
      }
    },
    [moduleConfig, fetchData, pagination]
  );

  const handleBulkDelete = async (selectedRows) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${selectedRows.length} items?`
      )
    ) {
      try {
        await Promise.all(
          selectedRows.map((row) => deleteItem(moduleConfig.api, row.id))
        );
        fetchData(pagination);
        message.success(`${selectedRows.length} items deleted successfully`);
        return true;
      } catch (error) {
        console.error("Error deleting items:", error);
        message.error("Failed to delete items");
        return false;
      }
    }
    return false;
  };

  const handleExport = useCallback(() => {
    if (!moduleConfig) return;
    try {
      exportCurrentItems(data, moduleConfig.plural);
      message.success("Export successful");
    } catch (error) {
      console.error("Error exporting items:", error);
      message.error("Failed to export items");
    }
  }, [data, moduleConfig]);

  const handleImport = useCallback(
    async (file) => {
      if (!moduleConfig) return;
      setLoading(true);
      try {
        const text = await file.text();
        const objects = text
          .split("\n")
          .slice(1)
          .map((line) => {
            const values = line.split(",");
            return moduleConfig.columns.reduce((obj, column, index) => {
              obj[column.key] = values[index]?.trim() ?? "";
              return obj;
            }, {});
          });

        let importedCount = 0;
        for (const obj of objects) {
          await createItem(moduleConfig.api, obj);
          clearError();
          importedCount++;
        }

        fetchData(pagination);
        message.success(`Successfully imported ${importedCount} items`);
      } catch (error) {
        console.error("Error importing items:", error);
        setError({
          statusCode: error.response?.status || 500,
          message:
            error.response?.data?.message || "An unexpected error occurred",
          errors: error.response?.data?.errors,
        });
        message.error("Failed to import items");
      } finally {
        setLoading(false);
      }
    },
    [moduleConfig, fetchData, pagination]
  );

  const handleAdd = () => {
    navigate(`/new/${moduleConfig.single}`);
  };

  const handleEdit = (id) => {
    navigate(`/edit/${moduleConfig.single}/${id}`);
  };

  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters(newFilters);
      fetchData({ ...pagination, newFilters });
    },
    [fetchData, pagination]
  );

  useEffect(() => {
    if (moduleConfig) {
      fetchData({ ...pagination, filters });
    }
  }, [fetchData, moduleConfig, pagination, filters]);

  const handlePageChange = useCallback(
    (newPagination) => {
      setPagination(newPagination);
      fetchData(newPagination);
    },
    [fetchData]
  );

  const objectsToCsv = (data) => {
    if (data.length === 0) return "";

    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join(","),
      ...data.map((row) =>
        headers
          .map((fieldName) => JSON.stringify(row[fieldName] ?? ""))
          .join(",")
      ),
    ];

    return csvRows.join("\r\n");
  };

  const exportCurrentItems = (data, module) => {
    try {
      // Convert current data to CSV
      const csv = objectsToCsv(data);

      // Create a Blob from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${module}_export.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      return { success: true, message: "Export completed successfully" };
    } catch (error) {
      console.error(`Error exporting ${module}:`, error);
      throw error;
    }
  };

  return {
    loading,
    data,
    pageCount,
    pagination,
    fetchData,
    handleDelete,
    handleBulkDelete,
    handlePageChange,
    handleImport,
    handleExport,
    handleAdd,
    handleEdit,
    handleFilterChange,
  };
};

export default useTableData;
