import React from "react";
import { Card, Row, Col, Statistic } from "antd";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  UserOutlined,
  FileOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import Layout from "../layout";

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

// Mock data for charts
const barChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Policies Sold",
      backgroundColor: "#1890ff",
      data: [50, 60, 70, 80, 60, 90],
    },
  ],
};

const doughnutChartData = {
  labels: ["FMA"],
  datasets: [
    {
      data: [300, 50, 100, 80],
      backgroundColor: ["#ff6384", "#36a2eb", "#ffce56", "#4caf50"],
    },
  ],
};

const lineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Claims Processed",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "#36a2eb",
      borderColor: "#36a2eb",
      data: [65, 59, 80, 81, 56, 55],
    },
  ],
};

const Dashboard = () => {
  return (
    <Layout>
      <div
        style={{ padding: 24, backgroundColor: "#f0f2f5", minHeight: "100vh" }}
      >
        <h1 style={{ marginBottom: 24 }}>Tableau de suivie des ventes</h1>

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Contrats vendus"
                value={1345}
                prefix={<FileOutlined />}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Nombre de contrats validès"
                value={764}
                prefix={<DollarCircleOutlined />}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Clients"
                value={1293}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginBottom: 24 }}>
          <Col span={16}>
            <Card title="Contrats vendu sur une pèriode ">
              <Bar
                data={barChartData}
                options={{ maintainAspectRatio: false }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Distribution par Type">
              <Doughnut
                data={doughnutChartData}
                options={{ maintainAspectRatio: false }}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Card title="Claims Processed Over Time">
              <Line
                data={lineChartData}
                options={{ maintainAspectRatio: false }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Dashboard;
