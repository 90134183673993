import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, message, Spin, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Layout from "../layout";
import { useRoleManagement } from "./role-hooks-and-helpers/useRoleManagement.hook";
import PermissionsForm from "./role-hooks-and-helpers/PermissionsForm";

const RoleManagement = () => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [permissions, setPermissions] = useState({});
  const {
    roles,
    modules,
    loading,
    modalVisible,
    currentRole,
    handleCreateRole,
    handleEditRole,
    handleDeleteRole,
    handleModalOk,
    setModalVisible,
    fetchRoles,
  } = useRoleManagement(form);

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        name: currentRole?.name || "",
      });
      setPermissions(currentRole?.permissions || {});
    } else {
      form.resetFields();
      setPermissions({});
    }
  }, [modalVisible, currentRole, form]);

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      const formData = {
        ...values,
        permissions: permissions,
      };
      await handleModalOk(formData);
      await fetchRoles();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handlePermissionsChange = (newPermissions) => {
    setPermissions(newPermissions);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleEditRole(record)}>Edit</Button>
          <Button onClick={() => handleDeleteRole(record.id)} danger>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <h1>Role Management</h1>
      <Space style={{ marginBottom: 16 }}>
        <Button onClick={handleCreateRole} type="primary">
          Create New Role
        </Button>
        <Input
          placeholder="Search modules, controllers, or actions"
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
      </Space>
      <Spin spinning={loading}>
        <Table dataSource={roles} columns={columns} rowKey="id" />
      </Spin>

      <Modal
        title={currentRole ? "Edit Role" : "Create New Role"}
        visible={modalVisible}
        onOk={handleFormSubmit}
        onCancel={() => setModalVisible(false)}
        width={800}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Role Name"
            rules={[{ required: true, message: "Please input the role name!" }]}
          >
            <Input />
          </Form.Item>
          <PermissionsForm
            modules={modules}
            form={form}
            initialPermissions={currentRole?.permissions}
            onChange={handlePermissionsChange}
          />
        </Form>
      </Modal>
    </Layout>
  );
};

export default RoleManagement;
