import { FaFileContract } from "react-icons/fa6";

import FormConfig from "./vente.form";
import TableConfig from "./vente.table";

export const VenteConfig = {
  module: "vente",
  single: "vente",
  plural: "ventes",
  label: "Ventes",
  path: "/vente",
  api: "ventes",
  icon: FaFileContract,
  excludeColumns: [
    "formData",
    "familyMembers",
    "familyComposition",
    "selectedProduct",
    "pdfLinks",
  ],
  table: TableConfig,
  form: FormConfig,
};

export default VenteConfig;
