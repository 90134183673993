import React from "react";
import { Form, Input, Checkbox, InputNumber, Card, Typography } from "antd";
import {
  FileAddOutlined,
  MailOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-indigo-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const ReceiptTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-indigo-800">
        Paramètres de Recette
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Configuration générale"
          icon={<SettingOutlined />}
          className="bg-indigo-50"
        >
          <FormItem name="isRecetteSite" valuePropName="checked">
            <Checkbox>Site de recette</Checkbox>
          </FormItem>
          <FormItem name="recetteMode" label="Mode de recette">
            <Input />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Redirection email"
          icon={<MailOutlined />}
          className="bg-blue-50"
        >
          <FormItem
            name="recetteRedirectionEmail"
            label="Adresse mail de redirection recette"
          >
            <Input type="email" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Notifications"
          icon={<UserOutlined />}
          className="bg-green-50"
        >
          <FormItem
            name="recetteNotificationRecipients"
            label="Destinataires des notifications de recette"
          >
            <Input />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Réinitialisation des données"
          icon={<FileAddOutlined />}
          className="bg-yellow-50"
        >
          <FormItem
            name="recetteDataResetFrequency"
            label="Fréquence de réinitialisation des données de recette"
          >
            <InputNumber min={1} addonAfter="jours" />
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default ReceiptTab;
