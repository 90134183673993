import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button, Spin, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const PdfPreview = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setPageNumber(1);
    setNumPages(null);
  }, [url]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  }, []);

  const onDocumentLoadError = useCallback((error) => {
    console.error("Error loading PDF:", error);
    message.error("Failed to load PDF. Please try again later.");
    setLoading(false);
  }, []);

  const file = useMemo(
    () => ({
      url: url,
      httpHeaders: {
        "Content-Type": "application/pdf",
      },
      withCredentials: false,
    }),
    [url]
  );

  const onPrevPage = useCallback(
    () => setPageNumber((prev) => Math.max(prev - 1, 1)),
    []
  );

  const onNextPage = useCallback(
    () => setPageNumber((prev) => Math.min(prev + 1, numPages || Infinity)),
    [numPages]
  );

  return (
    <div className="flex flex-col items-center">
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        loading={<Spin size="large" />}
        options={{
          cMapUrl: "https://unpkg.com/pdfjs-dist@2.9.359/cmaps/",
          cMapPacked: true,
        }}
      >
        {numPages > 0 && <Page pageNumber={pageNumber} />}
      </Document>
      {!loading && numPages > 0 && (
        <div className="mt-4 flex items-center">
          <Button
            icon={<LeftOutlined />}
            onClick={onPrevPage}
            disabled={pageNumber <= 1}
          />
          <span className="mx-4">
            Page {pageNumber} of {numPages}
          </span>
          <Button
            icon={<RightOutlined />}
            onClick={onNextPage}
            disabled={pageNumber >= numPages}
          />
        </div>
      )}
    </div>
  );
};

export default PdfPreview;
