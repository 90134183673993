import React from "react";
import { Link } from "react-router-dom";
import { FiHome, FiLogIn, FiAlertTriangle } from "react-icons/fi";

const UnauthorizedPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl">
        <div>
          <FiAlertTriangle
            className="mx-auto h-16 w-16 text-yellow-400"
            aria-hidden="true"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Accès Non Autorisé
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Désolé, vous n'avez pas les autorisations nécessaires pour accéder à
            cette page.
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <Link
                to="/"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <FiHome
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Retour à l'accueil
              </Link>
            </div>
          </div>

          <div>
            <Link
              to="/auth"
              className="group relative w-full flex justify-center py-2 px-4 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <FiLogIn
                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
              Se connecter
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-500">
          Si vous pensez qu'il s'agit d'une erreur, veuillez contacter le
          support technique.
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
