const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: [
        "code",
        "code_externe",
        "groupe_num",
        "nom",
        "prenom",
        "type_general",
        "type",
      ],
    },
    {
      label: "ORIAS",
      fields: ["orias", "orias_valide", "date_debut_orias", "date_fin_orias"],
    },
    {
      label: "Contact",
      fields: ["telephone", "mail", "mail_signatures"],
    },
    {
      label: "Adresse",
      fields: ["adresse", "complement", "code_postal", "ville", "pays"],
    },
    {
      label: "Informations bancaires",
      fields: ["iban", "bic"],
    },
    {
      label: "Associations",
      fields: ["equipe", "objectif_projet", "company", "groupes"],
    },
    {
      label: "Commissions",
      fields: [
        "code_commission_1",
        "code_commission_2",
        "code_commission_3",
        "code_commission_4",
        "code_commission_5",
      ],
    },
    {
      label: "Autres",
      fields: ["observation", "bordereaux_bloques", "reglements_bloques"],
    },
  ],
  fields: [
    {
      name: "code",
      label: "Code",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_externe",
      label: "Code externe",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "groupe_num",
      label: "Numéro de groupe",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "nom",
      label: "Nom",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "prenom",
      label: "Prénom",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "type_general",
      label: "Type général",
      type: "select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      options: [
        { value: 0, label: "Aucun" },
        { value: 1, label: "Courtier" },
        { value: 2, label: "Mandataire" },
        { value: 3, label: "Salarié" },
        { value: 4, label: "Autre" },
        { value: 5, label: "Agent" },
      ],
    },
    {
      name: "type",
      label: "Type",
      type: "select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      options: [
        { value: 1, label: "Vendeur" },
        { value: 2, label: "Responsable" },
        { value: 4, label: "Inspecteur" },
        { value: 8, label: "Gestionnaire" },
        { value: 16, label: "Grossiste" },
        { value: 32, label: "Cabinet" },
        { value: 64, label: "Compagnie" },
        { value: 128, label: "Autre" },
        { value: 256, label: "Agence" },
        { value: 512, label: "Siège" },
        { value: 1024, label: "Conseiller en Assurance" },
        { value: 2048, label: "Assureur Conseil" },
        { value: 4096, label: "Assureur" },
        { value: 8192, label: "Courtier" },
        { value: 16384, label: "Salarié" },
        { value: 32768, label: "Mandataire" },
        { value: 65536, label: "Conseillère en Assurance" },
      ],
    },
    {
      name: "orias",
      label: "ORIAS",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "orias_valide",
      label: "ORIAS valide",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "date_debut_orias",
      label: "Date de début ORIAS",
      type: "date",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "date_fin_orias",
      label: "Date de fin ORIAS",
      type: "date",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "telephone",
      label: "Téléphone",
      type: "text",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "mail",
      label: "Email",
      type: "email",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "mail_signatures",
      label: "Email pour signatures",
      type: "email",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "adresse",
      label: "Adresse",
      type: "textarea",
      filtrable: true,
      required: false,
      className: "col-span-12",
    },
    {
      name: "complement",
      label: "Complément d'adresse",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12",
    },
    {
      name: "ville",
      label: "Ville",
      type: "ville",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "iban",
      label: "IBAN",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "bic",
      label: "BIC",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "equipe",
      label: "Équipe",
      type: "api-select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      module: "teams",
    },
    {
      name: "objectif_projet",
      label: "Objectif du projet",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
      module: "project-objectives",
    },
    {
      name: "company",
      label: "Entreprise",
      type: "api-select",
      filtrable: true,
      required: true,
      className: "col-span-12 md:col-span-6",
      module: "companies",
      attribute: "raisonSociale",
    },
    {
      name: "groupes",
      label: "Groupes",
      type: "api-select",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6ç",
      relation: "provider-groups",
      multiple: true,
      module: "provider-groups",
      attribute: "nom",
    },
    {
      name: "code_commission_1",
      label: "Code commission 1",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_commission_2",
      label: "Code commission 2",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_commission_3",
      label: "Code commission 3",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_commission_4",
      label: "Code commission 4",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "code_commission_5",
      label: "Code commission 5",
      type: "text",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "observation",
      label: "Observation",
      type: "textarea",
      filtrable: true,
      required: false,
      className: "col-span-12",
    },
    {
      name: "bordereaux_bloques",
      label: "Bordereaux bloqués",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
    {
      name: "reglements_bloques",
      label: "Règlements bloqués",
      type: "checkbox",
      filtrable: true,
      required: false,
      className: "col-span-12 md:col-span-6",
    },
  ],
};

export default form;
