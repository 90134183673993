import { FaUserTie } from "react-icons/fa6";

import FormConfig from "./provider.form";
import TableConfig from "./provider.table";

export const providerConfig = {
  module: "provider",
  single: "Fournisseur",
  plural: "Fournisseurs",
  label: "liste des Fournisseurs",
  path: "/provider",
  api: "providers",
  icon: FaUserTie,
  displayColumns: [
    "id",
    "code",
    "nom",
    "prenom",
    "orias",
    "type_general",
    "mail",
  ],
  excludeColumns: [
    "createdAt",
    "updatedAt",
    "users",
    "groupes",
    "code_commission_1",
    "code_commission_2",
    "code_commission_3",
    "code_commission_4",
    "code_commission_5",
  ],
  table: TableConfig,
  form: FormConfig,
};

export default providerConfig;
