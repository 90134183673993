// src/components/common/savePartialCustomerData.js
import { createOrUpdateAndLinkCustomer } from "../../../services/api";

export const savePartialCustomerData = async (
  values,
  customerId,
  setCustomerId,
  venteId,
  setFormData
) => {
  try {
    console.log("values", values);
    const customer = await createOrUpdateAndLinkCustomer({
      customerId,
      customerData: values,
      venteId,
    });

    setCustomerId(customer.id);
    setFormData((prev) => ({
      ...prev,
      ...customer,
      customerId: customer.id,
    }));

    return customer;
  } catch (error) {
    console.error("Error saving partial data:", error);
    throw error;
  }
};
