import React, { useState } from "react";
import { Button, Switch, Dropdown, Checkbox, Space, Tooltip } from "antd";
import {
  DownloadOutlined,
  UploadOutlined,
  SettingOutlined,
  PlusOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import FilterDrawer from "./FilterDrawer";
import { usePermissions } from "../../../hooks/use-permissions.hook";

const Toolbar = ({
  moduleConfig,
  viewMode,
  setViewMode,
  selectedRows,
  handleBulkDelete,
  columns,
  columnVisibility,
  toggleColumnVisibility,
  handleAdd,
  handleExport,
  handleImport,
  filters,
  setFilters,
}) => {
  console.log(moduleConfig);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const { checkPermission } = usePermissions();

  const columnsMenuItems = columns
    .map((column) => {
      const columnKey = column.id || column.accessor;
      return columnKey
        ? {
            key: columnKey,
            label: (
              <Checkbox
                checked={columnVisibility[columnKey]}
                onChange={() => toggleColumnVisibility(columnKey)}
              >
                {column.Header}
              </Checkbox>
            ),
          }
        : null;
    })
    .filter(Boolean);

  return (
    <>
      <div className="mb-4 flex justify-between items-center">
        <Space>
          {/*  {checkPermission(`${moduleConfig.module}:create`) && ( */}
          {moduleConfig.table.actions.find(
            (action) => action.type === "add"
          ) && (
            <Tooltip title={`Add new ${moduleConfig.single}`}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Tooltip>
          )}
          {/*    )}
          {checkPermission(`${moduleConfig.module}:import`) && ( */}
          <Tooltip title="Import data">
            <Button icon={<UploadOutlined />} onClick={handleImport}>
              Import
            </Button>
          </Tooltip>
          {/*   )}
          {checkPermission(`${moduleConfig.module}:export`) && ( */}
          <Tooltip title="Export data">
            <Button icon={<DownloadOutlined />} onClick={handleExport}>
              Export
            </Button>
          </Tooltip>
          {/*  )}
          {checkPermission(`${moduleConfig.module}:filter`) && ( */}
          <Tooltip title="Filter data">
            <Button
              icon={<FilterOutlined />}
              onClick={() => setFilterDrawerVisible(true)}
            >
              Filter
            </Button>
          </Tooltip>
          {/*  )} */}
        </Space>
        {selectedRows.length > 0 && (
          <Button icon={<DeleteOutlined />} onClick={handleBulkDelete}>
            Delete Selected ({selectedRows.length})
          </Button>
        )}
        <div className="flex space-x-2">
          <Switch
            checkedChildren="Table"
            unCheckedChildren="Card"
            checked={viewMode === "table"}
            onChange={(checked) => setViewMode(checked ? "table" : "card")}
          />
          <Dropdown menu={{ items: columnsMenuItems }} trigger={["click"]}>
            <Button icon={<SettingOutlined />}>Columns</Button>
          </Dropdown>
        </div>
      </div>
      <FilterDrawer
        visible={filterDrawerVisible}
        onClose={() => setFilterDrawerVisible(false)}
        filters={filters}
        setFilters={setFilters}
        columns={columns}
      />
    </>
  );
};

export default Toolbar;
