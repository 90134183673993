import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  Card,
  Typography,
  Space,
  Divider,
  Tooltip,
  Steps,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  BankOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { getItemById } from "../../../services/api";
import { savePartialCustomerData } from "./savePartialCustomerData";
import AddressAutocomplete from "./../form-hooks-and-helpers/renderField/address";
import CityAutocomplete from "./../form-hooks-and-helpers/renderField/cities";

const { Title } = Typography;
const { Option } = Select;
const { Step } = Steps;

const MergedCustomerInformationForm = ({
  formData,
  setFormData,
  customerId,
  setCustomerId,
  venteId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      console.log("customerId", customerId);
      if (customerId) {
        try {
          setLoading(true);
          const customerData = await getItemById("customers", customerId);
          form.setFieldsValue(customerData);
        } catch (error) {
          console.error("Error fetching customer data:", error);
          message.error("Failed to load customer data");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [customerId, form]);

  const onFinish = async () => {
    try {
      setLoading(true);
      // Validate all fields in the form
      await form.validateFields();

      // Get values for all three steps
      const values = form.getFieldsValue(true);

      const response = await savePartialCustomerData(
        values,
        customerId,
        setCustomerId,
        venteId,
        setFormData
      );
      console.log(response);
      message.success("Customer information saved successfully");
    } catch (error) {
      console.log(error);
      if (error.errorFields) {
        // Form validation error
        message.error("Please fill in all required fields correctly");
      } else if (error.response?.data?.message) {
        message.error(
          `Failed to save information: ${error.response.data.message}`
        );
      } else {
        message.error("Failed to save customer information");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddressChange = (address, extraData) => {
    form.setFieldsValue({
      address: address,
      postalCode: extraData?.postcode,
      city: extraData?.city,
      country: "france",
    });
  };

  const handleBankAddressChange = (address, extraData) => {
    form.setFieldsValue({
      bankAddress: address,
      bankCity: extraData?.postcode + " " + extraData?.city,
      bankCountry: "france",
    });
  };

  const handleCityChange = (city, extraData) => {
    form.setFieldsValue({
      city: city,
      postalCode: extraData.codesPostaux[0],
    });
  };

  const nextStep = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        console.log("Validation failed:", error);
      });
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderNavigationButtons = () => (
    <div className="flex justify-between items-center mb-6">
      <Button
        onClick={prevStep}
        disabled={currentStep === 0}
        icon={<LeftOutlined />}
        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Previous
      </Button>
      <Steps current={currentStep} className="flex-1 mx-4">
        <Step title="Personal Info" />
        <Step title="Address" />
        <Step title="Payment & Bank" />
      </Steps>
      <Space>
        <Button
          type="primary"
          onClick={onFinish}
          loading={loading}
          icon={<SaveOutlined />}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          {loading ? "Saving..." : "Save All"}
        </Button>
        {currentStep < 2 && (
          <Button
            onClick={nextStep}
            icon={<RightOutlined />}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
          >
            Next
          </Button>
        )}
      </Space>
    </div>
  );

  return (
    <Card className="shadow-lg rounded-lg bg-white p-8 max-w-4xl mx-auto">
      <Title level={2} className="mb-8 text-center text-gray-800">
        Customer Information
      </Title>

      <Form
        form={form}
        layout="vertical"
        initialValues={formData}
        className="w-full"
      >
        {renderNavigationButtons()}

        <div className="bg-gray-50 p-6 rounded-lg">
          {currentStep === 0 && (
            <Space direction="vertical" size="large" className="w-full">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: "Please select a title" }]}
                >
                  <Select placeholder="Select title">
                    <Option value="mr">Mr</Option>
                    <Option value="mrs">Mrs</Option>
                    <Option value="ms">Ms</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="text-gray-400" />}
                    placeholder="Enter your first name"
                  />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="text-gray-400" />}
                    placeholder="Enter your last name"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="text-gray-400" />}
                    placeholder="Enter your email"
                  />
                </Form.Item>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your mobile number",
                    },
                  ]}
                >
                  <Input
                    prefix={<PhoneOutlined className="text-gray-400" />}
                    placeholder="Enter your mobile number"
                  />
                </Form.Item>
              </div>
            </Space>
          )}

          {currentStep === 1 && (
            <Space direction="vertical" size="large" className="w-full">
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  { required: true, message: "Please enter your address" },
                ]}
              >
                <AddressAutocomplete
                  onChange={(value, option) =>
                    handleAddressChange(value, option?.extraData)
                  }
                  placeholder="Enter an address"
                  className="w-full"
                />
              </Form.Item>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Form.Item
                  name="postalCode"
                  label="Postal Code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your postal code",
                    },
                  ]}
                >
                  <Input
                    prefix={<HomeOutlined className="text-gray-400" />}
                    placeholder="Enter postal code"
                  />
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter your city" },
                  ]}
                >
                  <CityAutocomplete
                    onChange={(value, option) =>
                      handleCityChange(value, option?.extraData)
                    }
                    placeholder="Enter a city"
                    className="w-full"
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "Please select a country" }]}
              >
                <Select
                  placeholder="Select country"
                  prefix={<GlobalOutlined />}
                >
                  <Option value="france">France</Option>
                  <Option value="belgium">Belgium</Option>
                  <Option value="switzerland">Switzerland</Option>
                </Select>
              </Form.Item>
            </Space>
          )}

          {currentStep === 2 && (
            <Space direction="vertical" size="large" className="w-full">
              <Form.Item
                name="paymentFrequency"
                label="Payment Frequency"
                rules={[
                  {
                    required: true,
                    message: "Please select a payment frequency",
                  },
                ]}
              >
                <Select placeholder="Select payment frequency">
                  <Option value="monthly">Monthly</Option>
                  <Option value="quarterly">Quarterly</Option>
                  <Option value="annually">Annually</Option>
                </Select>
              </Form.Item>

              <Divider orientation="left">Bank Details</Divider>

              <Form.Item
                name="bankAddress"
                label="Bank Address"
                rules={[
                  { required: true, message: "Please enter the bank address" },
                ]}
              >
                <AddressAutocomplete
                  onChange={(value, option) =>
                    handleBankAddressChange(value, option?.extraData)
                  }
                  placeholder="Enter bank address"
                  className="w-full"
                />
              </Form.Item>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Form.Item
                  name="bankCity"
                  label="Bank City"
                  rules={[
                    { required: true, message: "Please enter the bank city" },
                  ]}
                >
                  <Input
                    prefix={<BankOutlined className="text-gray-400" />}
                    placeholder="Enter bank city"
                  />
                </Form.Item>
                <Form.Item
                  name="bankCountry"
                  label="Bank Country"
                  rules={[
                    {
                      required: true,
                      message: "Please select the bank country",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select bank country"
                    prefix={<GlobalOutlined />}
                  >
                    <Option value="france">France</Option>
                    <Option value="belgium">Belgium</Option>
                    <Option value="switzerland">Switzerland</Option>
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                name="iban"
                label="IBAN"
                rules={[{ required: true, message: "Please enter your IBAN" }]}
              >
                <Input
                  prefix={<CreditCardOutlined className="text-gray-400" />}
                  placeholder="Enter your IBAN"
                />
              </Form.Item>

              <Form.Item
                name="bic"
                label="BIC"
                rules={[{ required: true, message: "Please enter your BIC" }]}
              >
                <Input
                  prefix={<BankOutlined className="text-gray-400" />}
                  placeholder="Enter your BIC"
                />
              </Form.Item>

              <Form.Item
                name="prestationBancaire"
                label={
                  <span>
                    Prestation Bancaire
                    <Tooltip title="Indicates whether you require additional banking services">
                      <QuestionCircleOutlined className="ml-2 text-gray-400" />
                    </Tooltip>
                  </span>
                }
                rules={[{ required: true, message: "Please select an option" }]}
              >
                <Select placeholder="Select an option">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>
            </Space>
          )}
        </div>
      </Form>
    </Card>
  );
};

export default MergedCustomerInformationForm;
