import React from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill"; // You'll need to install this package
import "react-quill/dist/quill.snow.css"; // Don't forget to import Quill styles
import DynamicArrayField from "./DynamicArrayField";
import RelationTableComponent from "./relation-table-component";
import ApiSelect from "./ApiSelect"; // Import the new ApiSelect component
import ApiMultiSelect from "./ApiMultiSelect";
import CityInput from "./cities"; // Import the new GooglePlacesInput component
import dayjs from "dayjs";
import { Input, Select, DatePicker } from "antd";

const { TextArea } = Input;

const RenderField = ({
  config,
  register,
  setValue,
  control,
  field,
  errors,
}) => {
  const fieldConfig = config.form.fields.find((f) => f.name === field);
  if (!fieldConfig) return null;

  const errorMessage = errors[field]?.message;

  const commonClasses =
    "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50";

  switch (fieldConfig.type) {
    case "text":
    case "email":
    case "number":
    case "password":
    case "tel":
    case "url":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field }) => (
            <>
              <Input
                {...field}
                type={fieldConfig.type}
                className={commonClasses}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "textarea":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field }) => (
            <>
              <TextArea {...field} className={`${commonClasses} h-32`} />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "date":
    case "time":
    case "datetime-local":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{
            required: fieldConfig.required,
            validate: (value) => {
              if (fieldConfig.required && !value) {
                return "This field is required";
              }
              if (value && !dayjs(value).isValid()) {
                return "Invalid date";
              }
              return true;
            },
          }}
          render={({ field }) => (
            <>
              <DatePicker
                {...field}
                className={commonClasses}
                onChange={(date) => {
                  field.onChange(date ? date.toDate() : null);
                }}
                value={field.value ? dayjs(field.value) : null}
                format={
                  fieldConfig.type === "date"
                    ? "YYYY-MM-DD"
                    : "YYYY-MM-DD HH:mm:ss"
                }
                showTime={fieldConfig.type !== "date"}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "select":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field }) => (
            <>
              <Select
                {...field}
                className={commonClasses}
                style={{ width: "100%" }}
                options={fieldConfig.options.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "api-select":
      return (
        <ApiSelect
          fieldConfig={fieldConfig}
          control={control}
          errorMessage={errorMessage}
          commonClasses={commonClasses}
        />
      );

    case "api-multi-select":
      return (
        <ApiMultiSelect
          fieldConfig={fieldConfig}
          control={control}
          errorMessage={errorMessage}
          commonClasses={commonClasses}
        />
      );

    case "cities":

    case "table":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field: { onChange, value } }) => (
            <>
              <RelationTableComponent
                spec={fieldConfig}
                value={value}
                onChange={onChange}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "checkbox":
      return (
        <div className="flex items-center">
          <input
            type="checkbox"
            {...register(fieldConfig.name)}
            className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
          />
          <label
            htmlFor={fieldConfig.name}
            className="ml-2 block text-sm text-gray-900"
          >
            {fieldConfig.label}
          </label>
        </div>
      );

    case "radio":
      return (
        <div>
          {fieldConfig.options.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                type="radio"
                id={`${fieldConfig.name}-${option.value}`}
                value={option.value}
                {...register(fieldConfig.name, {
                  required: fieldConfig.required,
                })}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
              <label
                htmlFor={`${fieldConfig.name}-${option.value}`}
                className="ml-2 block text-sm text-gray-900"
              >
                {option.label}
              </label>
            </div>
          ))}
          {errorMessage && (
            <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
          )}
        </div>
      );

    case "file":
      return (
        <>
          <input
            type="file"
            accept={fieldConfig.accept}
            {...register(fieldConfig.name, { required: fieldConfig.required })}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
          />
          {errorMessage && (
            <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
          )}
        </>
      );

    case "image":
      return (
        <>
          <input
            type="file"
            accept="image/*"
            {...register(fieldConfig.name, { required: fieldConfig.required })}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
          />
          {errorMessage && (
            <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
          )}
        </>
      );

    case "rich-text":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field }) => (
            <>
              <ReactQuill
                theme="snow"
                value={field.value}
                onChange={field.onChange}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    case "array":
      return (
        <DynamicArrayField
          fieldConfig={fieldConfig}
          control={control}
          register={register}
          errors={errors}
        />
      );

    case "ville":
      return (
        <Controller
          name={fieldConfig.name}
          control={control}
          rules={{ required: fieldConfig.required }}
          render={({ field: { onChange, value } }) => (
            <>
              <CityInput
                value={value}
                onChange={onChange}
                onExtraDataChange={(extraData) => {
                  setValue("cityCode", extraData.code);
                  setValue("departmentCode", extraData.codeDepartement);
                  setValue("regionCode", extraData.codeRegion);
                }}
                placeholder={fieldConfig.placeholder}
              />
              {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
              )}
            </>
          )}
        />
      );

    default:
      return null;
  }
};

export default RenderField;
