import { FaBoxesStacked } from "react-icons/fa6";

import FormConfig from "./question.form";
import TableConfig from "./question.table";

export const questionGammeConfig = {
  module: "questions",
  single: "Gamme question",
  plural: "Gammes questions",
  label: "Gamme Question",
  path: "/question-gamme",
  api: "questions/gammes",
  icon: FaBoxesStacked,
  displayColumns: ["id", "text"],
  excludeColumns: ["createdAt", "updatedAt", "userResponses"],
  table: TableConfig,
  form: FormConfig,
  addon: "gamme",
};

export default questionGammeConfig;
