import { FaCircleQuestion } from "react-icons/fa6";

import FormConfig from "./question.form";
import TableConfig from "./question.table";

export const questionConfig = {
  module: "questions",
  single: "question",
  plural: "questions",
  label: "Questions",
  path: "/question",
  api: "questions",
  icon: FaCircleQuestion,
  displayColumns: ["id", "text"],
  excludeColumns: ["createdAt", "updatedAt", "userResponses"],
  table: TableConfig,
  form: FormConfig,
  addon: "question",
};

export default questionConfig;
