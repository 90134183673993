import { FaDiagramProject } from "react-icons/fa6";

export const NewProject = {
  module: "vente",
  single: "Nouveau projet",
  plural: "Nouveaux Projets",
  label: "Nouveau projet",
  path: "/new-project",
  link: "nouveau-projet",
  icon: FaDiagramProject,
};

export default NewProject;
