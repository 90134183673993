import React, { createContext, useState, useContext } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [globalError, setGlobalError] = useState(null);

  const setError = (error) => {
    setGlobalError(error);
  };

  const clearError = () => {
    setGlobalError(null);
  };

  return (
    <ErrorContext.Provider value={{ globalError, setError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
