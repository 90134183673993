import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Form, Checkbox, Tooltip, Space, Typography, Badge } from "antd";
import {
  QuestionCircleOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import ThreeStateSwitch from "../../common/Switch";

const { Text } = Typography;

const PermissionsForm = ({ modules, form, initialPermissions, onChange }) => {
  const [permissions, setPermissions] = useState({});
  const [expandedModules, setExpandedModules] = useState([]);

  const initializeFullPermissions = useCallback(() => {
    return modules.reduce((acc, module) => {
      if (module && module.name && module.controllers) {
        acc[module.name] = module.controllers.reduce(
          (controllerAcc, controller) => {
            if (controller && controller.name && controller.actions) {
              controllerAcc[controller.name] = controller.actions.reduce(
                (actionAcc, action) => {
                  if (action && action.name) {
                    actionAcc[action.name] = false;
                  }
                  return actionAcc;
                },
                {}
              );
            }
            return controllerAcc;
          },
          {}
        );
      }
      return acc;
    }, {});
  }, [modules]);

  const mergePermissions = (fullPermissions, initialPermissions) => {
    const merged = { ...fullPermissions };
    Object.keys(initialPermissions).forEach((moduleName) => {
      if (merged[moduleName]) {
        Object.keys(initialPermissions[moduleName]).forEach(
          (controllerName) => {
            if (merged[moduleName][controllerName]) {
              Object.keys(
                initialPermissions[moduleName][controllerName]
              ).forEach((actionName) => {
                if (
                  merged[moduleName][controllerName].hasOwnProperty(actionName)
                ) {
                  merged[moduleName][controllerName][actionName] =
                    initialPermissions[moduleName][controllerName][actionName];
                }
              });
            }
          }
        );
      }
    });
    return merged;
  };

  useEffect(() => {
    const fullPermissions = initializeFullPermissions();
    const mergedPermissions = initialPermissions
      ? mergePermissions(fullPermissions, initialPermissions)
      : fullPermissions;

    setPermissions(mergedPermissions);
    form.setFieldsValue({ permissions: mergedPermissions });
  }, [form, initialPermissions, modules, initializeFullPermissions]);

  const calculateModuleState = useCallback((modulePermissions) => {
    if (!modulePermissions) return "unchecked";

    let checkedCount = 0;
    let totalCount = 0;

    Object.values(modulePermissions).forEach((controller) => {
      Object.values(controller).forEach((isChecked) => {
        totalCount++;
        if (isChecked) checkedCount++;
      });
    });

    if (checkedCount === 0) return "unchecked";
    if (checkedCount === totalCount) return "checked";
    return "intermediate";
  }, []);

  const handleSwitchChange = useCallback(
    (moduleName) => {
      setPermissions((prevPermissions) => {
        const currentState = calculateModuleState(prevPermissions[moduleName]);
        const newCheckedState = currentState !== "checked";

        const updatedModulePermissions = Object.entries(
          prevPermissions[moduleName] || {}
        ).reduce((acc, [controllerName, actions]) => {
          acc[controllerName] = Object.keys(actions || {}).reduce(
            (actionAcc, actionName) => {
              actionAcc[actionName] = newCheckedState;
              return actionAcc;
            },
            {}
          );
          return acc;
        }, {});

        const newPermissions = {
          ...prevPermissions,
          [moduleName]: updatedModulePermissions,
        };

        form.setFieldsValue({ permissions: newPermissions });
        onChange(newPermissions);
        return newPermissions;
      });
    },
    [calculateModuleState, form, onChange]
  );

  const handleCheckboxChange = useCallback(
    (moduleName, controllerName, actionName, checked) => {
      setPermissions((prevPermissions) => {
        const newPermissions = {
          ...prevPermissions,
          [moduleName]: {
            ...(prevPermissions[moduleName] || {}),
            [controllerName]: {
              ...(prevPermissions[moduleName]?.[controllerName] || {}),
              [actionName]: checked,
            },
          },
        };
        form.setFieldsValue({ permissions: newPermissions });
        onChange(newPermissions);
        return newPermissions;
      });
    },
    [form, onChange]
  );

  const renderModulePermissions = useCallback(
    (module) => (
      <div className="pl-8 space-y-4">
        {module.controllers?.map((controller) =>
          controller && controller.name ? (
            <div key={controller.name}>
              <Text strong className="mb-2 block">
                {controller.name}
              </Text>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                {controller.actions?.map((action) =>
                  action && action.name ? (
                    <Form.Item
                      key={`${module.name}.${controller.name}.${action.name}`}
                      name={[
                        "permissions",
                        module.name,
                        controller.name,
                        action.name,
                      ]}
                      valuePropName="checked"
                      className="m-0"
                    >
                      <Checkbox
                        onChange={(e) =>
                          handleCheckboxChange(
                            module.name,
                            controller.name,
                            action.name,
                            e.target.checked
                          )
                        }
                      >
                        <Tooltip title={action.description}>
                          <Text className="truncate max-w-[200px] inline-block align-bottom">
                            {action.description || action.name}
                          </Text>
                        </Tooltip>
                      </Checkbox>
                    </Form.Item>
                  ) : null
                )}
              </div>
            </div>
          ) : null
        )}
      </div>
    ),
    [handleCheckboxChange]
  );

  const getBadgeInfo = useCallback((state) => {
    switch (state) {
      case "checked":
        return { text: "All", color: "#52c41a" };
      case "unchecked":
        return { text: "None", color: "#f5222d" };
      default:
        return { text: "Some", color: "#faad14" };
    }
  }, []);

  const toggleExpand = useCallback((moduleName) => {
    setExpandedModules((prev) =>
      prev.includes(moduleName)
        ? prev.filter((name) => name !== moduleName)
        : [...prev, moduleName]
    );
  }, []);

  const moduleStates = useMemo(() => {
    return Object.keys(permissions).reduce((acc, moduleName) => {
      acc[moduleName] = calculateModuleState(permissions[moduleName]);
      return acc;
    }, {});
  }, [permissions, calculateModuleState]);

  return (
    <div className="space-y-2">
      {modules.map((module) => {
        if (!module || !module.name) return null;

        const moduleState = moduleStates[module.name];
        const badgeInfo = getBadgeInfo(moduleState);

        return (
          <div key={module.name} className="border border-gray-200 rounded-md">
            <div className="flex items-center justify-between p-2 bg-gray-50">
              <div className="flex items-center space-x-2">
                <Text strong className="text-base">
                  {module.name.replace(/Module/g, " ")}
                </Text>
                <Badge
                  count={badgeInfo.text}
                  style={{ backgroundColor: badgeInfo.color }}
                />
              </div>
              <Space>
                <ThreeStateSwitch
                  state={moduleState}
                  onChange={() => handleSwitchChange(module.name)}
                />
                <Tooltip title="Expand/Collapse">
                  <button
                    onClick={() => toggleExpand(module.name)}
                    className="bg-transparent border-none cursor-pointer p-1"
                  >
                    {expandedModules.includes(module.name) ? (
                      <DownOutlined />
                    ) : (
                      <RightOutlined />
                    )}
                  </button>
                </Tooltip>
              </Space>
            </div>
            {expandedModules.includes(module.name) && (
              <div className="p-4">{renderModulePermissions(module)}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PermissionsForm;
