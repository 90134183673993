import React from "react";
import { Form, Checkbox, Card, Typography } from "antd";
import {
  DollarOutlined,
  SettingOutlined,
  TransactionOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-green-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const CheckboxItem = ({ name, children }) => (
  <FormItem name={name} valuePropName="checked" className="mb-2">
    <Checkbox className="text-sm text-gray-700">{children}</Checkbox>
  </FormItem>
);

const CommissioningTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-green-800">
        Paramètres de Commissionnement
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Calcul des Commissions"
          icon={<DollarOutlined />}
          className="bg-green-50"
        >
          <CheckboxItem name="proratizePrecomptedCommissionByTermDuration">
            Proratiser commission précompter selon la durée d'échéance
          </CheckboxItem>
          <CheckboxItem name="ignoreTeamCommissionErrors">
            Ignorer les erreurs de commission d'équipe (sauf transfert)
          </CheckboxItem>
          <CheckboxItem name="precomptGivenOnlyOnce">
            Précompte donné une seule fois (commissionnement à l'encaissement
            uniquement)
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Paiements et Encaissements"
          icon={<TransactionOutlined />}
          className="bg-blue-50"
        >
          <CheckboxItem name="allowSameIdPaymentImport">
            Import encaissement de même Id autorisé
          </CheckboxItem>
          <CheckboxItem name="modifyDueDatesToFullPaymentDate">
            Modification des dates d'échéance à la date d'encaissement complet
          </CheckboxItem>
          <CheckboxItem name="payCommissionsOnPaymentDate">
            Paiement des commissions à la date d'encaissement
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Transferts et Clôtures"
          icon={<SyncOutlined />}
          className="bg-yellow-50"
        >
          <CheckboxItem name="automaticApporteurTransfer">
            Transfert apporteur automatique (Edi)
          </CheckboxItem>
          <CheckboxItem name="automaticCoApporteurClosure">
            Clôture co-apporteur automatique (Edi)
          </CheckboxItem>
          <CheckboxItem name="changeCommissionModelsOnPortfolioTransfer">
            Changement modèles de commissions sur transfert de portefeuille
          </CheckboxItem>
        </SectionCard>

        <SectionCard
          title="Affichage et Contrôles"
          icon={<SettingOutlined />}
          className="bg-purple-50"
        >
          <CheckboxItem name="displayComplementaryIndividualStatementNumbering">
            Affichage complémentaire de la numérotation individuelle des
            bordereaux
          </CheckboxItem>
          <CheckboxItem name="companyNameInSepaTransferLabels">
            Nom de l'entreprise dans les libellés du virement SEPA
          </CheckboxItem>
          <CheckboxItem name="blockApporteurPaymentIfInvalidOrias">
            Bloquer le règlement des apporteurs si Orias invalide
          </CheckboxItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default CommissioningTab;
