import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch, Controller } from "react-hook-form";
import { FaCirclePlus, FaTrashCan } from "react-icons/fa6";
import { Input, Checkbox } from "antd";

const DynamicArrayField = ({ fieldConfig, control, errors, defaultValues }) => {
  const [localFields, setLocalFields] = useState([]);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: fieldConfig.name,
  });

  const questionType = useWatch({
    control,
    name: "type",
  });

  const showOptions =
    ["radio", "checkbox"].includes(questionType) || !questionType;

  console.log("Local fields:", questionType, showOptions);

  const isStringArray = fieldConfig.response === "string";

  useEffect(() => {
    if (
      defaultValues &&
      defaultValues[fieldConfig.name] &&
      defaultValues[fieldConfig.name].length > 0
    ) {
      replace(defaultValues[fieldConfig.name]);
      setLocalFields(defaultValues[fieldConfig.name]);
    } else if (fields.length > 0) {
      setLocalFields(fields);
    } else {
      const emptyField = isStringArray ? "" : { text: "", isCorrect: false };
      append(emptyField);
      setLocalFields([emptyField]);
    }
  }, [defaultValues, fieldConfig.name, replace, append, fields, isStringArray]);

  if (!showOptions) {
    return null;
  }

  const renderField = (item, index) => {
    if (isStringArray) {
      return (
        <Controller
          name={`${fieldConfig.name}.${index}`}
          control={control}
          defaultValue={item || ""}
          rules={{ required: fieldConfig.required }}
          render={({ field }) => (
            <Input
              {...field}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          )}
        />
      );
    } else {
      return (
        <>
          <Controller
            name={`${fieldConfig.name}.${index}.text`}
            control={control}
            defaultValue={item.text || ""}
            rules={{ required: fieldConfig.fields[0].required }}
            render={({ field }) => (
              <Input
                {...field}
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            )}
          />
          {fieldConfig.fields[1] && (
            <Controller
              name={`${fieldConfig.name}.${index}.isCorrect`}
              control={control}
              defaultValue={item.isCorrect || false}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value}>
                  {fieldConfig.fields[1].label}
                </Checkbox>
              )}
            />
          )}
        </>
      );
    }
  };

  return (
    <div className="space-y-4">
      {localFields.map((item, index) => (
        <div
          key={index}
          className="flex flex-wrap items-end space-x-4 pb-4 border-b mt-6"
        >
          <div className="flex-grow">
            <label className="block text-sm font-medium text-gray-700">
              {isStringArray ? fieldConfig.label : fieldConfig.fields[0].label}
            </label>
            {renderField(item, index)}
            {errors[fieldConfig.name]?.[index] && (
              <p className="mt-1 text-sm text-red-600">Ce champ est requis</p>
            )}
          </div>
          <button
            type="button"
            onClick={() => {
              remove(index);
              setLocalFields((prev) => prev.filter((_, i) => i !== index));
            }}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <FaTrashCan className="h-4 w-4 mr-2" />
            Supprimer
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={() => {
          const newField = isStringArray ? "" : { text: "", isCorrect: false };
          append(newField);
          setLocalFields((prev) => [...prev, newField]);
        }}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FaCirclePlus className="h-5 w-5 mr-2" />
        Ajouter une option
      </button>
    </div>
  );
};

export default DynamicArrayField;
