const form = {
  actions: [
    {
      label: "Enregistrer",
      type: "button",
    },
  ],
  tabs: [
    {
      label: "Informations générales",
      fields: ["name", "order"],
    },
  ],
  fields: [
    {
      name: "name",
      label: "Nom de la gamme",
      type: "textarea",
      filtrable: true,
      required: true,
      className: "col-span-8",
    },
  ],
};

export default form;
