import dayjs from "dayjs";

export const toISOStringOrNull = (date) => {
  if (!date) {
    console.log("Date is null or undefined");
    return null;
  }
  const d = dayjs(date);
  if (!d.isValid()) {
    console.log(`Invalid date: ${date}`);
    return null;
  }
  return d.toISOString();
};
