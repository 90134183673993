import { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import {
  getItems,
  updateItem,
  createItem,
  deleteItem,
} from "../../../services/api";
import { useError } from "../../../contexts/ErrorContext";

export const useRoleManagement = (form) => {
  const { setError, clearError } = useError();
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getItems("roles", { pageIndex: 0, pageSize: 100 });
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      message.error("Failed to fetch roles");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchModules = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getItems("modules", {
        pageIndex: 0,
        pageSize: 100,
      });
      setModules(response.data);
    } catch (error) {
      console.error("Error fetching modules:", error);
      message.error("Failed to fetch modules");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRoles();
    fetchModules();
  }, [fetchRoles, fetchModules]);

  const initializeForm = useCallback(
    (role = null) => {
      const initialValues = {
        name: role?.name || "",
        permissions: role?.permissions || {},
      };
      /* modules.forEach((module) => {
        initialValues.permissions[module.name] = {};
        module.controllers.forEach((controller) => {
          initialValues.permissions[module.name][controller.name] = {};
          controller.actions.forEach((action) => {
            const fullActionName = `${controller.name}.${action.name}`;
            if (role && role.permissions) {
              initialValues.permissions[module.name][controller.name][
                action.name
              ] =
                role.permissions[module.name]?.includes(fullActionName) ||
                false;
            } else {
              initialValues.permissions[module.name][controller.name][
                action.name
              ] = false;
            }
          });
        });
      }); */

      form.setFieldsValue(initialValues);
    },
    [modules, form]
  );

  const handleCreateRole = useCallback(() => {
    setCurrentRole(null);
    initializeForm();
    setModalVisible(true);
  }, [initializeForm]);

  const handleEditRole = useCallback(
    (role) => {
      setCurrentRole(role);
      initializeForm(role);
      setModalVisible(true);
    },
    [initializeForm]
  );

  const handleDeleteRole = useCallback(
    async (roleId) => {
      try {
        await deleteItem("roles", roleId);
        message.success("Role deleted successfully");
        fetchRoles();
      } catch (error) {
        console.error("Error deleting role:", error);
        message.error("Failed to delete role");
      }
    },
    [fetchRoles]
  );
  const handleModalOk = useCallback(
    async (values) => {
      try {
        const { name, permissions } = values;

        if (
          !permissions ||
          typeof permissions !== "object" ||
          Object.keys(permissions).length === 0
        ) {
          setError({
            statusCode: 500,
            message: "Permissions are required",
          });
        }

        clearError();

        const roleData = {
          name,
          permissions,
        };

        if (currentRole) {
          await updateItem("roles", currentRole.id, roleData);
          message.success("Role updated successfully");
        } else {
          await createItem("roles", roleData);
          message.success("Role created successfully");
        }

        clearError();
        setModalVisible(false);
      } catch (error) {
        console.error("Error in handleModalOk:", error);
        setError({
          statusCode: error.response?.status || 500,
          message:
            error.response?.data?.message || "An unexpected error occurred",
          errors: error.response?.data?.errors,
        });
        message.error(`Failed to save role: ${error.message}`);
      }
    },
    [
      currentRole,
      fetchRoles,
      updateItem,
      createItem,
      setModalVisible,
      clearError,
      setError,
    ]
  );

  return {
    roles,
    modules,
    loading,
    modalVisible,
    currentRole,
    setRoles,
    handleCreateRole,
    handleEditRole,
    handleDeleteRole,
    handleModalOk,
    setModalVisible,
    fetchRoles,
    initializeForm,
  };
};
