import { useMemo } from "react";
import { Button, Tooltip, Input, Select, DatePicker } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { usePermissions } from "./../../../hooks/use-permissions.hook";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const useColumns = (data, moduleConfig, onDelete, navigate) => {
  const { checkPermission } = usePermissions();

  return useMemo(() => {
    if (
      !moduleConfig ||
      data.length === 0 ||
      !moduleConfig ||
      !moduleConfig.form
    )
      return [];

    const { excludeColumns = [], customRenderers = {} } = moduleConfig.form;
    const fieldMap = new Map(
      moduleConfig.form.fields.map((field) => [field.name, field])
    );

    const dataColumns = Object.keys(data[0])
      .filter((key) => !excludeColumns.includes(key))
      .map((key) => {
        const fieldConfig = fieldMap.get(key) || {};
        const column = {
          Header:
            fieldConfig.label ||
            key.charAt(0).toUpperCase() +
              key
                .slice(1)
                .replace(/([A-Z])/g, " $1")
                .trim(),
          filtrable: fieldConfig.filtrable || false,
          sortable: true,
          accessor: key,
          id: key,
          Cell: customRenderers[key] || (({ value }) => String(value ?? "")),
        };

        // Define filter based on field type
        switch (fieldConfig.type) {
          case "select":
            column.Filter = ({ column: { filterValue, setFilter } }) => (
              <Select
                value={filterValue}
                onChange={setFilter}
                style={{ width: "100%" }}
                allowClear
                placeholder={`Filter ${column.Header}`}
              >
                {fieldConfig.options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            );
            break;
          case "date":
            column.Filter = ({ column: { filterValue, setFilter } }) => (
              <RangePicker
                value={
                  filterValue
                    ? [moment(filterValue[0]), moment(filterValue[1])]
                    : null
                }
                onChange={(dates) => {
                  setFilter(
                    dates ? dates.map((date) => date.toISOString()) : undefined
                  );
                }}
                style={{ width: "100%" }}
              />
            );
            break;
          case "checkbox":
            column.Filter = ({ column: { filterValue, setFilter } }) => (
              <Select
                value={filterValue}
                onChange={setFilter}
                style={{ width: "100%" }}
                allowClear
                placeholder={`Filter ${column.Header}`}
              >
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            );
            break;
          case "number":
            column.Filter = ({ column: { filterValue, setFilter } }) => (
              <Input
                type="number"
                value={filterValue || ""}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={`Filter ${column.Header}`}
                style={{ width: "100%" }}
              />
            );
            break;
          case "text":
          case "email":
          default:
            column.Filter = ({ column: { filterValue, setFilter } }) => (
              <Input
                value={filterValue || ""}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={`Filter ${column.Header}`}
                style={{ width: "100%" }}
              />
            );
            break;
        }

        return column;
      });

    const actionColumn = {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          {checkPermission(
            `${moduleConfig.module}:update`,
            moduleConfig.addon
          ) && (
            <Tooltip title="Edit">
              <Button
                icon={<EditOutlined />}
                onClick={() =>
                  navigate(
                    moduleConfig?.table?.rowActions?.edit
                      ? `${moduleConfig.table.rowActions.edit.replace(
                          /:id/,
                          row.original.id
                        )}`
                      : `/edit/${moduleConfig.single}/${row.original.id}`,
                    {
                      state: { item: row.original },
                    }
                  )
                }
              />
            </Tooltip>
          )}

          {!moduleConfig.table?.rowActions?.delete &&
            checkPermission(
              `${moduleConfig.module}:delete`,
              moduleConfig.addon
            ) && (
              <Tooltip title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this item?"
                      )
                    ) {
                      onDelete(row.original.id);
                    }
                  }}
                />
              </Tooltip>
            )}
        </div>
      ),
    };

    return [actionColumn, ...dataColumns];
  }, [data, moduleConfig, onDelete, navigate]);
};
