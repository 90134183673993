import React from "react";
import { Form, Input, Checkbox, InputNumber, Card, Typography } from "antd";
import {
  SafetyCertificateOutlined,
  KeyOutlined,
  SyncOutlined,
  ApiOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-teal-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const OriasTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-teal-800">
        Paramètres Orias
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Configuration Orias"
          icon={<SafetyCertificateOutlined />}
          className="bg-teal-50"
        >
          <FormItem name="oriasUserToken" label="Token user Orias">
            <Input />
          </FormItem>
          <FormItem name="advancedOriasVerification" valuePropName="checked">
            <Checkbox>Vérification ORIAS avancée</Checkbox>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Vérifications"
          icon={<SyncOutlined />}
          className="bg-blue-50"
        >
          <FormItem
            name="simultaneousOriasVerificationsCount"
            label="Nombre de vérifications ORIAS simultanées"
          >
            <InputNumber min={1} />
          </FormItem>
          <FormItem name="oriasAutoCheck" valuePropName="checked">
            <Checkbox>Vérification ORIAS automatique</Checkbox>
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Fréquence de vérification"
          icon={<KeyOutlined />}
          className="bg-green-50"
        >
          <FormItem
            name="oriasCheckFrequency"
            label="Fréquence de vérification ORIAS"
          >
            <InputNumber min={1} addonAfter="jours" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="API Orias"
          icon={<ApiOutlined />}
          className="bg-yellow-50"
        >
          <FormItem
            name="oriasApiEndpoint"
            label="Point de terminaison de l'API ORIAS"
          >
            <Input />
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default OriasTab;
