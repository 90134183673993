// src/config/index.js
import { companyConfig } from "./company";
import { projectObjectiveConfig } from "./projectObjectives";
import { providerConfig } from "./provider";
import { providerGroupConfig } from "./providerGroup";
import { teamConfig } from "./team";
import { userConfig } from "./user";
import { questionConfig } from "./question";
import { questionAdditionalConfig } from "./question-additional";
import { questionCategConfig } from "./question-category";
import { questionGammeConfig } from "./question-gamme";
import { NewProject } from "./new-project";
import { VenteConfig } from "./ventes";
import { Roles } from "./roles";
import { ParamsConfig } from "./parameters";
import { FaFile, FaGear, FaUsersGear } from "react-icons/fa6";
// import other module configs

export const models = {
  company: companyConfig,
  projectObjective: projectObjectiveConfig,
  provider: providerConfig,
  providerGroup: providerGroupConfig,
  team: teamConfig,
  user: userConfig,
  question: questionConfig,
  questionCateg: questionCategConfig,
  newProject: NewProject,
  roles: Roles,
  vente: VenteConfig,
  params: ParamsConfig,
  questionGamme: questionGammeConfig,
  questionAdditional: questionAdditionalConfig,
  // other module configs
};

export const NavBar = [
  {
    name: "Ventes",
    label: "Vente",
    plural: "Ventes",
    icon: FaFile,
    info: "Module de gestion des ventes",
    menus: {
      newProject: models["newProject"],
      liste: models["vente"],
      projectObjectiveConfig: models["projectObjective"],
    },
  },
  {
    name: "Ventes Paramètres",
    label: "Vente Paramètres",
    plural: "Ventes Paramètres",
    icon: FaUsersGear,
    info: "Module de gestion des paramètres des ventes",
    menus: {
      company: models["company"],
      provider: models["provider"],
      providerGroup: models["providerGroup"],
      team: models["team"],
      user: models["user"],
      "Gammes questions": models["questionGamme"],
      "Categories questions": models["questionCateg"],
      question: models["question"],
      questionAdditional: models["questionAdditional"],
    },
  },
  {
    name: "Config",
    label: "Paramètre",
    plural: "Paramètres",
    icon: FaGear,
    info: "Module de configuration",
    menus: {
      roles: models["roles"],
      params: models["params"],
    },
  },
];

// other module configs

export const getConfigForModule = (moduleName) => {
  return models[moduleName];
};
