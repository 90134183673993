import { useState, useEffect, useRef } from "react";

export const useHorizontalScroll = () => {
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkForOverflow = () => {
      if (ref.current) {
        const { scrollWidth, clientWidth } = ref.current;
        setHasHorizontalScroll(scrollWidth > clientWidth);
      }
    };

    checkForOverflow();
    window.addEventListener("resize", checkForOverflow);

    return () => window.removeEventListener("resize", checkForOverflow);
  }, []);

  return [ref, hasHorizontalScroll];
};
