import React from "react";
import { Tabs, Form, Spin, Button, message } from "antd";
import { SaveOutlined, ReloadOutlined } from "@ant-design/icons";
import GeneralTab from "./parameters-hooks-and-helpers/tabs/general-tab";
import SalesTab from "./parameters-hooks-and-helpers/tabs/sales-tab";
import StatusQualityTab from "./parameters-hooks-and-helpers/tabs/status-quality-tab";
import CRMTab from "./parameters-hooks-and-helpers/tabs/crm-tab";
import CommissioningTab from "./parameters-hooks-and-helpers/tabs/comissioning-tab";
import CompanyTab from "./parameters-hooks-and-helpers/tabs/company-tab";
import OriasTab from "./parameters-hooks-and-helpers/tabs/orias-tab";
import ReceiptTab from "./parameters-hooks-and-helpers/tabs/receipt-tab";
import useParametersForm from "./parameters-hooks-and-helpers/use-parameters.hook";
import Layout from "../layout";

const { TabPane } = Tabs;

const ParametersPage = () => {
  const { form, loading, onFinish, resetForm } = useParametersForm();

  const handleSave = async () => {
    try {
      await form.validateFields();
      await onFinish(form.getFieldsValue());
      message.success("Parameters saved successfully");
    } catch (error) {
      message.error("Failed to save parameters");
    }
  };

  const handleReset = () => {
    resetForm();
    message.info("Form has been reset");
  };

  const SaveButton = (
    <Button
      key="save"
      type="primary"
      icon={<SaveOutlined />}
      onClick={handleSave}
      loading={loading}
      className="bg-blue-500 hover:bg-blue-600 focus:bg-blue-600"
    >
      Enregistrer
    </Button>
  );

  const ResetButton = (
    <Button
      key="reset"
      icon={<ReloadOutlined />}
      onClick={handleReset}
      className="ml-2"
    >
      Réinitialiser
    </Button>
  );

  if (loading) {
    return (
      <Layout pageTitle="Paramétrage de l'application">
        <Spin
          size="large"
          className="flex justify-center items-center h-full"
        />
      </Layout>
    );
  }

  return (
    <Layout
      pageTitle="Paramétrage de l'application"
      actionComponents={[SaveButton, ResetButton]}
    >
      <div className="max-w-7xl">
        <Form form={form} layout="vertical">
          <Tabs
            defaultActiveKey="general"
            className="bg-white shadow-md rounded-lg p-6"
          >
            <TabPane tab="Général" key="general">
              <GeneralTab form={form} />
            </TabPane>
            <TabPane tab="Vente" key="vente">
              <SalesTab form={form} />
            </TabPane>
            <TabPane tab="Statut et qualité" key="statut-qualite">
              <StatusQualityTab form={form} />
            </TabPane>
            <TabPane tab="CRM" key="crm">
              <CRMTab form={form} />
            </TabPane>
            <TabPane tab="Commissionnement" key="commissionnement">
              <CommissioningTab form={form} />
            </TabPane>
            <TabPane tab="Entreprise" key="entreprise">
              <CompanyTab form={form} />
            </TabPane>
            <TabPane tab="Orias" key="orias">
              <OriasTab form={form} />
            </TabPane>
            <TabPane tab="Recette" key="recette">
              <ReceiptTab form={form} />
            </TabPane>
          </Tabs>
        </Form>
      </div>
    </Layout>
  );
};

export default ParametersPage;
