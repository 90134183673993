import React from "react";
import { Form, Input, Card, Typography } from "antd";
import {
  BankOutlined,
  HomeOutlined,
  PhoneOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const FormItem = Form.Item;

const SectionCard = ({ title, icon, children, className }) => (
  <Card
    title={
      <span className="flex items-center text-orange-700">
        {icon}
        <span className="ml-2">{title}</span>
      </span>
    }
    className={`shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
  >
    {children}
  </Card>
);

const CompanyTab = ({ form }) => {
  return (
    <div className="max-w-5xl p-6 bg-gray-50">
      <Title level={2} className="mb-6 text-orange-800">
        Paramètres de l'Entreprise
      </Title>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <SectionCard
          title="Informations Générales"
          icon={<BankOutlined />}
          className="bg-orange-50"
        >
          <FormItem name="companyName" label="Nom de l'entreprise">
            <Input />
          </FormItem>
          <FormItem name="siret" label="SIRET">
            <Input />
          </FormItem>
          <FormItem name="rcs" label="RCS">
            <Input />
          </FormItem>
          <FormItem name="orias" label="ORIAS">
            <Input />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Adresse"
          icon={<HomeOutlined />}
          className="bg-yellow-50"
        >
          <FormItem name="address" label="Adresse">
            <Input />
          </FormItem>
          <FormItem name="addressComplement" label="Complément d'adresse">
            <Input />
          </FormItem>
          <FormItem name="postalCode" label="Code postal">
            <Input />
          </FormItem>
          <FormItem name="city" label="Ville">
            <Input />
          </FormItem>
          <FormItem name="country" label="Pays">
            <Input />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Contact"
          icon={<PhoneOutlined />}
          className="bg-green-50"
        >
          <FormItem name="phone" label="Téléphone">
            <Input />
          </FormItem>
          <FormItem name="fax" label="Fax">
            <Input />
          </FormItem>
          <FormItem name="email" label="E-mail">
            <Input type="email" />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="Informations Bancaires"
          icon={<BankOutlined />}
          className="bg-blue-50"
        >
          <FormItem name="iban" label="IBAN">
            <Input />
          </FormItem>
          <FormItem name="bic" label="BIC">
            <Input />
          </FormItem>
          <FormItem name="ics" label="ICS">
            <Input />
          </FormItem>
        </SectionCard>

        <SectionCard
          title="En ligne"
          icon={<GlobalOutlined />}
          className="bg-purple-50 col-span-full"
        >
          <FormItem name="registrationUrl" label="URL d'inscription">
            <Input />
          </FormItem>
          <FormItem
            name="productCatalogUrl"
            label="URL du catalogue de produits"
          >
            <Input />
          </FormItem>
          <FormItem
            name="companyPresentationDocument"
            label="Document de présentation de l'entreprise"
          >
            <Input />
          </FormItem>
          <FormItem
            name="prospectionConditionsDocument"
            label="Document des Conditions de prospection"
          >
            <Input />
          </FormItem>
        </SectionCard>
      </div>
    </div>
  );
};

export default CompanyTab;
