import React from "react";
import { Button, Select } from "antd";

const Pagination = ({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  pageOptions,
}) => (
  <div className="flex items-center justify-between mt-4">
    <div className="flex-1 flex justify-between sm:hidden">
      <Button
        onClick={() => gotoPage(pageIndex - 1)}
        disabled={!canPreviousPage}
      >
        Previous
      </Button>
      <Button onClick={() => gotoPage(pageIndex + 1)} disabled={!canNextPage}>
        Next
      </Button>
    </div>
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div className="flex gap-x-2">
        <span className="text-sm text-gray-700">
          Page <span className="font-medium">{pageIndex + 1}</span> of{" "}
          <span className="font-medium">{pageOptions.length}</span>
        </span>
        <Select
          value={pageSize}
          onChange={(value) => setPageSize(Number(value))}
          className="text-sm"
        >
          {[1, 2, 5, 10, 20, 30, 40, 50].map((size) => (
            <Select.Option key={size} value={size}>
              Show {size}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <Button
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span className="sr-only">First</span>
            <span aria-hidden="true">&laquo;</span>
          </Button>
          <Button
            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={!canPreviousPage}
          >
            <span className="sr-only">Previous</span>
            <span aria-hidden="true">&lsaquo;</span>
          </Button>
          <Button
            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={!canNextPage}
          >
            <span className="sr-only">Next</span>
            <span aria-hidden="true">&rsaquo;</span>
          </Button>
          <Button
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span className="sr-only">Last</span>
            <span aria-hidden="true">&raquo;</span>
          </Button>
        </nav>
      </div>
    </div>
  </div>
);

export default Pagination;
